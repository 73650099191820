@import '../../marketplace.css';

.section {
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (--viewportLarge) {
    padding: 30px 7%;
  }
}

.title {
  color: #0d1624;
  @apply --marketplaceH1FontStyles;
  font-weight: 700;
  font-size: 36px;
  line-height: 49.03px;
  margin-bottom: 20px;
  text-align: left;
  padding-top: 53px;

  @media (--viewportMobile) {
    padding-top: 20px;
    font-size: 1.07em;
    text-align: center;
  }

  @media (max-width: 991px) {
    padding-top: 20px;
    text-align: center;
  }
}

.description {
  /*@apply --marketplaceH1FontStyles;*/
  font-weight: 600;
  font-size: 32px;
  line-height: 43.58px;
  text-transform: capitalize;
  color: #6e6f71;
  text-align: left;

  @media (--viewportMobile) {
    font-size: 22px;
    text-align: center;
  }

  @media (max-width: 991px) {
    margin-bottom: 80px;
    text-align: center;
  }
}

.lpContentWrapper {
  width: 100%;
  height: 100%;
  /* max-width: 1501px; */
  margin: 0 auto;
}

.promotedSectionTabMenu {
  padding: 0;
  position: relative;
  /*border: 1px solid #ccd7ea;*/
  width: 100%;
}
.tabsContainer {
  display: flex;
  grid-column-gap: 7px;
  column-gap: 7px;
}
.subCategoryItem {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;
  display: flex;
  align-items: center;

  @media (--viewportTabletDown) {
    overflow-x: scroll;
    width: 100%;
    padding: 33px 15px 18px;
  }
}

.selectedSubCategoryItem {
  color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  cursor: pointer;
  background: transparent;
  transition: background-size 0.2s;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;

  @media (--viewportMobile) {
    background: var(--marketplaceColor);
  }
}

.subCategoryMoreItems {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 19px 10px;
  max-height: 34px;
  @media (--viewportMobile) {
    width: 88px;
  }

  & svg {
    margin-left: 4px;
    & path {
      fill: var(--marketplaceColor);
    }
  }

  @media (--viewportMobile) {
    color: var(--matterColorDarkBlue);
    & svg path {
      fill: var(--matterColorDarkBlue);
    }
  }
}

.menuContentClass {
  background-color: var(--matterColorLight);
  height: auto;
  margin-top: 20px;
  overflow: inherit;
  top: 30px;
}
