@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1456px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}

.associatedUsersWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 40px;
  width: 100%;

  & > div {
    margin: 10px 0;
  }
}

.userName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.userInfo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  padding: 20px 0;
  justify-content: space-around;
  gap: 10px;
  align-items: center;
  align-content: center;
}

.inviteButton {
  max-width: 200px;
  min-height: 50px;
  cursor: pointer;
  font-size: 18px;

  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.separator {
  margin: 0 20px;
}

.actionButtonsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.actionButton {
  min-height: 40px;
  margin-bottom: 10px;
  max-width: 120px;
}

.inviteLinkContainer {
  background-color: var(--marketplaceOffWhiteColor);
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
}

.inviteLink {
  background-color: var(--marketplaceColorLightWhite);
  padding: 5px;
  font-size: 18px;
  word-wrap: break-word;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
}

.descriptionWrapper {
  font-size: 16px;
  text-align: center;
}

.cardWrapper {
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.copyButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  align-self: center;
}

.redText {
  color: var(--marketplaceRedDarkColor);
}

.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (--viewportMobile) {
    margin-right: 0;
  }
}

.rowContainer {
  padding: 15px 40px;
  border: 1px solid var(--marketplaceOffWhiteColor);
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 5px 5px 5px var(--marketplaceOffWhiteColor);

  @media (--viewportTabletDown) {
    padding: 15px 10px;
    justify-content: space-around;
  }
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMobile) {
    width: 220px;
  }
}

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportMobile) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.nameContainer {
  font-weight: 600;
  font-size: 18px;
}

.idContainer {
  color: var(--matterColorDimmedText);
  font-size: 16px;
}

.roleInfoContainer {
  font-size: 16px;
}

.contextContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.emailContainer {
  font-size: 16px;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  font-size: 16px;
}

.avatarImage {
  width: 100%;
  border-radius: 30px;
}

.support {
  position: relative;
  display: flex;
  justify-content: flex-end;
  color: transparent;
}

.supportText {
  font-size: 16px;
  min-width: 300px;
  line-height: 24px;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0 21px;

  /* Position the tooltip */
  position: absolute;
  top: 40px;
  left: -150px;
  z-index: 1;
}

.hideSupportText {
  visibility: hidden;
}
