@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      /*border-bottom: 4px solid var(--marketplaceColor);*/
      border-radius: 0;
      text-decoration: none;
    }
  }
}

/* Desktop */
.root {
  /* Size */
  width: 100%;

  /* height: var(--topbarHeightDesktop); */
  height: 80px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 24px; */
  /* fill */
  /*background-color: var(--matterColorLight);

  !* shadows *!
  box-shadow: var(--boxShadowLight);*/

  box-shadow: none;
  background: transparent;

  @media (--viewportLarge) {
    /* padding: 0 40px; */
  }
}

.darkRoot {
  background: var(--matterColorLight);
  box-shadow: none;
  transition: 0.3s ease-in-out;
  border-bottom: 1px solid #e9e9e9;
}

.transparentRoot {
  /*background: var(--marketplaceColorBackground);*/
  box-shadow: none;
  background: transparent;
}

.darkTopbarMenuLink {
  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000;
  }
}

/* logo */
.logoLink {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  width: 154px;
  height: 40px;

  @media (--viewportLarge) {}
}

nav :global(.Menu_root__3wQlL) {
  height: auto;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  /* height: 27px; */

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  /* width: auto;
  max-width: 220px;*/
  object-fit: contain;
  object-position: left center;
  max-width: 100%;
}

/* Search */
.searchLink {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 24px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.darkSearchLink {
  & svg {
    & g {
      stroke: var(--matterColorLight);
    }
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  margin-left: 25px;
  background: var(--matterColorLight);
  border-radius: 50px;
  width: 100%;
  max-width: 275px;
  height: 55px;
  display: flex;
  border: 1.5px solid #f1f1f1;
  /* flex-direction: row-reverse;
  margin-left: 24px; */

  @media (--viewportLarge) {
    /* padding-left: 34px; */
  }
}

.darkTopbarSearchWithLeftPadding {
  margin-left: 25px;
  background: transparent;
  border-radius: 50px;
  width: 100%;
  max-width: 275px;
  height: 55px;
  display: flex;
  border: 1.5px solid #324259;
  font-size: 16.4283px;
  line-height: 21px;
  color: #617189 !important;
}

.darkTopbarSearchWithLeftPadding:hover {
  color: var(--matterColorLight) !important;
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

.yourListings {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  & .yourListingTitle {
    display: inline-block;
    margin: 0;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;

    & .yourListingTitle {
      margin: 24px 0;
    }
  }
}

/* Inbox */
.inboxLink,
.topbarMenuLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;
  border-bottom: 4px solid transparent;
  padding: 22px 12px;
  /* height: 100%; */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  color: var(--matterColor);

  &>span {
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: var(--matterColorDarkBlue);

    @media (--viewportLaptop) {
      white-space: nowrap;
    }
  }

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    &>span {
      font-size: 20px;
      line-height: 26px;
    }
  }
}

.topbarLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;
  /* height: 100%; */
  padding: 0 12px 0 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #233045;
  text-align: center;

  border-bottom: 4px solid transparent;

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;

    &>span {
      @apply --TopbarDesktop_label;
      margin: 22px 0px !important;
    }
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 26px;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.menuInbox {
  position: relative;
  color: lightgrey;

  /* display: flex;
  align-items: flex-end; */
  &:hover {
    color: black;
  }
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  margin-bottom: 4px;
  margin-left: 9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  /* height: 100%; */
  /* margin-left: 12px; */
  padding: 0 0 0 20px;
  margin: 18px 0;
  border-left: 2px solid var(--matterColorNegative);

  /* flex-direction: column-reverse; */
  &>span {
    width: fit-content;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #233045;
    /* font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--marketplaceColorDarker); */
    /* height: 31px; */
  }

  &:active {
    border-bottom: 0;
  }

  @media (max-width: 1200px) {
    &>span {
      font-size: 20px;
      line-height: 26px;
    }
  }
}

.darkProfileMenuLabel {
  & span {
    color: var(--matterColorDark);
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.profileMenuContent {
  min-width: 200px !important;
  padding-top: 20px;
  left: auto !important;
  right: 1.7rem;
  top: 3.8rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: var(--marketplaceGrayDarkColor);
}

.clientMenuContent,
.vendorMenuContent {
  min-width: 200px !important;
  /* padding-top: 20px; */
  /* left: 0px; */
  right: 0;
  width: fit-content;
  height: auto;
  /* padding: 40px 20px; */
  border-radius: 11px;
  column-width: auto;
}

.menuMarketPlaceLink,
.organiserAppLink,
.tripExpertLink,
.partnersLink,
.USDLink,
.englishLink {
  padding-left: 42px;
}

.menuMarketPlaceLink:hover,
.organiserAppLink:hover,
.tripExpertLink:hover,
.partnersLink:hover,
.USDLink:hover,
.englishLink:hover {
  text-decoration: none;
}

.menuMarketPlace,
.organiserApp,
.tripExpert,
.partners,
.USD,
.english {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--marketplaceColorLightWhite);
}

.USD img,
.english img {
  padding-left: 5px;
  width: 10.45px;
  height: 5px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 0 12px 0 12px;
  /*border-bottom: 4px solid transparent;*/
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.loginUserButton {
  padding-right: 7px;
}

.login {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  height: 38px;
  margin: 0;
  display: flex;
  align-items: center;
  width: 86px;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 22px;
  color: #707d83 !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 54px;

  & .loginName {
    padding-right: 7px;
  }
}

.login img {
  margin-left: 10px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink,
.walletMenuLink,
.dashboardMenuLink,
.yourListingsMenuLink,
.profileSettingsLink,
.yourListingsLink,
.walletMenuLink,
.dashboardMenuLink,
.yourListingsMenuLink,
.createListingMenuLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--marketplaceColorLightWhite);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColorLightWhite);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColorLightWhite);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.tripExpertAdmin {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: #ff7a00;
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--#FF7A00);
    text-decoration: none;

    /* & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    } */
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.messages,
.orderManagement {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 28px;

  /* Layout details */
  color: var(--marketplaceColorLightWhite);
  text-align: left;
  transition: var(--transitionStyleButton);
  font-weight: 700;

  &:hover {
    color: var(--#FF7A00);
    text-decoration: none;

    /* & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    } */
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.menuItemDot {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  background: var(--marketplaceRedDarkColor);
  margin-left: 5px;
}

.notificationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divider {
  border-top: 1px solid var(--marketplaceColorLightWhite);
  margin: 1rem 2rem;
}

.createListingMenuLink {
  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH4FontStyles;
  font-size: 16px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 0px 24px 20px 24px;

  /* Layout details */
  color: var(--marketplaceColorLightWhite);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColorLightWhite);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColorLightWhite);
    }
  }

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.activeUser {
  height: 62px;
  width: 62px;
  position: relative;
  margin-left: 7px;
  display: flex;
  align-items: center;

  @media (max-width: 1440px) {
    height: 52px;
    width: 52px;
  }
}

.active {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #6cef3e;
  border: 3px solid var(--matterColorLight);
  top: 65%;
  left: 0;
  position: absolute;
}

.displayMenu {
  color: lightgrey;

  &:hover {
    color: black;
  }
}

.mainMenuItem {
  display: flex;
  min-height: 473px;
}

.menuContainer {
  margin-right: 46px;
}

.mainMenuItem .menuContainer:last-child {
  margin-right: 0px;
}

.menuContainer .MenuTitle {
  margin: 0;
}

.menuContainer .MenuTitle span {
  font-style: normal;
  font-weight: bolder;
  font-size: 22px;
  line-height: 28px;
  color: #272727;
  margin-bottom: 0;
  margin-top: 15px;
  display: block;
}

/* .menuContainer span{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8E8E8E;
  margin-top: 14px;
  display: block;
}

.menuContainer a span{
  color: var(--matterColorDark);
} */

.menuContainer a:hover {
  text-decoration: none;
}

.menuImage {
  width: 249px;
  height: 141px;
  border-radius: 10px;
}

.jobsBoardMenuImage {
  width: 188px;
  height: 124px;
  border-radius: 13px;
}

.avatar {
  height: 44px;
  width: 44px;
}

.profileMenuRoot {
  height: auto;
  margin-left: 8px;
}

.navWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.navLinkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.privateNavWrap {
  margin: 0 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobsBoardButton {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  align-items: center;
  justify-content: center;
  animation-delay: 0.8s;
  min-height: 50px;
  padding: 0;
}

.proConnetTitle {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-weight: bold;
  font-size: 17px;
  line-height: 11px;
  text-transform: capitalize;
  color: var(--matterColorLight);

  & svg {
    width: 12px;
    height: 12px;
  }
}

.darkJobsBoardButton {
  background: #37465a !important;
}

.jobsBoardButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--marketplaceColorBackground);
  border: 0.621311px solid var(--marketplaceColorBackground);
  box-sizing: border-box;
  border-radius: 6px;
  text-decoration: none !important;
  width: 196px;
  height: 47px;
}

.becomeVenderName {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: var(--marketplaceColor);
}

.menuItemHolder {
  display: flex;
  padding: 18px 0 6px 0;
  align-items: center;
  column-gap: 20px;
}

.jobsBoardItemHolder {
  display: flex;
  padding: 12px 0 11px 0;
  align-items: flex-start;
  border-bottom: 0.1px solid var(--matterColorAnti);
  column-gap: 13px;

  & .menuItemInfo {
    font-size: 14px;
    line-height: 18px;
  }

  & svg {
    margin-top: -10px;
  }
}

.jobsBoardItemHolderWithoutBorder {
  border: 0;
  padding-top: 8px;
}

.jobsBoardItemTitle {
  height: 42px;
  width: 262px;
  margin: 0;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: var(--marketplaceColorDarkest);
}

.menuItemTitle {
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: var(--matterColorDark);
  position: relative;
}

.menuItemTitleOneLine {
  white-space: nowrap;
}

.menuItemTitleBooking {
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: var(--matterColorDark);
  position: relative;
  display: flex;
}

.menuItemInfo {
  display: block;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #6e6f71;
  margin-top: 4px;
}

.menuItemClientInfo {
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #6e6f71;
  margin: 7px 0 6px 0;
}

.menuItemInfoWithBold {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #6e6f71;
  margin-top: 4px;
}

.menuItemInfoWithPadding {
  display: block;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #6e6f71;
  margin: 9px 0 23px 0;
}

.vendorPortalMenuItem {
  display: block;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: var(--marketplaceColor);
  margin: 9px 0 14px 0;
}

.greyContainer {
  display: flex;
  padding: 24px 33px;
  background: #efefef;
  border-radius: 0 11px 11px 0;
}

.hide {
  display: none;
}

.whiteContainer {
  padding: 24px 33px 32px;
}

.menuItemHeading {
  margin-top: 18px;
}

.becomeVendorHeading {
  margin-top: 31px;
}

.addListingContainer {
  /* padding: 12px 0 0; */
  background: #f1f1f1;
  margin: 4px 0;
  border-radius: 7px;
  height: 35px;
}

.newIcon {
  position: absolute;
  right: -54px;
  top: -14px;
}

.socialMarketNewIcon {
  position: absolute;
  right: -4px;
  top: -25px;
  width: 45px;
  height: 23px;
}

.displayAddMenu {
  display: flex;
  align-items: center;
  width: 215px;
  height: 35px;
  margin-left: 11px;

  & span {
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #6e6f71;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    margin: 0px 7;
  }
}

.privateMenu {
  display: flex;
  align-items: center;
}

.embeddedInfo {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--matterColorDarkBlue);
  display: block;
  margin-top: 20px;
}

.socialSearchMarketingInfo {
  display: block;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
}

.svgWrapFulltimeJobs {
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg,
      var(--attentionColor) 99.99%,
      var(--matterColorDarkBlue) 100%);
  box-shadow: 2.83398px 5.66797px 21.2549px rgba(79, 223, 177, 0.35);
  border-radius: 2.83398px;
}