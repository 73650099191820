@import '../../marketplace.css';

.mainCategoryFilters {
  width: 246px;
  height: 72px;
  display: flex;
  background: linear-gradient(
    180deg,
    rgba(147, 147, 147, 0.255) 0%,
    rgba(147, 147, 147, 0.075) 100%
  );
  border-radius: 4.80251px;
  border: 1.5px solid transparent;
  @media (--viewportMobile) {
    width: 100%;
    height: 55px;
  }
  & .categoryImage {
    width: 67px;
    min-width: 67px;
    height: 100%;
    @media (--viewportMobile) {
      width: 52px;
      min-width: 52px;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
  & .categoryLabel {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    white-space: nowrap;
    color: var(--matterColorLight);
    padding: 23px 59px 23px 17px;
    @media (--viewportMobile) {
      font-size: 15px;
      line-height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
}

.selectedMainCategory {
  border: 1.5px solid var(--marketplaceColorLight);
}

.filterResetButton {
  width: 84px;
  min-height: 40px;
  font-weight: 600;
  font-size: 12.8801px;
  line-height: 18px;
  color: var(--matterColorLight);
  background: rgba(255, 255, 255, 0.07) !important;
  border: 0;
  border-radius: 20.5px;
}
.filterApplyButton {
  width: 84px;
  min-height: 40px;
  font-weight: 600;
  font-size: 12.8801px;
  line-height: 16px;
  color: var(--matterColorLight);
  background: var(--marketplaceColorLight) !important;
  border: 0;
  border-radius: 20.5px;
}

.filterButtonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0 10px;
  @media (--viewportMobile) {
    margin: 22px 17px 22px 0;
  }
}

.languageTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorLight);
  margin: 23px 0 13px;
  @media (--viewportMobile) {
    margin: 17px 0 16px;
  }
}

.advancedFiltersWrapper {
  padding: 20px 44px 51px 70px;
  background: var(--marketplaceColorBackground);
  @media (--viewportMobile) {
    padding: 19px 24px 51px;
  }
}

.advancedFiltersWrapper .FilterByTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--matterColorLight);
  @media (--viewportMobile) {
    display: flex;
    justify-content: center;
  }
}

.languageOptionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (--viewportMobile) {
    display: block;
    column-count: 2;
  }
}

.checkboxContainer {
  display: block;
  margin-bottom: 15px;
  @media (--viewportMobile) {
    margin-bottom: 24px;
  }
}

.checkboxContainer input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkboxContainer label {
  position: relative;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #c4c4c4;
}

.checkboxContainer label:before {
  content: '';
  -webkit-appearance: none;
  border-radius: 3px;
  background-color: #1c2a3f;
  border: 2px solid #2f3d54;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.checkboxContainer input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--marketplaceColor);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  @media (--viewportMobile) {
    top: 3px;
  }
}

.sectionHostedByPros {
  max-width: 1175px;
  margin: 0 auto;
  padding: 0 24px;
}

.hostedByProsWrap {
  display: flex;
  padding: 51px 0 53px;
  column-gap: 45px;
  border-width: 100%;
  @media (--viewportMobile) {
    flex-direction: column;
    padding-bottom: 12px;
    border: 0;
  }
}

.hostedProsHeadingWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.hostedByProsTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: var(--matterColorDark);
  margin: 0;
}

.blueText {
  color: var(--marketplaceColor);
}

.hostedByProsSlogan {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--matterColorDark);
  @media (--viewportMobile) {
    padding: 62px 0 15px;
  }
}

.videoWrapper {
  max-width: 756px;
  height: auto;
  width: 100%;
  max-height: 300px;

  @media (--viewportMobile) {
    height: 412px;
  }
  & video {
    border-radius: 28px;
    max-width: 100%;
    height: 100%;
    object-fit: inherit;
  }
}

.prosupportWrapper {
  max-width: 1175px;
  margin: 0 auto;
  padding: 38px 24px;

  & .prosupportMain {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    column-gap: 70px;

    @media (--viewportMobile) {
      justify-content: space-between;
      column-gap: 0;
    }

    & .prosupportTitle {
      color: #101c30;
      font-weight: 700;
      font-size: 25px;
      line-height: 16px;
      & span {
        color: var(--marketplaceColor);
      }
      @media (--viewportMobile) {
        font-size: 20px;
      }
    }

    & .prosupportHeading {
      color: #c4c4c4;
      font-weight: 600;
      font-size: 25px;
      line-height: 16px;
      @media (--viewportMobile) {
        font-size: 20px;
      }
    }

    & .selectedProsupportHeading {
      color: var(--marketplaceColor);
    }
  }
}

.mainCategoryFiltersBox {
  background-color: #101c30;
}

.mainCategoryFiltersWrapper {
  max-width: 1175px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(246px, 1fr));
  gap: 12px 8px;
  background-color: #101c30;
  position: relative;

  padding: 35px 20px 25px 55px;
  @media (--viewportMobile) {
    grid-template-columns: repeat(2, 1fr);
    padding: 14px 16px 18px 16px;
  }

  &:after {
    content: '';
    position: absolute;
    top: -6%;
    left: 5%;
    background-color: #101c30;
    width: 20px;
    height: 11px;
    clip-path: polygon(50% 0%, 3% 76%, 100% 76%);
    @media (--viewportMobile) {
      top: -5%;
      left: 15%;
    }
  }
}

.mainAdventureWrapper {
  &:after {
    left: 19%;
    @media (--viewportMobile) {
      left: 45%;
    }
  }
}

.mainWellnessWrapper {
  &:after {
    left: 34%;
    @media (--viewportMobile) {
      left: 74%;
    }
  }
}

.advanceFilterContainer {
  margin: 0px;
  border-top: 1px solid #e5e5e5;
  position: relative;
}

.divider {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  border-bottom: 1px solid #e5e5e5;
}

.advanceFilterWrapper {
  padding: 10px 34px;
  display: flex;
  align-items: center;
  margin: 10px 10px;
  column-gap: 16px;
  @media (--viewportMedium) {
    padding-bottom: 20px;
  }

  @media screen and (max-width: 1366px) {
    flex-direction: row;
    align-items: center;
    padding-top: 19px;
    margin: 0.8rem 2rem;
  }

  @media (--viewportTabletDown) {
    flex-direction: column;
    padding: 10px 24px 0;
  }
}

.subCategoryItemsWrap {
  display: flex;
  column-gap: 11px;
  padding: 26px 0 30px;
  column-gap: 16px;

  @media screen and (max-width: 1200px) {
    overflow-x: scroll;
    max-width: 100%;
    overflow-y: hidden;
  }

  @media (--viewportTabletDown) {
    overflow-x: scroll;
    width: 100%;
    padding: 15px 0 18px;
  }
}

.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  max-width: 154px;
  white-space: nowrap;
}

.filterButtonItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;
  position: relative;
}

.filterButtonItemOpen {
  &:after {
    content: '';
    position: absolute;
    bottom: -30px;
    right: 37%;
    background-color: #101c30;
    width: 20px;
    height: 10px;
    -webkit-clip-path: polygon(50% 0%, 3% 76%, 100% 76%);
    clip-path: polygon(50% 0%, 3% 76%, 100% 76%);
  }
}

.subCategoryMoreItems {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 19px 10px;
  max-height: 34px;
  @media (--viewportMobile) {
    width: 88px;
  }

  & svg {
    margin-left: 4px;
    & path {
      fill: var(--marketplaceColor);
    }
  }
}
.selectedSubCategoryItem {
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
}

.rightSideFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 7px;
  padding-bottom: 4px;

  @media screen and (max-width: 400px) {
    overflow-x: scroll;
    max-width: 300px;
    padding-bottom: 15px;
    justify-content: flex-start;
  }
}

.labelSellerLevels {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* text-transform: uppercase; */
  color: var(--marketplaceOffWhiteColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 19px 10px;
  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceOffWhiteColor);
  box-sizing: border-box;
  border-radius: 6px;
  max-height: 34px;

  & svg {
    margin-left: 5px;
    & path {
      fill: var(--marketplaceColorLightI);
    }
  }
}

.menuContent {
  margin-top: 7px;
  padding: 13px 10px 5px;
  min-width: 300px !important;
  border-radius: 4px;
  @media (--viewportMobile) {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.sellerTypeMenuContent {
  left: 0;
}

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0 0 10px 0;
  padding: 4px 15px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  white-space: nowrap;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
  & svg {
    fill: transparent;
  }
}

.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  padding: 5px 0;
  min-width: 142px;
  min-height: 30px;
}

.filterContainer {
}

.DropdownButton {
  position: absolute;
  left: 0;
  width: 1024px;
  top: 78%;
  z-index: 1;
  @media screen and (max-width: 1366px) {
    top: 46%;
  }
  @media (--viewportMobile) {
    width: 100%;
    top: 60%;
  }
}
.subscriptionLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--marketplaceColorLight) !important;
  border-radius: 8px;
  padding: 0 22px;
  height: 38px;
  font-style: normal;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  /* text-transform: capitalize; */
  color: var(--matterColorLight);
  text-decoration: none !important;
  @media (max-width: 991px) {
    width: auto;
    height: 44px;
    font-size: 15px;
    line-height: 19px;
  }
}
.postNewJob {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--marketplaceColorLight) !important; */
  border-radius: 25px;
  padding: 0 22px;
  height: 38px;
  font-style: normal;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  border: 1px solid var(--marketplaceColorLight);
  /* text-transform: capitalize; */
  color: var(--marketplaceColorLight);
  text-decoration: none !important;
  @media (max-width: 991px) {
    width: auto;
    height: 44px;
    font-size: 15px;
    line-height: 19px;
    margin-top: 10px;
  }
}

.buttonSubTitle {
  font-size: 12px;
  font-weight: 600;

  margin: 0 auto;
}

.subDescription {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionSearchContainer {
  display: flex;
  flex: 1;
  /* background-color: #101c30; */
  margin-left: 2rem;

  margin-bottom: 10px;
}

.searchLink {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 24px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.topbarSearchWithLeftPadding {
  margin-left: 25px;
  background: var(--matterColorLight);
  border-radius: 50px;
  width: 100%;
  max-width: 275px;
  height: 55px;
  display: flex;
  border: 1.5px solid #f1f1f1;
  /* flex-direction: row-reverse;
  margin-left: 24px; */

  @media (--viewportLarge) {
    /* padding-left: 34px; */
  }
}
