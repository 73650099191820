@import '../../../../marketplace.css';

.row {
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
  &:nth-child(even) {
    background: #f7f9fa;
  }
  &.clickable {
    cursor: pointer;
    background: var(--marketplaceColorDarker);
    color: var(--matterColorLight);
    & .nameContent {
      font-weight: bold;
    }
  }
}

.clientRow {
  display: flex;
  background: #e0f2f7;
  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
}

.cell {
  margin: 18px 0 18px 1.8%;
  font-size: 14px;
}

.nameContent,
.listingNameContent {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
  }
}

.clientName {
  width: 50%;
  text-align: left;
}
.name {
  position: ;
  width: 10%;
}
.time {
  width: 6.5%;
}
.listingName {
  /* width: 30%; */
  width: 10%;
}
.clientMembership {
  width: 10%;
}
.membership {
  /* width: 9.5% */
  width: 10%;
}
.marked {
  position: relative;
  /* width: 2.5%; */
  width: 5%;
  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--marketplaceColor);
  }
  &.not {
    &:before {
      background-color: #f2f6f8;
    }
  }
}

.weekday {
  margin-bottom: 24px;
  border: 1px solid #ededed;
  border-radius: 6px;
}
.weekdayRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 1.8% 18px 1.8%;
  border-bottom: 1px solid #ededed;
}
.weekdayName {
  font-size: 14px;
  color: var(--marketplaceColorDark);
  text-transform: uppercase;
  font-weight: bold;
}
.bookingNumber {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.collapsible {
  height: auto;
  overflow: hidden;
  transition: height 2s linear;
  &.hidden {
    height: 0;
  }
}

.collapseIcon {
  margin-right: 16px;
  transform: rotate(180deg);
  transition: transform 0.5s linear;
  &.collapsed {
    transform: rotate(0deg);
  }
}

.collapseIconMultiBooking {
  transform: rotate(0deg);
  fill: var(--matterColorLight);
  &.collapsed {
    transform: rotate(180deg);
  }
}
