@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.headerInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 20px;
}

.textInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 60px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.mutateBtns {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;
  @media (--viewportMobile) {
    margin-bottom: 24px;
  }
  & > button {
    max-width: 200px;
    min-height: 40px;
  }
}
