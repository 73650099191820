@import '../../marketplace.css';
.uploadBtn{
	margin: 24px 0px;
}
.notes{
	margin: 24px 0px;
}
.submit{
	margin: 100px 0px;
}
.progressReports{
	@media (--viewportMedium) {
		margin: 0px 64px;
	}
}
.attachment img {
	width: 300px;
	padding: 15px;
	@media (--viewportMobile) {
		width: 250px;
	}
}
.attachment{
	display: flex;
	justify-content: center;
}
.header{
	display: flex;
	align-items: center;
}
.headerLinks{
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 20px;
}
.saleDetails a{
	@apply --marketplaceH4FontStyles;
	color: var(--matterColor);
	line-height: 18px;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	&:hover .clientName{
	    /* Hightlight the username when the whole item is hovered */
	    color: var(--marketplaceColor);
	    & .listingTitle {
	      border-color: var(--marketplaceColor);
	    }
	}
}
.listingTitle{
	margin-left: 10px;
	padding-left: 10px;
	border-left: 1px solid var(--matterColorDarkBlue);
}