@import '../../marketplace.css';

.HowItsWorksSectionContainer {
  margin-bottom: 100px;
}

.title {
  color: #0d1624;
  @apply --marketplaceH1FontStyles;
  font-weight: 700;
  font-size: 36px;
  padding-top: 53px;
  line-height: 49px;

  @media (max-width: 991px) {
    margin-bottom: 3px;
    text-align: center;
  }

  @media (--viewportMobile) {
    font-size: 22px;
    text-align: center;
  }
}

.description {
  @apply --marketplaceH1FontStyles;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  text-transform: capitalize;
  color: #6e6f71;
  text-align: left;
  margin-bottom: 50px;

  @media (max-width: 991px) {
    margin-bottom: 23px;
    text-align: center;
  }

  @media (--viewportMobile) {
    font-size: 20px;
    text-align: center;
  }

}

.ListWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0
}

.DetailsContainer {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  flex: 1;
  align-self: center;
}

.DetailsContainer h6 span {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #0d1624;

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.DetailsContainer span {
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  color: #6e6f71;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.DetailsOptionalContainer h6 span {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-transform: capitalize;
  color: var(--marketplaceRedDarkColor);

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.DetailsOptionalContainer span {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  text-transform: capitalize;
  color: #6e6f71;

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.arrowImgLeftContainer,
.arrowImgRightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.arrowImgRightContainer img {
  position: absolute;
  /*top: 0;*/
}

.arrowImgRightContainer img {
  position: absolute;
  /*bottom: 0;*/
}

.imgLeftContainer {
  display: flex;
  flex-direction: row;

  @media (--viewportTabletDown) {
    flex-direction: column;
    text-align: center;
  }
}

.imgRightContainer {
  display: flex;
  flex-direction: row;
  gap: 290px;

  @media (max-width: 991px) {
    gap: 0px;
  }

  @media (--viewportTabletDown) {
    flex-direction: column-reverse;
    text-align: center;
  }
}

.imgWrapper img {
  max-width: 100%;
  height: auto;
}

.imgWrapper {
  flex: 1.2;
}

.imgOptionalDisplay {
  display: flex;
  justify-content: center;
}

.imgOptionalDisplay img {
  @media (--viewportLaptopDown) {
    display: none;
  }
}

.imgOptionalDisplay1 img {
  @media (--viewportTabletDown) {
    display: none;
  }
}

.imgOptionalWrapper img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.imgOptionalWrapper {
  height: 536px;
  flex: 1;
  position: relative;
  text-align: center;
}

.arrowImgContainer img {
  position: absolute;
}