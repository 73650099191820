@import '../../marketplace.css';

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.listingCards {
  padding: 0 0px 26px 0px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.listingPanel {
  width: 100%;

  @media (--viewportLarge) {
    max-width: 62.5vw;
  }
}

.trigger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--matterColorNegative);
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  color: var(--matterColorDarkBlue);
  @media (--viewportMobile) {
    flex-direction: column;
  }
}

.triggerSectionLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.userAndListingName {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.userName:hover {
  color: var(--marketplaceColor);
}
.listingTitle:hover {
  color: var(--marketplaceColor);
}
.createdAt {
  color: var(--matterColorAnti);
  margin-top: 10px;
  font-size: 14px;
}
.sessions,
.usedSessions {
  margin-left: 10px;
  font-size: 14px;
}
.prAmount {
  display: block;
}
.usedSessions {
  padding-left: 10px;
  border-left: 1px solid var(--matterColor);
}
.status {
  margin-right: 10px;
}
.triggerSectionRight {
  margin-left: auto;
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    margin: 0;
  }
}
.packageRequestActions,
.paymentInfoContainer {
  display: flex;
  border-left: 1px solid #e0e0e0;
}

.collapsible {
  width: 100%;
}
.payment_type,
.payment_status {
  padding: 15px 10px;
  font-weight: 600;
}
.acceptPackageRequest {
  @apply --marketplaceButtonStylesPrimary;
  width: auto;
  padding: 15px 25px;
  margin-left: 20px;
}
.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColorDark);
  stroke-width: 3px;
}
.rejectPackageRequest {
  @apply --marketplaceButtonStylesSecondary;
  width: auto;
  padding: 15px 25px;
  margin-left: 20px;
}
.iconArrowDown {
  display: flex;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  & svg {
    margin-left: 0;
    fill: var(--matterColorBright);
  }
}
.listItem {
  /* Layout */
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 12px;

  align-items: center;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
  @media (--viewportMobile) {
    flex-direction: column;
  }
}
.paymentProofsContainer {
  margin-top: 30px;
}
.paymentProofSsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (--viewportMobile) {
    margin: 29px 24px 0 24px;
  }
}
.paymentProofSs img {
  width: 300px;
  padding: 15px;
}

.searchPackage,
.transactions {
  margin-bottom: 20px;
  margin-right: 20px;
}
.allPackages{
  font-size: 30px;
}