@import '../../marketplace.css';

.header {
  padding: 16px 7%;
  background-color: var(--marketplaceColorDarker);
  color: var(--matterColorLight);
  font-size: 25px;
  font-weight: 600;

  @media (--viewportMobile) {
    padding: 16px 15px;
  }
}

.title {
  margin: 0px 25px;
}

.tabsContainer {
  @media (--viewportTabletDown) {
    padding: 50px 24px 0;
  }
}

.move {
  color: var(--matterColorLightBlue);
}

.searchContainer {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    padding: 0;
  }
}

.searchTabs {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  & > * {
    margin-right: 34px;

    @media (--viewportMobile) {
      display: block;
      margin-right: 10px;
    }
  }

  & > *:last-child {
    margin-right: 0;
  }

  @media (--viewportMobile) {
    justify-content: flex-start;
    display: none;
  }
}

.tabs {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  color: #223046;
  font-size: 20px;

  @media (--viewportMobile) {
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
  }
}

.activeTab {
  border-bottom: 6px solid #2c3e50;
  font-weight: bold;
  font-size: 21px;
  line-height: 60px;
  text-transform: capitalize;
  color: var(--matterColorDarkBlue);

  /* width: 111px; */
  @media (--viewportMobile) {
    font-weight: bold;
    border-bottom: 6px solid var(--marketplaceColor);
    font-size: 23px;
    line-height: 60px;
    text-transform: capitalize;
    color: var(--marketplaceColor);
  }
}

.inactiveTab {
  border-bottom: 6px solid transparent;
  font-weight: normal;
  font-size: 21px;
  line-height: 60px;
  text-transform: capitalize;
  color: var(--matterColorDimmedText);

  @media (--viewportMobile) {
    font-weight: 600;
    font-size: 23px;
    line-height: 60px;
    text-transform: capitalize;
    color: #243144;
  }
}

.borderWrapper {
  position: absolute;
  display: flex;
  width: 100%;
}

.borderLight {
  background-color: var(--matterColorLightBlue);
  height: 3px;
  width: 70%;
  float: left;
  margin-top: 4px;
}

.borderDark {
  background-color: var(--marketplaceColorBackground);
  width: 30%;
  height: 3px;
  float: left;
  margin-right: 5px;
  margin-top: 4px;
}

.providerHeading {
  width: 170px;

  @media (--viewportMobile) {
    width: 125px;
  }
}

.customersHeadingBorderDark {
  @media (--viewportMobile) {
    width: 37px;
  }
}

.customersHeadingBorderLight {
  @media (--viewportMobile) {
    width: 95px;
  }
}

.providerHeadingBorderLight {
  width: 97px;

  @media (--viewportMobile) {
    width: 70px;
  }
}

.providerHeadingBorderDark {
  width: 43px;

  @media (--viewportMobile) {
    width: 37px;
  }
}

.search {
  /*margin-bottom: 40px;*/
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  max-width: 698px;
  padding: 0.5rem 1rem;
  color: var(--matterColor);
  /*padding-bottom: 25px;*/
  background: #ffffff;
  border: 1px solid #6e6f71;
  box-sizing: border-box;
  box-shadow: 0px 0px 50px 10px rgb(0 0 0 / 25%);
  backdrop-filter: blur(15px);
  border-radius: 10px;

  @media (--viewportMobile) {
    display: flex;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
    max-width: 100%;
  }
}

.searchForSearchPage {
  /*margin-bottom: 40px;*/
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  max-width: 698px;
  padding: 0.5rem 1rem;
  color: var(--matterColor);
  /*padding-bottom: 25px;*/
  background: #ffffff;
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  border-radius: 10px;

  @media (--viewportMobile) {
    display: flex;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
    max-width: 100%;
  }
}
.searchQuery > img {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.searchQuery {
  flex: 1;
  min-width: 276px;
  position: relative;
  /*border: 1px solid #f1f1f1;*/
  border-radius: 10px 10px 0 0;
  background-color: #ffffffe6;
  transition: border-color 0.2s ease 0s;
  cursor: pointer;
  margin-bottom: 0.5rem;

  @media (--viewportMobile) {
    width: 100%;
    min-width: 100%;
    margin: 10px 0px 10px 0px;
  }

  & input {
    height: 100%;
    font-weight: normal;
    border-bottom: none !important;
    font-size: 16px;
    padding: 0 0 0 0.2rem;
    line-height: 21px;
    color: #617189;
    &::placeholder {
      font-style: italic;
    }
    @media (--viewportMobile) {
      padding: 0 0 0 10px;
    }
  }

  &:not(.active-search-query):hover {
    border-color: #dddddd;
  }

  & :global(.react-autosuggest__container) {
    height: 55px;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    border-bottom: 1px solid black;
  }
}

.searchPipeLine {
  padding: 8px 0;
}
.MapIcon {
  margin: auto;
  width: 30px;
}
.searchBottomIcon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.SmileIcon {
  left: 0;
  width: 30px;
}
.countyOrCitySearchQuery {
  flex: 1;
  min-width: 274px;
  position: relative;
  /*border: 1px solid #f1f1f1;*/
  background-color: #ffffffe6;
  transition: border-color 0.2s ease 0s;
  margin-bottom: 1rem;
  cursor: pointer;

  @media (--viewportMobile) {
    width: 100%;
    min-width: 100%;
    margin: 10px 0px 10px 0px;
  }

  & input {
    height: 100%;
    border-bottom: none !important;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #617189;
    &::placeholder {
      font-style: italic;
    }
  }

  &:not(.active-search-query):hover {
    border-color: #dddddd;
  }

  & :global(.react-autosuggest__container) {
    height: 55px;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    border-bottom: 1px solid black;
  }
}

.searchButton {
  @media (--viewportMobile) {
  }

  & button {
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    height: 54px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    right: 5px;
    outline: none;
    padding: 14px 20px;
    -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.1s ease, transform 0.1s ease;
    -moz-transition: box-shadow 0.2s ease, transform 0.1s ease;
    transition: box-shadow 0.2s ease, -ms-transform 0.1s ease, -webkit-transform 0.1s ease,
      transform 0.1s ease;
    background: var(--marketplaceColor);
    color: var(--matterColorLight);
    white-space: nowrap;
    margin: 5px 3px;

    & svg {
      stroke: var(--matterColorLight);
      transform: scaleX(-1);
      width: 29px;
      height: 29px;
    }

    @media (--viewportMobile) {
      margin: 10px 0px 10px 0px;
      width: 100%;
      height: 60px;
    }
  }
}

.border {
  -ms-grid-row-align: center;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  border-right: 1px solid #dddddd;
  -webkit-flex: 0 1 0px;
  -ms-flex: 0 1 0px;
  flex: 0 1 0px;
  height: 44px;

  @media (--viewportMobile) {
    display: none;
  }
}

.dateRangeLengthFilter button {
  border: none;
  font-size: 13px !important;
  font-weight: normal !important;
  color: var(--matterColorAnti) !important;
  padding: 4px 0 10px 0;
  background-color: var(--matterColorLight) !important;

  @media (--viewportMobile) {
    font-size: 16px !important;
  }
}

.dateRangeLengthFilter button:hover {
  background-color: var(--matterColorBright) !important;
  box-shadow: none !important;
  border: none !important;
}

.dateRangeLengthFilter button:focus {
  background-color: var(--matterColorBright) !important;
  box-shadow: none !important;
  border: none !important;
}

.dateRangeLengthFilter > div:first-of-type {
  @media (--viewportMobile) {
    position: absolute;
    left: 0px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.addListingButton {
  @apply --marketplaceButtonStyles;

  animation-delay: 0.8s;
  min-height: 55px;
  padding: 15px 0 0;
  background-color: #34b7ba;
  width: 190px;
  border-radius: 8px;
}

.findCustomers {
  margin: 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #eaeaea;

  & p {
    margin: 0 0 16px 0;
    font-size: 14px;
    line-height: 16px;

    @media (--viewportLaptop) {
      margin: 0 0 20px 0;
      font-size: 18px;
      line-height: 22px;
    }
  }

  & .findProvidersTitle {
    margin-bottom: 12px;
  }
}

.findCustomersTitle {
  margin-bottom: 24px;
  font-size: 21px;
  line-height: 26px;
  color: var(--matterColorDarkBlue);

  @media (--viewportLarge) {
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 32px;
    line-height: 42px;
  }
}

.findProvidersTitle p {
  margin: 25px 0 0;
  font-weight: bold;
  font-size: 50px;
  line-height: 46px;
  color: #ffffff;

  @media (--viewportMobile) {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}
.findProvidersTitle {
  font-weight: bold;
  font-size: 50px;
  line-height: 46px;
  color: #ffffff;
  margin: 43px 0 24px;

  & span {
    color: var(--marketplaceColor);
  }

  @media (--viewportMobile) {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 0px;
    margin: 0 0 0px 0;
  }

  /* @media screen and (min-width: 1024px) and (max-width: 1440px) {
    font-size: 44px;
  }

  @media screen and (min-width: 1441px) and (max-width: 1490px) {
    font-size: 46px;
  }

  @media screen and (min-width: 1491px) and (max-width: 1580px) {
    font-size: 48px;
  } */
}

.findProvidersInfo {
  font-weight: 600;
  font-size: 23px;
  line-height: 41px;
  /* color: var(--matterColorDimmedText); */
  color: #6e6f71;
  margin: 0 0 48px;

  @media (--viewportMobile) {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0 26px;
  }
}

.findProvidersSubTitle {
  margin-top: 0;
  font-size: 90%;
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    font-size: 140%;
    line-height: 56px;
  }
}

.findCustomersDescription,
.findProvidersDescription {
  font-size: 16px;
}

.suggestionSectionTitle {
  color: var(--matterColorLightBlue);
  text-align: left;
  padding: 10px 36px;
}

.popularRequests {
  @media (--viewportMobile) {
    display: none;
  }
}

.searchAddEnquiry {
  display: flex;
  justify-content: space-between;
  padding: 24px 0 0 24px;
  background: #7c8a8b;
  color: var(--matterColorLight);
  border-radius: 8px;

  @media (--viewportLaptop) {
    position: relative;
    padding: 24px;
  }

  & .searchAddEnquiryHeader {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 23px;
    font-weight: bold;
    line-height: 23px;
    color: var(--marketplaceColorLightWhite);

    @media (--viewportMobile) {
      font-size: 4vw;
      line-height: 4vw;
    }
  }

  & .searchAddEnquiryHighlighted {
    margin-top: 5px;
    font-weight: 900;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    color: #c8d9da;

    @media (--viewportMobile) {
      font-size: 3.5vw;
      line-height: 4vw;
    }
  }

  & .searchAddEnquiryContent {
    flex: 1 1;
    max-width: 345px;

    & p {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 17px;

      @media (--viewportMobile) {
        margin-bottom: 3vw;
        font-size: 2.5vw;
        line-height: 3vw;
      }
    }

    @media (--viewportLaptop) {
    }
  }

  & .searchAddEnquiryButton {
    padding: 8px 24px;
    color: #223147;
    font-size: 14px;
    background: var(--matterColorLight);
    border-radius: 100px;
    border: none;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }

    & svg {
      margin-left: 12px;
    }

    @media (--viewportMobile) {
      padding: 1.9vw 5.7vw;
      font-size: 3.4vw;
      border-radius: 24vw;
      white-space: nowrap;

      & svg {
        margin-left: 2.9vw;
        width: 3.6vw;
        height: 3.3vw;
      }
    }
  }
}

.searchEnquiryFormContainer {
  @media (--viewportMobile) {
    padding: 0 24px;
  }
}

.ImageSection {
  display: flex;
  width: 876px;
  height: 259px;
  margin: 30px 0 -56px;
  z-index: -1;
  /* birthday */
  position: relative;
  justify-content: center;

  @media (--viewportXLarge) {
    margin: 30px 0 -26px;
  }

  & .leftSvg {
    position: absolute;
    top: -16px;
    left: -34px;
    z-index: -1;

    @media (--viewportTabletDown) {
      display: none;
    }
  }

  & .rightSvg {
    position: absolute;
    top: -50px;
    right: -22px;
    z-index: -1;

    @media (--viewportTabletDown) {
      display: none;
    }
  }

  @media (--viewportLargeWithPaddings) {
    width: 100%;
  }

  & div {
    & img {
      width: 100%;
      height: auto;
      border-radius: 50%;

      @media (--viewportMedium) {
        width: auto;
        height: 220px;
      }
    }
  }

  @media (--viewportTabletDown) {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
}
.searchInputWrapper {
  position: absolute;
  top: 60%;
  left: 40px;
  width: 35rem;
  padding: 0px 4rem;

  @media (--viewportSmall) and (--viewportLaptopDown) {
    width: 28rem;
    padding: 0 2rem;
  }

  @media (--viewportMobile) {
    width: 18rem;
    padding: 0;
  }
}

.searchPageInputWrapper {
  position: relative;
  top: 60%;
  left: -5rem;
  width: 35rem;
  padding: 0px 4rem;

  @media (--viewportSmall) and (--viewportLaptopDown) {
    width: 28rem;
    padding: 0 2rem;
  }

  @media (--viewportMobile) {
    width: 18rem;
    padding: 0;
  }
}

.searchInputWrapper :global(.react-autosuggest__suggestions-container--open), .searchPageInputWrapper :global(.react-autosuggest__suggestions-container--open) {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #ffffff;
  border-radius: 16.8519px;
  z-index: 111;
  padding: 6px;
}
.searchInputWrapper :global(.react-autosuggest__suggestions-container--open):after, .searchPageInputWrapper :global(.react-autosuggest__suggestions-container--open):after {
  content: '';
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 11px solid #ffffff;
  position: absolute;
  top: -10px;
  left: 8%;
  transform: translateX(-50%);
}
.searchInputWrapper :global(.react-autosuggest__suggestions-container--open), .searchPageInputWrapper :global(.react-autosuggest__suggestions-container--open) {
  top: 85px;
}
.searchInputWrapper :global(.react-autosuggest__suggestion), .searchPageInputWrapper :global(.react-autosuggest__suggestion) {
  font-weight: 400;
  font-size: 12.8395px;
  line-height: 24px;
  color: #000000;
}
.searchInputWrapper :global(.react-autosuggest__suggestion), .searchPageInputWrapper :global(.react-autosuggest__suggestion) {
  border: 1px solid transparent;
}
.searchInputWrapper :global(.react-autosuggest__suggestion):hover, .searchPageInputWrapper :global(.react-autosuggest__suggestion):hover {
  border: 1px solid #1db1a9;
  border-radius: 8px;
  font-weight: 700;
}
.searchInputWrapper :global(.react-autosuggest__suggestion):hover .suggetionDotWrap, .searchPageInputWrapper :global(.react-autosuggest__suggestion):hover .suggetionDotWrap {
  font-weight: 700;
}
.searchInputWrapper :global(.react-autosuggest__suggestion), .searchPageInputWrapper :global(.react-autosuggest__suggestion) {
  font-weight: 400;
}
.searchInputWrapper :global(.react-autosuggest__suggestion):after, .searchPageInputWrapper :global(.react-autosuggest__suggestion):after {
  contain: '';
  width: 3px;
  height: 3px;
  display: block;
}
.suggetionDot {
  background-color: #000000;
  height: 2px;
  width: 2px;
  border-radius: 50%;
  margin-right: 15px;
}
.suggetionDotWrap {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.searchInputWrapper [class*='currentLocation'], .searchPageInputWrapper [class*='currentLocation'] {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: relative;
}
.searchInputWrapper [class*='currentLocationIcon'], .searchPageInputWrapper [class*='currentLocationIcon'] {
  display: none;
}
.searchInputWrapper [class*='currentLocation']:after, .searchPageInputWrapper [class*='currentLocation']:after {
  content: '';
  width: 30px;
  height: 25px;
  background: url('../../assets/Landing_Pages/Current_Location_Icon.svg');
  background-repeat: no-repeat;
  top: 3px;
  right: 5px;
  left: -20px;
  /*position: absolute;*/
  display: inline-block;
}

.searchInputWrapper [class*='predictions'] li, .searchPageInputWrapper [class*='predictions'] li {
  font-size: 12.8395px;
  line-height: 24px;
  color: #000000;
  padding: 10px 0 10px 50px;
  border: 1px solid transparent;
}
.searchInputWrapper [class*='predictions'] li:hover, .searchPageInputWrapper [class*='predictions'] li:hover {
  border: 1px solid #1db1a9;
  border-radius: 8px;
}
.searchInputWrapper [class*='predictions'] li:hover [class*='predictionsWrap'], .searchPageInputWrapper [class*='predictions'] li:hover [class*='predictionsWrap'] {
  font-weight: 700;
}

.searchInputWrapper [class*='predictionsWrap'], .searchPageInputWrapper [class*='predictionsWrap'] {
  position: relative;
}
.searchInputWrapper [class*='predictionsWrap']:after, .searchPageInputWrapper [class*='predictionsWrap']:after {
  content: '';
  width: 30px;
  height: 30px;
  background: url('../../assets/Landing_Pages/Map_Icon_Small.svg');
  background-repeat: no-repeat;
  top: 4px;
  right: 5px;
  left: -20px;
  /*position: absolute;*/
  display: inline-block;
}
