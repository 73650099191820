@import '../../marketplace.css';


.genericMessage {
  position: fixed;
  top: calc(var(--topbarHeight) + 50px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: 2;

  @media (--viewportMedium) {
    /* top: calc(var(--topbarHeightDesktop) + 50px); */
    top: 172px;
  }
}

.genericMessageWithButton {
  position: fixed;
  top: 250px;
  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: 100000;

  @media (--viewportMedium) {
    top: 250px;
  }
}

.genericMessageVisible {
  right: 0;
  opacity: 1;
}

.genericMessageContent {
  padding: 27px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericMessageText {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.close {
  /* @apply --marketplaceModalCloseStyles; */
  position: absolute;
  top: -25px;
  right: 0;
  z-index: calc(100 + 1);
  display: flex;
  align-items: flex-start;
  width: auto;
  margin: 0;
  border: 0;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeBtnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(242, 245, 251, 0.8);
  backdrop-filter: blur(10px);
  border: 4px solid var(--matterColorLight);
}
.closeIcon {
  @apply --marketplaceModalCloseIcon;
  margin-left: 0;
  padding: 0;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}