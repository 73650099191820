.sliderWrapper {
  padding: 0 18px;
}

.singleImageContainer {
  margin: 0 auto;
  padding: 24px 12px;
  height: 100vw;
  max-height: 800px;
  object-fit: cover;
  @media (--viewportMobile) {
    padding: 24px 5px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel :global(.slick-track) {
  margin: 0 auto;
}
.carousel :global(.slick-track)div:last-child {
  margin: 0 auto;
  padding: 0;
}

.carousel :global(.slick-dots) ul li {
  margin-right: 10px;
}
.carousel :global(.slick-dots) ul li button:before {
  top: -57px;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: white;
  color: transparent;
}