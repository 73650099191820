@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.summary{
  @apply --EditListingDescriptionForm_formMargins;
  
  & textarea, ::placeholder {
    font-size: 16px;
  }

  & textarea {
    padding: 5px;
  }
}
.title,
.companyName {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.customField {
  @apply --EditListingDescriptionForm_formMargins;
}

.category {
  @apply --EditListingDescriptionForm_formMargins;
}

.subCategory {
  @apply --EditListingDescriptionForm_formMargins;
}

.providerName {
  @apply --EditListingDescriptionForm_formMargins;
}

.addCompanyNameNote{
  @apply --EditListingDescriptionForm_formMargins;
  color: var(--matterColorAnti);
}

.companyListing{
  @apply --EditListingDescriptionForm_formMargins;
}

.bankDetails{
  @apply --EditListingDescriptionForm_formMargins;
}

.submitButton {
  border-radius: 8px;
  min-height: 66px;
  padding: 20px 54px;
  white-space: nowrap;
  width: 257px;
  background-color: var(--marketplaceColor);
  border: 0;
  color: var(--matterColorLight);
  display: flex;
  justify-content: center;
  align-items: center;


  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 257px;
    margin: auto;
    /* margin-top: 100px; */
  }
}

.submitButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.submitButtonWraper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.mutateBtns {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;
  @media (--viewportMobile) {
    margin-bottom: 24px;
  }
  & > button {
    max-width: 200px;
    min-height: 40px;
  }
}

.detailedLabelWithSVG{
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.formGroup {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 50px 80px 40px 80px;

  @media (--viewportMobile) {
    padding: 11px 24px 40px 24px;
  }
}

.btnWrapper {
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 20px 10px 35px;
  margin: 5px;

  @media(--viewportMobile) {
    padding-left: 25px;
  }
}

.topSaveButton {
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 8px;
  border: none;
  padding: 5px;
  cursor: pointer;
  width: 200px;
  &:hover{
    background-color: var(--marketplaceColorLight);
  }
  @media (--viewportMobile) {
    width: 100px;
    font-size: 14px;
  }
}

.topSaveButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.headingTitle {
  font-size: 35px;
  color: white;
  text-transform: capitalize;
  margin: 0;

  @media (--viewportMobile) {
    /* font-size: 20px; */
    display: none;
  }
}
