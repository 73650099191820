@import '../../marketplace.css';

.TripExpertListContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 32px;

  @media (max-width: 1250px) {
    flex-direction: column;
    margin: auto 20px;
  }
}

.title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-transform: capitalize;
  color: #0d1624;
  margin: 50px 0;

  @apply --marketplaceH1FontStyles;

  @media (--viewportTabletDown) {
    text-align: center;
  }

  @media (--viewportMobile) {
    font-size: 22px;
  }
}

.ListWrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 0 28px rgba(24, 98, 90, 0.1);
  border-radius: 15px;
  padding: 20px;
  margin: auto 20px;
  flex: 1;
  height: 320px;
  width: 100%;

  @media (max-width: 1600px) and (--viewportMedium) {
    height: 350px;
  }
}

.imageContainer {
  width: 74px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  background: var(--marketplaceRedDarkColor);
  border-radius: 50%;
  object-fit: contain;
}

.DetailsContainer span {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: #6E6F71;
}

.DetailsContainer h3 span {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin: 20px 0 15px;
  color: #0d1624;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
}

.DetailsContainer {
  background: #ffffff;
  text-align: center;
}