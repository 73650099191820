@import '../../../marketplace.css';

.filtersSectionContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--marketplaceColorBackground);
}

.subContainer {
  max-width: 1501px;
  margin: 0 120px;
  width: calc(100% - 240px);

  @media (--viewportLaptopDown) and (--viewportSmall) {
    margin: 0 50px;
    width: calc(100% - 100px);
  }

  @media (--viewportMobile) {
    margin: 0 30px;
    width: calc(100% - 60px);
  }
}

.wrapper {
  /* position: sticky; */
  /* top: var(--topbarHeight); */
  /* z-index: 999; */
  left: 0;
  min-height: 80px;
  border-bottom: 1px solid rgba(205, 205, 205, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* padding: calc(var(--topbarHeight) + 45px) 2vw 0 2vw; */
  flex-wrap: wrap;
  width: 100%;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 8px;
  height: 12px;
  width: auto;
  transform: translateY(-50%);
}

.filterWrapper {
  position: relative;
  margin: 10px;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  height: 45px;
}

.filtersBarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 100%;
}

.filtersBar {
  display: flex;
  width: 80%;

  @media (--viewportLaptopDown) {
    width: 75%;
  }
}

.filtersWrapper {
  display: flex;
  align-items: center;

  @media (max-width: 1250px) {
    &>*:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
      display: none;
    }
  }

  @media (max-width: 991px) {
    &>*:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
      display: none;
    }
  }

  @media (--viewportMobile) {
    &>*:not(:first-child) {
      display: none;
    }
  }
}


.mobileTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
}

.filtersSwitch {
  font-size: 16px;
  text-decoration: underline;
  line-height: 22px;
  color: var(--marketplaceColor);
  cursor: pointer;
}

.filterBtnWrapper {
  display: flex;
  align-items: center;
}

.filterBtn {
  padding: 5px 15px;
  border: 2px solid #dcdfe4;
  background-color: #fff;
  border-radius: 0.5em;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #ebeef1;
  }

  & span {
    font-size: 16px;

    @media (--viewportMobile) {
      display: none;
    }
  }
}

.boxClass {
  width: 50%;
  height: 80%;

  @media (--viewportLaptopDown) {
    width: 80%;
    height: 80%;
  }

  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
  }
}

.itemTitleBar {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dcdfe4;
  padding: 10px;
}

.filtersSection {
  overflow-y: scroll;
  max-height: 80%;
  height: 80%;
}

.footerSection {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dcdfe4;
  padding: 20px 0 10px 0;
  gap: 10px;
}

.accordionArrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  margin-left: 0.5em;
  padding: 0 0.5em;
  transform: rotateX(0);
  transition: transform .3s ease;

  &:before {
    border-color: #1a2b49;
    border-style: solid;
    border-width: 0 0 0.15em 0.15em;
    content: "";
    height: 0.6em;
    transform: rotate(-135deg);
    transition: transform .2s ease;
    width: 0.6em;
  }
}

.accordionArrowOpen {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  margin-left: 0.5em;
  padding: 0 0.5em;
  transform: rotateX(90);
  transition: transform .3s ease;

  &:before {
    border-color: #1a2b49;
    border-style: solid;
    border-width: 0 0 0.15em 0.15em;
    content: "";
    height: 0.6em;
    transform: rotate(-45deg);
    transition: transform .2s ease;
    width: 0.6em;
  }
}

.closeBtnWrapper {
  width: 20%;
  text-align: end;
}

.closeBtn {
  background: transparent;
  border: 0px;
  color: #0071eb;
  outline: 0px;
  padding: 0.25em 0.5em;
  text-decoration: none;
  transition: color 0.2s ease-out 0s;
}

.dialogHeading {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
}

.headingTitle {
  flex-grow: 1;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  max-width: 60%;
  padding: 0 12px;
  text-align: center;
}

.boldStyle {
  font-weight: bold;
}

.inputFieldWrapper {
  padding: 0px 20px 10px 20px;
}