.listingTravelAgentServiceWrapper {
  padding: 30px 15px;
  /*border-bottom: 1.5px solid #e5e5e5;*/
}

.listingTravelAgentServiceWrapper h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #233045;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.listingTravelAgentServiceWrapper li .textWrap {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
}
.listingTravelAgentServiceWrapper li .ml5 {
  margin-left: 24px;
}
.listingTravelAgentServiceWrapper li p {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
  white-space: nowrap;
}

.listingTravelAgentServiceWrapper p span {
  font-size: 16px;
  color: #6e6f71;
}

.listingTravelAgentServiceWrapper p {
  line-height: 25px;
}
.listingTravelAgentServiceWrapper li h5 {
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  white-space: nowrap;
  margin: 0 0 0 24px;
}
.listingTravelAgentServiceWrapper li img {
  margin-right: 8px;
  position: absolute;
  left: 0;
  top: 9px;
}
.listingTravelAgentServiceWrapper li {
  list-style: none;
  color: #272727;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.TravelIdentityWrapper span {
  margin-left: 12px;
}
.TravelIdentityWrapper {
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 11px;
  width: 104px;
  height: 41px;
  margin-left: 24px;
  background: #f5f5f5;
}
.TravelIdentityWrapper span {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #707d83;
  text-align: right;
}

.ListingDetailsMainTabConatiner {
  display: flex;
  justify-content: center;
}

.listingTravelAgentServiceInfo {
  max-width: 1501px;
  margin: 0 120px;
  width: 100%;

  @media (--viewportLaptopDown) {
    margin: 30px;
  }

  @media (--viewportMobile) {
    margin: 20px 15px;

    & > h2 {
      margin-left: 15px;
    }
  }
}

.Listingtext {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 24px;
  color: #6e6f71;
}
.RoleContent {
  margin-top: 15px;
}
.RoleContent h5 {
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  margin: 0 0 0 24px;
}

.RoleContent p {
  font-size: 16px;
  margin: 0 0 0 24px;
  line-height: 30px;
  white-space: nowrap;
}
