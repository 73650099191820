@import '../../marketplace.css';

.spinner {
  display: block;
  margin: 80px auto;
}
.heading {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.collapseAllBtn {
  text-transform: uppercase;
  color: var(--marketplaceColorDark);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border: none;
}
.tablePlaceholder {
  padding: 20px;
  text-align: center;
}

.helpers {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 18%;
  right: 40px;
  @media (--viewportMedium) {
    position: static;
  }
  @media (--viewportLarge) {
    position: static
  }
  @media (--viewportMobile) {
    position: static;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    position: static;
  }
}  

.collapsePeriod {
  display: flex;
  justify-content: flex-end; 
  @media (--viewportMobile) {
    justify-content: start;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    justify-content: start;
  }
}

.datePicker {
  padding: 14px 0 !important;
  border-bottom-width: 1px;
  font-size: 16px;
} 
.datePickerSecond {
  padding: 14px 0 !important;
  border-bottom-width: 1px;
  font-size: 16px;
  margin-left: 15px;
  @media (--viewportMobile) {
    margin-left: 0;
  }
}
.datePickerSecond .react-datepicker-popper {
  transform: translate3d(580px, 172px, 0px);
}
.dateWrap {
  display: flex;
  flex-direction: row;
  padding-right: 95px;
  @media (--viewportMedium) {
    padding-right: 145px;
  }
  @media (--viewportMobile) {
    flex-direction: column;
  }
}