@import '../../marketplace.css';

/* Top bar styles starts */

.topbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
    justify-content: center;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Top bar styles ends */

.container {
  padding: 24px;
  margin-bottom: 100px;
  @media (--viewportLarge) {
    margin: 100px auto 100px;
    width: 920px;
  }
}

.options {
  display: grid;
  grid-template-columns: 250px;
  grid-row: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  @media (--viewportLarge) {
    grid-template-columns: 250px 250px 250px;
  }
}

.optionItem {
  width: 100%;
  margin-bottom: 18px;
  padding: 20px;
  border: 1px solid var(--matterColorNegative);
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    box-shadow: var(--boxShadowButton);
  }
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.icon {
  width: 100px;
  margin: 0 auto 10px;
  & > svg {
    width: 100px;
    height: 100px;
    fill: var(--marketplaceColorDark);
  }
}

.label {
  text-align: center;
  & p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
}
