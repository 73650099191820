@import '../../marketplace.css';

.mainTabSectionContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  gap: 3rem;

  @media (--viewportTabletDown) {
    /* flex-direction: column-reverse; */
    flex-direction: column;
    align-items: center;
    /* padding: 0 12px; */
  }

  @media (--viewportLaptopDown) and (--viewportSmall) {
    margin: 0 20px
  }

  @media (--viewportMobile) {
    padding: 0 12px;
  }
}

.mainTitle {
  flex: 1;
  width: 100%;
}

.mainTitle h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #000000;
  margin-top: 0;
}

.descriptionFontStyle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgba(39, 39, 39, 0.97);
  min-height: 60px;

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 27px;
  }

  & div {
    padding: 0 0 20px 0;
  }
}

.seeMoreLessLink img {
  margin-right: 8px;
}

.seeMoreLessLink {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  text-decoration: none;
  align-items: center;
}

.msg {
  padding-right: 10px;
  white-space: pre-line;
  word-break: break-all;
  hyphens: auto;
}

.longWord {
  word-break: break-all;
  hyphens: auto;
}

.ListingDetailsMainTabConatiner {
  display: flex;
  justify-content: center;
}

.infoWrapper {
  max-width: 1501px;
  margin: 0 120px;
  width: calc(100vw - 240px);

  @media (--viewportLaptopDown) {
    margin: 0 30px;
    width: calc(100% - 60px);
  }

  @media (--viewportMobile) {
    margin: 0 15px;
    width: calc(100% - 30px);
  }
}

.draftInfoWrapper {
  max-width: 1501px;
  margin: 0 120px;
  width: calc(100vw - 284px - 240px);

  @media (--viewportLaptopDown) {
    margin: 0 30px;
    width: calc(100% - 60px);
  }

  @media (--viewportMobile) {
    margin: 0 15px;
    width: calc(100% - 30px);
  }
}

.ListingDetailsSectionTabMenu [class*='tabLabelItemActive'] {
  font-weight: 700 !important;
  font-size: 22px;
  line-height: 34px;
  color: #00ab99 !important;
  border-bottom: 5px solid #00ab99 !important;
}

.ListingDetailsSectionTabMenu [class*='tabLabelItem'] {
  font-weight: 400;
  font-size: 20px;
  padding: 12px 0;
  line-height: 34px;
  color: #b2aeab;
  margin-right: 40px !important;
}

.ListingDetailsSectionTabMenu {
  border-bottom: 1px solid #e8e8e8;
}

.subDetailsStickyContainer {
  flex: 1;
  background: #ffffff;
  border: 0.832774px solid #707d83;
  box-shadow: 0 8.32774px 38px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  position: sticky;
  top: 120px;
  overflow: hidden;
  max-width: 562px;

  /* display: none; */
  @media (--viewportMobile) {
    position: relative;
    top: 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.starContent {
  display: flex;
  align-items: center;
}

.ratingWrapper svg {
  margin-top: -4px;
}

.ratingWrapper svg path {
  fill: #fff;
  stroke: #346bf6;
  stroke-width: 4px;
  height: 20px;
}

.filledRating svg path {
  fill: #346bf6 !important;
}

.ratingWrapper {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #707d83;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-width: 100px;
  min-height: 41px;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}

.reviewCount {
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  color: #346bf6;
  margin-left: 8px;
}

.profileIdentityWrapper span {
  font-size: 10px !important;
  line-height: 15px !important;
  color: #6e6f71;
}

.profileIdentityWrapper {
  width: 104px;
  height: 41px;
  margin-left: 24px;
  background: #f5f5f5;
  border-radius: 6px;
}

.IdentityWrapper span {
  margin-left: 12px;
}

.IdentityWrapper {
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 11px;
  margin-left: 30px;
}

.IdentityWrapper span {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #707d83;
  text-align: right;
}

.mainContentWrap {
  display: flex;
  align-items: center;
}

.specializationWrapper li .textWrap {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
}

.chip {
  background-color: rgb(240, 239, 239, 0.5);
  border: 1px solid rgb(240, 239, 239, 0.7);
  color: rgb(85, 85, 85);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 111px;
  padding: 2px 10px;
  font-size: 14px;
  margin: 5px;
  text-transform: capitalize;
}

.specializationWrapper li .ml5 {
  margin-left: 24px;
}

.specializationWrapper li p {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
  white-space: wrap;
}

.specializationWrapper li h5 {
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  /* margin: 0 0 0 24px; */
  margin: 0;
}

.specializationWrapper li img {
  margin-right: 8px;
  /* position: absolute;
  left: 0;
  top: 9px; */
}

.specializationWrapper li {
  list-style: none;
  color: #272727;
  display: flex;
  position: relative;
  /* align-items: center; */
}

.rowDirection {
  flex-direction: row;
}

.columnDirection {
  flex-direction: column;
}

.verticalAlignCenter {
  display: flex;
  align-items: center;
}


.specializationWrapper h2 {
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: #233045;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.specializationWrapper h4 {
  font-weight: 700;
  font-size: 18px;
  /* font-style: italic; */
  line-height: 30px;
  color: #233045;
  display: flex;
  margin: 15px 0 5px 0;
  align-items: center;

}

.specializationWrapper {
  padding-bottom: 24px 12px;
  border-bottom: 1.5px solid #e5e5e5;
}

.wrapper {
  min-height: 50px;
  width: 100%;
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}

.subDetailsTabMenu span {
  flex: 1;
}

.subDetailsTabMenu [class*='tabLabelItemActive'] {
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 34px;
  color: #00ab99 !important;
  border-bottom: none !important;
  background: #ffffff !important;
  text-align: center;
  text-transform: uppercase;
  border-radius: 12px 12px 0px 0px;
}

.subDetailsTabMenu [class*='tabLabelItem'] {
  font-weight: 400;
  font-size: 18px;
  padding: 12px 0;
  line-height: 34px;
  text-align: center;
  color: #707d83;
  background: #f2f2f2;
  margin-right: 0 !important;
  text-transform: uppercase;
}

.authorDetailsImageRelative {
  position: relative;
  /* height: 80px;
  min-width: 80px;
  width: 80px; */
  border-radius: 50%;
  border: 1px solid #346bf6;
  box-sizing: border-box;
  /* padding: 22px; */
  display: flex;
  align-items: center;
  justify-content: center;

  /* @media (--viewportMobile) {
    height: 61px;
    width: 61px;
  } */
}

.authorDetailsImageLarge {
  /* height: 62px;
  width: 62px; */
  height: 90px;
  width: 90px;
  min-width: 62px;

  /* @media (--viewportMobile) {
    height: 48px;
    width: 48px;
    min-width: 48px;
  } */
}

.subTabSectionContainer p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #6e6f71;
  font-family: 'Source Sans Pro', sans-serif;
}

.subTabSectionContainer h5 {
  font-weight: 600;
  font-size: 15px;
  line-height: 170%;
  margin: 0;
  color: #272727;
}

.optionTag p {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  margin: 0 0 0 5px;
  color: #475e83;
}

.optionTag h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  margin: 0 0 0 8px;
  color: #00ab99;
}

.optionTag {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 25px;
}

.subTabSectionContainer h2 {
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 700;
  font-size: 28px;
  line-height: 25px;
  color: #233045;
  text-transform: uppercase;
}

.subTabSectionContainer {
  padding: 24px;
}

.userInformation {
  background: #f8f8f8;
  border: 0.722165px solid #6e6f71;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px 24px 16px;
  display: flex;
  flex-direction: column;
}

.userInformationWrap {
  display: flex;

  @media (--viewportTabletDown) {
    flex-direction: column-reverse;
  }
}

.bottomText {
  font-style: italic;
  font-weight: 300;
  margin-top: 24px;
  font-size: 9px;
  /* line-height: 0%; */
  color: #6e6f71;
}

.userListItem {
  flex: 1;

  @media (--viewportMobile) {
    margin: auto 20px;
  }
}

.userListItem li p {
  margin: 0;
}

.userListItem li img {
  margin-right: 16px;
}

.userListItem li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  list-style: none;
}

.authorDetailsImageWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #707d83;

  @media (--viewportTabletDown) {
    border: 0
  }
}

.subTabBtnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.sendRequestButton {
  @apply --marketplaceButtonStyles;
  height: 50px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 22px;
  padding: 0 24px;
  text-transform: capitalize;
}

.supContent {
  /* margin-top: 12px; */
  margin: 22px auto;
  font-weight: 600;
  font-size: 15px;
  color: #3471f2;

  & span {
    font-weight: 700;
    font-size: 30px;
    color: #3471f2;
    margin-left: 6px;
  }
}

.listingBookingSection {
  padding: 24px 0;
}

.pricingText {
  font-weight: 700;
  font-size: 28px;
  line-height: 25px;
  color: var(--matterColorDarkBlue);
  margin: 0 0 20px 41px;
}

.bookingPanelContainer {
  background: var(--matterColorLight);
  border-radius: 26px;
  padding: 35px 29px 39px 33px;
}



.tabLabels {
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
}

.tabLabelItem2 {
  padding: 15px 25px 10px 25px;
  font-size: 18px;
  /* font-weight: var(--fontWeightSemiBold); */
  white-space: nowrap;
  cursor: pointer;
  /* color: var(--matterColor); */
  color: #707d83;
  border-bottom: 5px solid #f5f5f5;

  &:hover {
    border-bottom: 5px solid var(--marketplaceColorDark);
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMobile) {
    padding: 12px;
    font-size: 15px;
  }
}

.infoSectionWrapper {
  position: relative;
}

.commonInfoContainer {
  display: grid;
  grid-gap: 3rem;
  grid-template-areas:
    'overview pricing'
    'locations pricing';
  grid-template-columns: 3fr 2fr;

  @media (--viewportTabletDown) {
    display: flex;
    flex-direction: column;
  }
}

.scrollAnchor {
  position: absolute;
  top: -100px;
  left: 0;
  /* height: 5px;
  width: 5px;
  background-color: transparent; */
}

.tabLabelItemActive {
  border-bottom: 3px solid var(--marketplaceColorDark);
  color: var(--marketplaceColor);
}


.tabsWrapper {
  max-width: 1501px;
  margin: 0 120px;
  background: #f5f5f5;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  align-content: center;
  /* border-bottom: 1px solid #e8e8e8; */
  /* padding: 0 20px; */
  /* padding: 0 auto; */

  @media (--viewportSmall) and (--viewportLaptopDown) {
    margin: 0 20px;
  }
}

.tabsWrapperContainer {
  background: #f5f5f5;
  margin: 50px 0;
  display: flex;
  justify-content: center;
  overflow-x: auto;
}