@import '../../marketplace.css';

.operatingHoursContainer {
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}

.entry {
  display: flex;
  justify-content: space-between;
}

.days,
.time {
  flex: 1 1 50%;
  font-size: 16px;
}

.time {
  text-align: right;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 15px 22px 10px 22px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
}

.entries {
  padding: 5px 22px 15px 22px;
}

.editBtn {
  min-height: 30px;
  min-width: 65px;
  font-size: 16px;
}
