@import '../../../marketplace.css';

.listingCards {
  padding: 24px 16px 16px 75px;
  & a {
    text-decoration: none !important;
  }
  @media (--viewportMobile) {
    padding: 24px 22px 149px;
    width: 100%;
    min-height: 100vh;
  }
}

.listingCard {
  margin-bottom: 20px;
  flex-basis: 100%;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(50% - 12px);
    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.connectPros {
  position: sticky;
  bottom: 0;
  background: #f1f1f1;
  box-sizing: border-box;
  padding: 9px 19px 12px;
  width: 100%;
  margin: 20px 0 0;
  @media (--viewportTabletDown) {
    display: none;
  }

  & .connectProsTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 15px;
    color: var(--matterColorDarkBlue);
    margin: 0 0 14px;
    & svg {
      margin-right: 4px;
      width: 13px;
      height: 22px;
    }
  }

  & .startRequestFormButton {
    background: var(--matterColorLight);
    width: 100%;
    max-width: 236px;
    margin: 0 auto;
    min-height: 40px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    color: var(--marketplaceColor);
  }
}

.jobBoardResultCardRoot {
  margin-bottom: 15px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 100%;
  }
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 11px 24px 12px;
    /* border-left: 10px solid #b3ceea; */
  }
}

.jobBoardResultCardWrapper {
  @media (--viewportMedium) {
    display: flex;
    column-gap: 8px;
  }
}

.authorInfoWrapper {
  display: flex;
  column-gap: 9px;
  align-items: center;
  @media (--viewportMedium) {
    column-gap: 35px;
  }
}

.authorInfoWrapper .authorInfo {
  display: flex;
  flex-direction: column;
}
.authorName {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--matterColorDark);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.authorPostedTime {
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #9e9e9e;
}

.currentListingTitleDescriptionWrapper {
  display: flex;
  align-items: center;
  column-gap: 32px;
  margin: 4px 0 17px;
  flex-direction: column;
  align-items: flex-start;
  width: 370px;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.listingTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* width: 370px; */
  text-align: start;
  margin-bottom: 30px;
  @media (--viewportTabletDown) {
    margin: 9px 0 6px;
  }
}
.svgWrapper {
  display: flex;
  align-items: center;
  column-gap: 19px;
}

.svgWrapper svg {
  width: 25px;
}

.addressSvgWrapper {
  display: flex;
  align-items: center;
  column-gap: 19px;
  margin-bottom: 20px;
  @media (--viewportMobile) {
    margin-bottom: 0;
    margin-top: 10px;
  }
}
.addressSvgWrapper svg {
  width: 25px;
}
.currentListingAddress {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 17px;
  text-decoration-line: underline;
  color: var(--matterColorDark);
}

.detailInfoWrapper {
  display: flex;
  column-gap: 30px;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  margin-top: 4px;
  @media (--viewportMobile) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.descriptionContent {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--matterColorDark);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* width: 53%; */
  max-height: 54px;
}
.intrestedButton {
  display: block;
  height: 36px;
  background: #001f3f;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--matterColorLight);
  padding: 8px 25px;
  width: 100%;
  min-height: 36px;
}

.timeInfoWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  row-gap: 10px;
  @media (--viewportMobile) {
    margin-left: 0;
    width: 100%;
  }
}

.timesInfoTitle {
  width: 100%;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 17px;
  color: var(--matterColorDark);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.avatarUser {
  height: 26px;
  width: 26px;
  background: #c4c4c4;
  & span {
    font-size: 24px;
  }

  @media (--viewportMedium) {
    height: 38px;
    width: 38px;
  }
}
