@import '../../marketplace.css';
.mainPageContainer {
  position: relative;
}
.jobsBoardContainer {
  max-width: 1190px;
  margin: 100px auto 73px;
  @media (--viewportMobile) {
    margin: 50px auto 73px;
  }
  & .mainHeading {
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    /* identical to box height, or 140% */
    color: #272727;
    max-width: 550px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin: 30px 0 100px 0;
    & .freelanceBox {
      font-weight: 600;
      font-size: 25px;
      line-height: 35px;
      text-align: center;
      background: #10d6eb33;
      color: var(--marketplaceColor);
      width: 247px;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 25px;
    }
    & > svg {
      top: -18px;
      right: -4px;
      position: absolute;
    }
  }
  & .mainHeader {
    display: flex;
    justify-content: space-between;
    height: auto;
    @media (--viewportMobile) {
      padding: 0 20px;
    }
    & .InfoSection {
      max-width: 464px;
      display: flex;
      flex-direction: column;
      & h1 {
        font-weight: bold;
        font-size: 45px;
        line-height: 65px;
        color: var(--matterColorLight);
        & .blueText {
          color: var(--marketplaceColor);
        }
        @media (--viewportMobile) {
          font-size: 30px;
          line-height: 45px;
        }
      }
      & p {
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: var(--matterColorLight);
        @media (--viewportMobile) {
          font-size: 15px;
        }
      }
    }

    & .imageSection {
      width: 516px;
      height: 366px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.createVendorListButton {
  width: 238px;
  height: 65px;
  padding: 20px;
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: var(--matterColorLight);
  background: var(--matterColorLightBlue) !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  @media (--viewportMobile) {
    width: fit-content;
    height: 50px;
    font-size: 15px;
    line-height: 19px;
    white-space: nowrap;
  }
}

.mainPanelSection {
  & :global(#checkboxWrap) {
    margin-top: 20px;
  }
  & :global(#checkboxWrap) > div {
    margin: 0 18px 0 0;
  }
}

.resultHead {
  padding-left: 75px;
  margin-top: 60px;
  @media (--viewportTabletDown) {
    padding-left: 22px;
    margin-top: 20px;
  }

  @media (--viewportLaptop) {
    margin-top: 90px;
  }
}

.coachesInfo {
  font-weight: bold;
  font-size: 30px;
  line-height: 51px;
  text-transform: capitalize;
  color: #272727;
  margin: 38px 0 0 0;
  @media (--viewportMobile) {
    font-size: 25px;
    line-height: 30px;
    margin: 35px 0 0 0;
  }
}

.resultsFound {
  font-weight: normal;
  font-size: 18px;
  line-height: 37px;
  color: var(--matterColorDimmedText);
  display: none;
  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 20px;
  }
}

.topSection {
  background: var(--marketplaceColorBackground);
  margin-top: -5px;
}

.imageContainer {
  max-width: 432px;
  height: 325px;
  position: relative;
  z-index: 1;
  left: -12%;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 51px;
  }
  & svg {
    position: absolute;
    left: -55px;
    bottom: -42px;
    z-index: -1;
  }
  @media (--viewportMobile) {
    display: none;
  }
}

.searchFilters {
  margin-top: 24px;
  & :global .SelectSingleFilterPopup_menuLabel__2dJH0 {
    min-width: 100px;
  }
}

.imageContainerData {
  display: none;
  @media (--viewportMobile) {
    display: block;
    max-width: 100%;
    height: 233px;
    position: relative;
    z-index: 2;
    margin-bottom: 60px;
    & img {
      width: 80%;
      height: 100%;
      object-fit: contain;
      border-radius: 45px;
      padding: 0 20px;
      z-index: 1;
      position: absolute;
      right: 24px;
    }
    & svg {
      position: absolute;
      z-index: -1;
      top: 80px;
      left: 20px;
    }
  }
}

.subContainer {
  display: flex;
  @media (--viewportMobile) {
    flex-direction: column-reverse;
  }
}

.sectionMapContainer {
  /* position: relative; */
  width: 100%;
  padding: 0px 4.7%;
  @media (--viewportMobile) {
    padding: 0px 0px;
  }
}

.draggableWrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  background: var(--matterColorLight);

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    z-index: 0;
    /* max-width: 1089px; */
  }
  @media (--viewportMobile) {
    border-radius: 25px 25px 0 0;
    /* background: red; */
  }
}

.draggableHandler {
  padding: 5px 0;
  background: var(--matterColorLightGrey);
  cursor: grab;
  @media (--viewportMobile) {
    border-radius: 27px 27px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--matterColorLight);
    padding: 9px 0 11px;
  }
}

.mapBtn {
  position: fixed;
  bottom: 170px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px;
  width: 100px;
  height: auto;
  z-index: 3;
  background: var(--matterColorLight);
  border-radius: 20px;
  box-shadow: var(--boxShadowFilterButton);
  text-align: center;
  cursor: pointer;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
  opacity: 0;
  visibility: hidden;
  & svg {
    margin-right: 10px;
    fill: var(--marketplaceColorDark);
    width: 20px;
  }
  &.shown {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s linear;
  }
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: sticky;
    /* top: var(--topbarHeightDesktop); */
    top: 114px;
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 100vw;
    /* height: var(--SearchPage_containerHeight); */
  }

  @media (min-width: 1750px) {
    width: calc(100vw - 1089px) !important;
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}
.mapWrapper :global .mapboxgl-canvas {
  width: 37.5vw !important;
  @media (min-width: 1750px) {
    width: calc(100vw - 1089px) !important;
  }
  @media (--viewportTabletDown) {
    width: 100% !important;
    height: 100% !important;
  }
}

.mapPanel {
  position: absolute;
  top: 0;
  z-index: 1;

  @media (--viewportLarge) {
    /* position: static; */
    position: sticky;
    top: 114px;
    right: 0;
    height: 100vh;
    /* margin-top: 48px; */
    flex-basis: 37.5%;
    /* z-index: 0; */
  }
}

.filters {
  /* margin-bottom: 24px; */
  padding-bottom: 21px;
  /* border-bottom: 1px solid #e1e1e1; */
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
  @media (--viewportMobile) {
    margin-top: 21px;
    margin-right: 0px !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.otherFilters {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  @media (--viewportMedium) {
    overflow-x: visible;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    justify-content: center;
    row-gap: 48px;
  }
}

.desktopSearchFilter {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.mobileSearchFilter {
  display: block;
  padding: 30px 4px 34px;
  @media (--viewportMedium) {
    display: none;
  }
  & :global(#checkboxWrap) {
    flex-wrap: nowrap;
    justify-content: center;
    column-gap: 8px;
  }
}

.filterTypeButtonWrapper {
  display: flex;
  gap: 0 11px;
  padding: 28px 15px 28px 70px;
  background: var(--matterColorLight);
  @media (--viewportMobile) {
    gap: 0 7px;
    width: 100%;
    padding: 0px 19px 21px 17px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    display: none;
  }
  & .filterTypeButton {
    background: var(--matterColorLight);
    border: 1.5px solid #f1f1f1;
    padding: 13px 22px;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: var(--marketplaceColorDarkest);
    @media (--viewportMobile) {
      font-size: 13px;
      line-height: 20px;
      white-space: nowrap;
      padding: 10px 18px 12px;
    }
  }

  & .selectedFilterTypeButton {
    background: #e7fbff;
    color: var(--marketplaceColor);
  }
}

.filterKeywordWrap {
  justify-content: left;
  align-items: center;
  margin-left: 77px;
  display: none;
  @media (--viewportMedium) {
    display: flex;
  }
}

.filterWrap {
  width: 375px;
  height: 46px;
  padding: 0 6px 0 16px;
  background: var(--matterColorLight);
  border-radius: 28px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;
  @media (--viewportMobile) {
    width: 100%;
    border-radius: 28px;
    margin-bottom: 18px;
  }
}
.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;
  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.filterWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
}
.filterKeywordWrap input {
  width: 214px;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
  @media (--viewportMobile) {
    width: 330px;
  }
}

.btnapply {
  width: 85px;
  min-width: 63px;
  border-radius: 16.5601px;
  min-height: 33px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  margin-left: 14px;
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  @media (--viewportMobile) {
    display: none;
  }
}

.topFilterTypesWrapper {
  @media (--viewportMobile) {
    display: none;
  }
}

.mobileFilterTypeElement {
  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersWrapper {
  position: relative;
  min-height: 100px;
  @media (--viewportMobile) {
  }
}

.connectPros {
  background: #f1f1f1;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  width: 100px;
  min-width: 100%;
  height: auto;
  position: fixed;
  z-index: 3;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 10px 22px 8px;
  margin: 0 auto;
  @media (--viewportMedium) {
    display: none;
  }

  &.shown {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s linear;
  }
  & .connectProsTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 17px;
    line-height: 12px;
    color: var(--matterColorDarkBlue);
    margin: 0 0 15px;
    & svg {
      margin-right: 4px;
      width: 11px;
      height: 18px;
    }
  }

  & .startRequestFormButton {
    background: var(--matterColorLight);
    width: 100%;
    max-width: 279px;
    margin: 0 auto;
    min-height: 47px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    text-decoration: none !important;
    color: var(--marketplaceColor);
  }
}
