@import '../../../../marketplace.css';

.classCards {
  /* padding: 0 0 0 28px;
  width: 59vw; */
  padding: 16px 16px 16px 75px;
  @media (--viewportTabletDown) {
    padding: 0;
    width: 100%;
  }
}
