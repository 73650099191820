@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
  row-gap: 14px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.getQuoteButton{
  width: 180px;
  min-height: 65px;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: var(--matterColorLight);
  background: var(--marketplaceColor) !important;
  border-radius: 6px;
  margin-top: 68px;
  @media (--viewportMobile) {
    margin: 30px auto 0;
  }
}

.formHeading{
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: var(--matterColorDarkBlue);
    margin: 0 0 24px;
}

.formContainer{
  background: rgba(0, 149, 179, 0.04);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px;
  padding: 39px 49px 47px;
  @media (--viewportMobile) {
    padding: 39px 28px 47px;
  }
  & input{
    background: var(--matterColorLight);
    height: 53px;
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: var(--matterColorDarkBlue) !important;
    padding: 16px 13px;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid transparent;
    border-bottom: 4px solid transparent;
    &::placeholder{
      color: #BCBCBC !important;
    }
    &:focus,
    &:hover{
      border: 1px solid var(--marketplaceColor);
      filter: drop-shadow(0px 7px 10px rgba(0, 149, 179, 0.08));
      border-bottom: 4px solid var(--marketplaceColor);
    }
  }
  & :global(label) {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--matterColorDark);
  }
}
