@import '../../marketplace.css';

.action{
  margin-top: auto;
}

.actionBtn{
  & > a{
    @apply --marketplaceButtonStylesSecondary;
    text-decoration: none;
  }
}

.primaryActionBtn{
  & > a{
    @apply --marketplaceButtonStylesSecondary;
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
    text-decoration: none;
    &:hover{
      color: var(--marketplaceColorDark);
    }
  }
}


.pricingHeading{
  max-width: 1154px;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 991px) {
      margin: 0 25px;
  }
  & h2{
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 55px;
      text-align: center;
      text-transform: capitalize;
      color: #272727;
      margin: 0;
      @media (max-width: 991px) {
      font-size: 30px;
      line-height: 35px;
      }
  }

  & span{
      font-style: normal;
      font-weight: normal;
      max-width: 815px;
      font-size: 20px;
      line-height: 35px;
      text-align: center;
      padding: 24px 0 33px 0;
      color: var(--matterColorDark);
      @media (max-width: 991px) {
          font-size: 14px;
          line-height: 25px;
      }
  }
  & button{
      /* background: var(--matterColorLightBlue) !important; */
      background: var(--marketplaceColor) !important;
      border-radius: 50px;
      width: 239px;
      height: 62px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      text-transform: capitalize;
      color: var(--matterColorLight);
      @media (max-width: 991px) {
          width: 228px;
          height: 64px;
          line-height: 27px;
      }
  }
}

.planContainer{
  max-width: 1154px;
  margin: 48px auto 0;
  display: flex;
  justify-content: space-evenly;
  @media (--viewportMobile) {
      display: block;
      margin: 42px 0 0 0;
  }
}

.planHeading{
  display: flex;
  justify-content: space-between;
  & h3{
      font-weight: bold;
      font-size: 40px;
      line-height: 55px;
      text-align: center;
      text-transform: capitalize;
      color: #272727;
      margin: 0;
      @media (--viewportMobile) {
          font-size: 30px;
      }
  }
  & h4{
      font-weight: bold;
      font-size: 40px;
      line-height: 55px;
      text-align: center;
      text-transform: capitalize;
      color: var(--marketplaceColorLight);
      margin: 0;
      @media (--viewportMobile) {
          font-size: 30px;
      }
  }
  & span{
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      text-transform: capitalize;
      color: var(--matterColorAnti);
      @media (--viewportMobile) {
          font-size: 16px;
      }
  }
}

.planHeadingWhite{
  & h3{
      color: var(--matterColorLight);
  }
  & h4{
      color: var(--matterColorLight);
  }
  & span{
      color: var(--marketplaceColor);
  }
}

.planPriceWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px 0;
}

.planSection{
  width: 500px;
  height: auto;
  background: var(--matterColorLight);
  border: 1px solid #D3D3D3;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.03);
  border-radius: 22px;
  padding: 37px 45px 27px;
  @media (--viewportMobile) {
      width: 100%;
      height: auto;
      padding: 14px 23px 15px;
  }
  & a{
      width: 389px;
      min-height: 62px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      text-transform: capitalize;
      color: #0095B3;
      background: #FFFFFF !important;
      border: 1px solid #0095B3;
      box-sizing: border-box;
      border-radius: 49.5px;
      margin: 21px auto 8px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (--viewportMobile) {
          width: 100%;
      }
  }
}

.planSection2{
  width: 500px;
  height: auto;
  background: var(--marketplaceColorLight);
  box-shadow: 0px 30px 40px 10px rgba(0, 149, 179, 0.15);
  border-radius: 22px;
  padding: 37px 45px 27px;
  @media (--viewportMobile) {
      width: 100%;
      height: auto;
      margin-top: 28px;
      padding: 14px 23px 15px;
  }
  & a{
      width: 389px;
      min-height: 62px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      text-transform: capitalize;
      color: #0095B3;
      background: #FFFFFF !important;
      border: 1px solid #0095B3;
      box-sizing: border-box;
      border-radius: 49.5px;
      margin: 21px auto 8px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (--viewportMobile) {
          width: 100%;
      }
  }
}

.planLists{
  display: flex;
  flex-direction: column;
  & h3{
      font-weight: bold;
      font-size: 30px;
      line-height: 55px;
      text-transform: capitalize;
      color: rgba(196, 196, 196, 0.6);
      margin: 31px 0 7px;
      @media (--viewportMobile) {
          font-size: 20px;
          line-height: 30px;
          margin: 28px 0 8px;
      }
  }
  & span{
      font-weight: normal;
      font-size: 20px;
      line-height: 25px;
      text-transform: capitalize;
      color: var(--matterColorDarkBlue);
      display: flex;
      margin-bottom: 22px;
      @media (--viewportMobile) {
          font-size: 15px;
          line-height: 25px;
          margin-bottom: 17px;
          align-items: center;
      }
  }
  & svg{
      margin-right: 13px;
      @media (--viewportMobile) {
          width: 18px;
          height: 18px;
      }
  }
   
}

.planListsWhite{
  & h3{
      color: rgba(255, 255, 255, 0.5);
  }
  & span{
      color: var(--matterColorLight);
  }    
}

.billedMonthly{
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  text-transform: capitalize;
  color: var(--matterColorAnti);
  @media (--viewportMobile) {
      font-size: 14px;
  }
}
.whiteText{
  color: var(--matterColorLight);
}

.planGreyWrap{ 
  background: #F8FBFC;
}
.selectPricesection{
  padding: 44px 0 99px;
  @media (--viewportMobile) {
      padding: 56px 19px 46px;
  }
}

