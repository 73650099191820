@import '../../marketplace.css';

* { -webkit-overflow-scrolling: touch;}

.topIcons {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: inherit;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
    padding: 15px;
    flex-direction: row;
    background-color: var(--matterColorBright);
    @media (--viewportSmall) {
        display: none;
    }
}

.icon {
    min-width: 140px;
    word-break: break-word;
    display: flex;
    margin: 0.5em 0.5em;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
}

.icon_item {
    border-radius: 10px;
    box-shadow: 0 0px 13px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.icon_item p{
    margin: 0px;
    font-size: 11px;
}

.icon_link{
    display: flex;
    width: 100%;
}

.img {
    width: 75px;
}

.iconName {
    font-size: 16px;
    text-align: left;
    color: var(--matterColorDarkBlue);
    margin-top: 10px;
    margin-bottom: 5px;
}

.title {
    @apply --marketplaceH1FontStyles;

    margin-top: 20px;
    margin-left: 24px;
    max-width: 735px;
    font-size: 25px;
  
    @media (--viewportSmall) {
      margin-bottom: 23px;
      display: none;
    }
}

.title > span {
  color: var(--matterColor);
}

.subTitle {
    margin-top: 4px;
    font-size: 14px;
    color: rgb(72,72,72);
    margin-bottom: -12px;
}

.subTitle {
  font-size: 18px;
  color: var(--matterColor);
}

.iconDescription {
    font-size: 10px !important;
    line-height: 14px;
    margin: 0;
    color: var(--matterColorDarkBlue);
    text-align: left;
    flex-grow: 1;
}

.iconResultLink{
    align-self: start;
    font-size: 10px;
    font-weight: 600;
    color: var(--matterColorPink);
    margin: 5px 0px 0px 0px !important;
    text-align: left;
}

.iconResultLinkArrow{
    color: var(--matterColor);
    margin-right: 3px;
}

.titleHighlight span{
    color: var(--matterColorLightBlue) !important;
}
