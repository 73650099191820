table th {
  width: 16%;
}

table td {
  width: 16%;
  /* white-space: wrap; */
  text-align: center;
  font-size: 16px;
  background-color: white;
}

table tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
