@import '../../marketplace.css';
.progressReports {
  width: calc(100% - 48px);
  margin: 12px 24px 24px 24px;

  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  }
}
.title{
  margin: 0px 64px 64px 64px;
}