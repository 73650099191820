@import '../../marketplace.css';

.root {
}

.iconWhatsappLink{
    background-color: var(--successColor);
    color: var(--matterColorLight);
    border: none;
    border-radius: 8px;
    padding: 19px 23px;
    text-decoration: none !important;
    height: 54px;
    display: flex;
    align-items: center;
    font-style: italic;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    text-transform: capitalize;

    @media (--viewportMobile) {
        padding: 10px 25px;
        white-space: nowrap; 
    }
}

.invertIconWhatsappButton{
    width: 18px;
    height: 18px;
    margin: 0 6px 0 0;
}