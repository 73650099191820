@import '../../marketplace.css';


.ImagesSection{

}
.ImagesContainer{
    display: flex;
    @media (--viewportMobile) {
        display: block;
        column-count: 2;
        gap: 0;
    }
}

.desktopSVG{
    @media (--viewportMobile) {
        display: none;
    }
}

.ImagesContainer svg{
    width: 100%;
    height: auto;
}

.madeEasySection{
    max-width: 1523px;
    margin: 58px auto 0;
    @media (--viewportLarge) {
        margin: 98px auto 0;
    }
}

.socialSearchMarketingTitle{
 margin: 0;
 font-weight: bold;
 font-size: 28px;
 line-height: 33px;
 color: var(--marketplaceColorDarkest);
 @media (--viewportLarge) {
    font-size: 40px;
    line-height: 50px;
 }
}

.mainContainer{
    margin: 0 7%;
    @media (--viewportMobile) {
        margin: 0 19px;
    }
}

.subHeading{
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: var(--marketplaceColor);
    @media (--viewportLarge) {
        font-size: 20px;
        line-height: 25px;
    }
}

.greyContainer{
    width: 100%;
    margin: 7px 0 34px;
    & svg{
        width: 100%;
        height: auto;
    }
    @media (--viewportTabletDown) {
        width: 100%;
        height: auto;
    }
    @media (--viewportLarge) {
        margin: 31px 0 50px;
    }
}

.textSection{
    display: flex;
    flex-direction: column;
    gap: 38px  0;   
    @media (--viewportLarge) {
        flex-direction: row;
        gap: 0 80px;    
    }
}

.infoContainer{
    width: 100%;
}
.infoTitle{
 margin: 0 0 22px;
 font-weight: bold;
font-size: 22px;
line-height: 28px;
color: var(--marketplaceColorDarkest);
    @media (--viewportMobile) {
        margin: 0 0 7px;
    }
}

.infoBody{
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: var(--matterColorDimmedText);
    @media (--viewportMobile) {
        line-height: 20px;
    }
}

.smallImageContainer{
    width: 100%;
    height: auto;
    max-height: 477px;
    border-radius: 8px 8px 0 0;
    & img{
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0 0;
    }
    @media (--viewportMobile) {
        height: 357px;
    }
}

.relatedCards{
 display: flex;
 flex-direction: column;
 gap: 23px 0;
 margin-bottom: 126px;
 @media (--viewportLarge) {
    flex-direction: row;
    gap: 0 19px;
    justify-content: space-between;    
 }
}

.relatedItem{
    width: 494px;
    height: auto;
    background: var(--matterColorLight);
    border: 1px solid var(--matterColorAnti);
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 auto;
    @media (--viewportMobile) {
        width: 100%;
    }
    @media (--viewportLarge) {
        margin: 0;
    }
}

.relatedHead{
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: var(--marketplaceColorDarkest);
    @media (--viewportLarge) {
        font-size: 35px;
        line-height: 44px;
    }
}

.relatedBody{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--marketplaceColor);
    @media (--viewportLarge) {
        font-size: 25px;
        line-height: 31px;
    }
}

.relatedInfoSection{
    display: grid;
    padding: 12px 22px 27px;
    @media (--viewportMobile) {
        padding: 10px 16px 18px;
    }
}

.relatedTitle{
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    color: var(--marketplaceColorDarkest);
    margin-bottom: 23px;
    @media (--viewportLarge) {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 52px;
    }
}

.marketingPlan{
    background: var(--matterColorLight);
    border: 1px solid var(--matterColorAnti);
    box-sizing: border-box;
    box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 345px;
    margin: 0 auto;
    @media (--viewportMobile) {
        width: 100%
    }
    @media (--viewportLarge) {
        margin: 0;
    }
}

.premiumPlan{
     background: linear-gradient(180deg, var(--marketplaceColor) 0%,var(--marketplaceColorLight) 100%) !important;
}

.premiumPlan .planCategory{
    color: var(--matterColorLight);
    &::after{
        background: var(--matterColorLight);
    }

}

.premiumPlan .planPrice{
    color: var(--matterColorLight);
}

.premiumPlan .planInfo{
    color: var(--matterColorLight);
    &::marker{
        color: var(--matterColorLight);
    }
}

.premiumPlan .planBtn button{
    color: var(--marketplaceColorDarkest);
    background: var(--matterColorLight);
}

.plan{
    height: 100%;
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    background: var(--matterColorLight);
    box-shadow: 0px 14.4433px 72.2165px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    @media (--viewportMobile) {
    
    }
}

.planWrap {
    display: grid;
    grid-template-columns: 1fr;
    flex: 1;
    padding: 45px 36px 32px;
    @media (--viewportMobile) {
        padding: 45px 22px 21px;
    }
  }

  .planCategoryContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 14px;
  }

  .planCategory {
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    color: var(--marketplaceColorDarkest);
    position: relative;

    &::after{
      content: '';
      height: 3px;
      width: 50px;
      background: var(--marketplaceColor);
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      @media (--viewportLarge) {
        bottom: -8px;
      }
    }
  }

  .planPrice {
    font-weight: bold;
    font-size: 45px;
    line-height: 57px;
    text-transform: uppercase;
    color: var(--marketplaceColor);
    text-align: center;
    padding: 49px 0 58px;
  }

  .planInfoContainer {
    margin: 0 7px 5px 20px;
    min-height: 83px;
    @media (--viewportLarge) {
      margin: 0 0 5px 23px;
      min-height: 115px;
    }
  }

  .planInfo{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: var(--matterColorDimmedText);
    word-break: break-all;
    margin: 0 0 23px;
    list-style-type: disc;
    &::marker{
        color: var(--marketplaceColor);
    }
    @media (--viewportMobile) {
        &::marker{
          font-size: 12px;
          margin-top: 12px;
        } 
    }
  }

  .planInfoSection{
      padding: 0 18px;
  }


  .planBtn{
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    & button{
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: var(--matterColorLight);
      text-align: center;
      background: #3DD170;
      border-radius: 3px;
      box-sizing: border-box;
      min-height: 76px;
    }
  }

  .svgWrap{
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background: linear-gradient(#FFC121, #FF6B18);
    display: flex;
    align-items: center;
    justify-content: center;
  & svg{
    width: 25px;
    height: 18px;
  }
}

.marketingPlanSection {
    display: flex;
    flex-direction: column;
    gap: 42px 0;
    justify-content: center;
    padding: 62px 0 63px;
    @media (--viewportLarge) {
        flex-direction: row;
        gap: 0 53px;
        padding: 126px 0 96px;
    }
}

.highLights{
    display: block;
    padding-top: 24px;
    font-weight: 600;
    color: var(--marketplaceColorDarkest);
}