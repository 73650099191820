@import '../../../../marketplace.css';
.form {
    padding: 35px 52px 35px 0;
    max-width: 448px;
    height: auto;
    background-color: var(--matterColorLight);
  
    /* margin: 0 120px; */
    @media (--viewportMobile) {
      max-width: 100%;
      width: 100%;
      height: auto;
      padding: 36px 26px 32px;
    }
  
    & h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 40px;
      max-width: 368px;
      text-transform: capitalize;
      color: #233045;
      margin: 0;
  
      @media (--viewportMobile) {
        font-size: 30px;
        line-height: 48px;
      }
    }
  
    & h2 {
      font-weight: 400;
      font-size: 17px;
      line-height: 30px;
      color: var(--matterColorDark);
      margin: 14px 0 2px 0;
  
      @media (--viewportMobile) {
        font-size: 20px;
      }
    }
  
    & .field {
      display: flex;
      width: 327px;
      height: 50px;
      margin-bottom: 8px;
      padding: 14px 16px;
      background: #F3F3F3;
      border: 1px solid #eaeaea;
      border-radius: 8px;
      align-items: center;
  
      @media (--viewportMobile) {
        width: 100%;
        height: 68px;
      }
  
      &:last-of-type {
        margin-bottom: 36px;
      }
  
      &>div {
        width: 100%;
      }
  
      & input {
        flex: 1 0;
        padding-left: 10px;
        border-bottom: none;
        height: auto;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: var(--matterColorDark);
  
        &:placeholder {
          color: #B2AEAB;
        }
  
        @media (--viewportMobile) {
          font-size: 22px;
          line-height: 30px;
        }
      }
  
      & .practiceIcon {
        width: 24px;
      }
    }
  
    & .selectField {
      display: flex;
      width: 327px;
      height: 50px;
      margin-bottom: 13px;
      /* padding: 16px; */
      background: var(--matterColorLight);
      border: 1px solid #eaeaea;
      border-radius: 8px;
      align-items: center;
      padding: 12px 16px;
  
      @media (--viewportMobile) {
        width: 100%;
        height: 68px;
      }
  
      &:last-of-type {
        margin-bottom: 16px;
      }
  
      &>div {
        width: 100%;
      }
  
      & select {
        flex: 1 0;
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        text-transform: capitalize;
        color: #455A64;
        padding-left: 10px;
        padding-right: 16px;
        border-bottom: none;
  
        /* height: 18px; */
        @media (--viewportMobile) {
          font-size: 22px;
          line-height: 30px;
        }
      }
  
      & .practiceIcon {
        width: 24px;
      }
    }
  
    & .location {}
  
    & .locationIcon {
      width: 24px;
      display: flex;
      align-items: center;
  
      & svg {
        transform: scaleX(-1);
        display: block;
        margin: 0 auto;
  
      }
    }
  
    & .submit {
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      text-transform: capitalize;
      color: var(--matterColorLight);
      border-radius: 8px;
      width: 327px;
      height: 50px;
      background-color: var(--marketplaceColorLight);
  
      @media (--viewportMobile) {
        width: 100%;
        font-size: 22px;
        font-weight: bold;
        line-height: 30px;
        height: 68px;
      }
    }
  }