@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0px;
  }
}

.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.heroContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1170px;
  width: 100%;
  height: 100%;

  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.heroMainTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);

  @apply --SectionHero_animation;
  animation-delay: 0.5s;

  font-size: 25px;
  line-height: 35px;
  letter-spacing: -1.5px;

  margin-bottom: 0;

  @media (--viewportMedium) {
    color: var(--matterColor);
    font-size: 36px;
    line-height: 36px;
    text-align: center;
  }

  @media (--viewportLarge) {
    color: var(--matterColor);
    font-size: 36px;
    line-height: 36px;
    text-align: center;
  }
}

.logo {
  width: 100px;
  height: 100px;
  margin-right: 10px;

  background: url('../../assets/landingPage/logo_light.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  @media (--viewportMedium) {
    width: 50px;
    background: url('../../assets/landingPage/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.heroSubTitle {
  @apply --marketplaceBodyFontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  text-align: left;

  @apply --SectionHero_animation;
  animation-delay: 0.65s;

  letter-spacing: -0.1px;

  @media (--viewportMedium) {
    margin: 0 0 63px 0;
    font-size: 16px;
    color: var(--matterColor);
    text-align: center;
    margin: 0px 200px;
  }

  @media (--viewportLarge) {
    font-size: 16px;
    color: var(--matterColor);
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  @apply --SectionHero_animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.halfSectionWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1170px;
  width: 100%;
  height: 100%;
  /*max-width: 1501px;*/
  margin: 0 auto;
}

.halfSectionWrapWithImages {
  max-width: 100%;
  position: relative;
}
.sectionSearchContainer {
  margin: 0 auto 32px;
  padding: 0 0 0 24px;
  width: 100%;
  height: 100%;

  @media (--viewportTabletDown) {
    padding: 0;
    margin-bottom: 0;
  }

  @media (--viewportLaptop) {
  }
}

.findProvidersTitle p {
  margin: 15px 0 0;
  font-weight: bold;
  font-size: inherit;
  line-height: 46px;
  color: #ffffff;
}
.findProvidersTitle {
  font-weight: bold;
  font-size: 48px;
  line-height: 46px;
  color: #ffffff;
  margin: 43px 0 24px;
  z-index: 999;
  & span {
    color: var(--marketplaceColor);
  }

  @media (--viewportLaptopDown) {
    font-size: 35px;
    line-height: 35px;
    margin: 50px 0 50px 0;
  }
  /* @media (--viewportMobile) {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    margin: 0 0 0 2rem;
  } */

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    font-size: 40px;
    line-height: 25px;
  }

  @media screen and (min-width: 1441px) and (max-width: 1490px) {
    font-size: 45px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
}

.trainerPromo {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  right: 0;
  bottom: 0;
  height: 414px;
  background-repeat: no-repeat;
  max-width: 620px;
  background-size: 420px;
  background-position: center;

  @media (--viewportLaptop) {
    /* height: 620px; */
    height: 100%;
    /* width: 620px;
    background-size: 89%; */
    background-position: inherit;
    margin-left: 74px;
  }
  @media (--viewportTabletDown) {
    height: 100%;
    max-width: 100%;
    justify-content: center;
  }
}
.trainerPromoText {
  margin: 20px -24px 70px 0;
  padding: 10px;
  border-radius: 4px;
  /* birthday */
  @media screen and (max-width: 1440px) {
    width: 48%;
  }

  @media (--viewportTabletDown) {
    width: 25%;
  }
  @media (--viewportMobile) {
    width: 36%;
    margin: 20px -24px 0 0;
    position: relative;
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 24px;
  }
  /* birthday over */
  & svg {
    width: 104px;
    height: 20px;
  }
  & p {
    margin: 0;
    font-weight: bold;
    font-weight: 900;
    font-size: 23.56px;
    line-height: 50px;
    color: var(--matterColorDark);
    @media (--viewportMobile) {
      font-size: 18px;
      line-height: 23px;
      color: var(--matterColorLight);
    }
  }
  & a {
    color: var(--matterColor);
    font-weight: bold;
    font-size: 16px;
  }
  @media (--viewportMedium) {
    & a {
      font-size: 20px;
    }
  }
}

.imageContainer {
  height: auto;
  max-width: 600px;
  position: absolute;
  margin-top: 37px;
  top: 60px;
  right: 21px;
  z-index: -1;
  & .imageBoxWrapper {
    display: flex;
  }
  & .imageBox {
    height: 188px;
    max-width: 200px;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.personWrapper {
  width: 360px;
  height: 458px;
  z-index: -1;
  margin-bottom: 48px;
  & img {
    height: 100%;
  }
  @media screen and (max-width: 1440px) {
    margin-bottom: 24px;
  }
  @media (--viewportMobile) {
    height: 344px;
    width: 311px;
    margin-bottom: 10px;
  }
}
.starWrap {
  display: flex;
  @media (--viewportMobile) {
    align-items: flex-end;
  }
  & span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #c4c4c4;
    margin-left: 8px;
  }
}

.bottomImageTopbar {
  display: none;
  @media (--viewportMobile) {
    position: absolute;
    bottom: 0;
    display: block;
    left: 0;
    width: 100%;
    & img {
      width: 100%;
    }
  }
}

.listingLink {
  @media (--viewportMobile) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.imageContainerWrapper {
  margin: 0 22px 90px;
  @media (--viewportMedium) {
    margin: 0 22px;
  }
}

.imageHomeBannerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 270px;
  position: relative;
  @media (--viewportMedium) {
    min-width: 500px;
    height: 500px;
  }
  & img {
    width: 70%;
    height: auto;
    object-fit: cover;
    border-radius: 51px;
    @media (--viewportMedium) {
      min-width: 336px;
    }
  }
  & svg {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @media (--viewportMedium) {
      width: 100%;
      height: 100%;
    }
  }
}

.heroSlider {
}
.heroImageWrapper .slick-list {
  height: 100%;
}
.heroImageWrapper .bannerImages {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.heroImageWrapper {
  height: 768px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerImages {
  position: absolute;
}

.popular {
  position: relative;
}

.sectionSearchWrapper {
  position: absolute;
  top: 200px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (--viewportMobile) {
    top: 150px;
  }
}

.sectionSearchContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1170px;
  width: 100%;
  padding: 0 40px;

  @media (max-width: 1024px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
