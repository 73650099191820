@import '../../../../marketplace.css';

.wrapper {
  margin-bottom: 100px;
  padding: 20px;
  overflow: auto;
}
.weekdayNameRow {
  background-color: var(--marketplaceColorLight);
  color: var(--matterColorLight);
  font-weight: bold;
  & td {
    padding: 0 10px;
  }
}
.table {
  min-width: 768px;
  font-size: 16px;
  padding-right: 20px;
  @media (--viewportMedium) {
    min-width: 1328px;
  }
  @media (--viewportLaptop) {
    padding-right: 0;
  }

  & th {
    padding: 0 10px;
  }

  & .head {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 18px;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--marketplaceColorDark);
    & > .cell {
      font-size: 15px;
      font-weight: bold;
    }
  }
  & .cell {
    margin-left: 1.8%;
  }

  & .nameCol {
    width: 10%;
  }
  & .phoneCol {
    width: 10%;
  }
  & .listingNameCol {
    width: 30%;
  }
}

.row {
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
  &:nth-child(even) {
    background: #f7f9fa;
  }
}
.cell {
  margin: 18px 0 18px 1.8%;
  font-size: 14px;
}
.whatsappIconSection {
  display: flex;
}

.nameContent,
.listingNameContent {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  width: 10%;
}
.phone {
  width: 10%;
}
.emptyPhone{
  padding-left:  1.5rem;
}
.listingName {
  width: 30%;
}

.pagination {
  display: flex;
  justify-content: center;
}
.paginationLink {
  padding: 10px;
  cursor: pointer;
  &.current {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: var(--matterColorDark);
    color: var(--matterColorDark);
  }
}
.iconWhatsapp {
  margin-left: 10px;
  & svg  {
    height: 25px;
    width: 25px;
  }
}
