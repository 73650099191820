@import '../../marketplace.css';

.root {}

input[type=number] {
  -moz-appearance: inherit;
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 25px;
}

.field {
  margin: 0 0 24px 0;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.breakdownWrapper {
  margin: 24px 0px;
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}

.totalPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  padding: 20px 0;
}

.modalTotalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 0px;
}

.orderBreakdownTitle {
  /* Font */
  color: var(--matterColor);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 48px 0 0px;
    padding: 4px 0 4px 0;
  }
}

.infoText {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.locationAddress {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.switch {
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: space-between;
  margin: 15px 0;

  & h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    margin: 0;
  }

  & input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  & label {
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 16px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  & label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 13px;
    height: 12px;
    background: var(--marketplaceColorLightWhite);
    border-radius: 90px;
    transition: 0.3s;
  }

  & input:checked+label {
    background: var(--marketplaceColor);
  }

  & input:checked+label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  & label:active:after {
    width: 30px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.researchActivities {
  position: relative;
}

.mutateBtns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 20px;
  position: absolute;
  top: 0;
  right: 0;

  @media (--viewportMobile) {
    margin-bottom: 24px;
  }

  &>button {
    width: 34px;
    min-height: 34px;
    background: var(--matterColorLight);
    border: 2px solid var(--marketplaceColor);
    color: var(--marketplaceColor);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      background: var(--successColorLight);
    }

    &:disabled {
      background: var(--matterColorLight);
      border: 2px solid var(--matterColorDimmedText);
      color: var(--matterColorDimmedText);
      border-radius: 24px;
    }
  }
}

.activitiesContainer {
  display: flex;
  margin-bottom: 20px;

  & label {
    width: 30px;
  }
}

.sectionTitle {
  background: var(--marketplaceColorBackground);
  color: var(--matterColorLight);
  padding: 10px;

  & svg {
    & path {
      stroke: var(--matterColorLight)
    }

    & circle {
      fill: var(--matterColorLight)
    }
  }
}

.errorMsg {
  color: red;
  margin-top: -10px;
  font-size: 16px;
  line-height: 18px;
}

.dialogHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.boxClass {
  width: 30%;
  height: 80%;

  @media (--viewportLaptopDown) {
    width: 80%;
    height: 80%;
  }

  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
  }
}

::-webkit-scrollbar {
  width: '5px';
}

.headingWrapper {
  font-size: 24px;
  line-height: 36px;
  max-width: 350px;
  text-align: center;

  & strong {
    text-transform: capitalize;
  }
}

.prefillHeadingWrapper {
  font-size: 24px;
  line-height: 36px;
  max-width: 400px;
  margin-bottom: 20px;
  text-align: center;
}

.packageInfoWrapper {
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 5px #e7e7e7;
}

.packageTitleWrapper {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  padding: 0 30px;
}

.packageTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
  margin-top: 15px;
}

.packageDetailsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 30px 35px 30px;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin: 50px 0;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }
}

.summaryName {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    min-width: 50px;
}

.summaryDetails {
    display: flex;
    flex-direction: column;
    gap: 5px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

}

.summaryWrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    padding: 7px 0 1px;
}

.counterValueWrapper {
  border-bottom: 2px solid var(--marketplaceColor);
} 