@import '../../marketplace.css';

* {
  -webkit-overflow-scrolling: touch;
}

:root {
  --promotedListingCardSizeDesktop: 189px;
  --promotedListingCardSizeMobile: 150px;
}

.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  overflow: visible;
}

.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */
  height: 200px;

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
    height: 203px;
  }

  object-fit: cover;
}

.popularImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popularImageHover {
  animation: ZoomIn 2.15s linear infinite;
}

@keyframes ZoomIn {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.3);
  }
}

.sliderImage {
  min-height: 300px;
}

.linkText {
  width: 100%;
}

.popularServices {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  will-change: transform;
  user-select: none;
  display: flex;
  padding-left: 25px;

  @media (--viewportLarge) {
    justify-content: center;
    padding-left: unset;
    overflow: hidden;
  }
}

.promotedListingContainer {
  @media (--viewportMobile) {
    margin: 0 0 0 12px;
  }
}

.promotedListingsWrapper {
  display: flex;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.popular {
  background-color: var(--matterColorLight);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  text-align: center;
  padding-bottom: 0;
  height: fit-content;
  width: 264px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.047);
  font-family: HelveticaNeue, 'Source Sans Pro', sans-serif;
  cursor: pointer;
  border: 1px solid #ccd7ea;
  box-sizing: border-box;

  /* &:hover {
    opacity: 0.8;
  } */
  @media (--viewportMobile) {
    width: 150px;
    margin: 10px 5px;
  }
}

.popular a,
.popular span,
.popular p,
.popular div {
  font-family: HelveticaNeue, 'Source Sans Pro', sans-serif;
}

.title {
  /* @apply --marketplaceH1FontStyles; */
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-bottom: 23px;
    padding-left: unset;
  }
}

.titleHighlight span {
  color: var(--matterColorLightBlue) !important;
}

.mainWrapper {
  flex: 1 1 auto;
  margin: 16px;

  @media (--viewportLarge) {
    height: unset;
  }
}

.avatarWrapper {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-top: 10px;

  @media (--viewportMobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.avatarImg {
  height: 40px;
  flex: 0 0 40px;
  border-radius: 50%;
  margin-right: 10px;

  @media (--viewportMobile) {
    display: none;
  }
}

.headline {
  display: -webkit-box;
  color: var(--matterColorDarkBlue);
  margin-top: 5px;
  margin-bottom: 8px;
  font-size: 14px;
  overflow: hidden;
  width: 125px;
  text-align: left;
  line-height: 18px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  white-space: break-spaces;
  word-wrap: break-word;
  font-weight: bold;

  @media (--viewportLarge) {
    margin-top: 5px;
    width: unset;
    margin: 0;
  }
}

.listingLink {
  text-decoration: none !important;
  font-size: 16px !important;
}

.locationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 38px;
  margin: 0px 0;
}

.locationLine {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  min-height: 19px;
  color: #475e83;
  text-align: left;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.locationContainer img {
  margin-right: 6px;
}

.description {
  padding: 15px 0;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #475e83;
  overflow: hidden;
  width: 100%;
  max-height: 83px;
  min-height: 83px;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.favourite {
  margin-top: 10px;
  font-size: 12px;
  text-align: right;
  color: #30c7be;

  @media (--viewportMobile) {
    display: none;
  }
}

.favourite a {
  display: flex;
  justify-content: flex-end;
  color: #30c7be;
  font-size: 16px;
  font-weight: bold;
}

.favoriteIcon {
  &>svg:nth-child(1) {
    width: 20px;
    stroke-width: 10px;
  }

  &>svg:nth-child(2) {
    width: 9px;
    left: 18px;
    top: -4px;
  }
}

.name {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0d1624;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}

.star_Wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 10px;
}

.starContent {
  display: flex;
  align-items: center;
}

.rating svg {
  margin-top: -4px;
}

.rating svg path {
  fill: #fff;
  stroke: #346bf6;
  stroke-width: 4px;
  height: 24px;
}

.filledRating svg path {
  fill: #346bf6 !important;
}

.rating {
  font-size: 16px;
  padding: 0px 8px;
  font-weight: 500;
  background: rgba(52, 107, 246, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #346bf6;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}

.reviewCountWrapper {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #346bf6;
  margin-left: 10px;
}

.proVerified {
  font-size: 10px;
  font-weight: 550;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}

.reviewCount {
  font-size: 16px;
  margin-left: 5px;
  color: #346bf6;
}

.startingAt span {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 24px;

  @media (--viewportLarge) {
    font-size: 16px;
  }
}

.startingAt {
  font-size: 12px;
  text-align: left;
  color: var(--matterColorLight);
  background: #233045;
  border: 2px solid #ffffff;
  border-radius: 34px;
  position: absolute;
  top: 180px;
  right: 10px;
  width: 135px;
  display: flex;
  height: 45px;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
}

.showAll {
  font-size: 15px;
  color: var(--marketplaceColor);
}

.subTitle span {
  font-size: 16px !important;
  color: var(--matterColorDarkBlue);
  font-weight: bold;
}

.header {
  margin: 15px 25px 25px;
}

.newheader {
  margin: 15px 25px 25px;
  padding: 78px 0 36px;

  @media (--viewportMobile) {
    padding: 50px 0 0 0;
    margin: 0;
  }
}

.title span {
  color: var(--matterColorDarkBlue);
  font-size: 24px;

  @media (--viewportMobile) {
    font-size: 22px;
  }
}

.title {
  @media (--viewportMedium) {
    margin: 0px !important;
  }
}

.subTitle {
  margin: 0px 25px 20px 25px !important;
}

.buttonWrap {
  margin: 30px 24px 0;
}

.levelContainer div {
  padding: 0px;
}

.levelContainer {
  margin-left: auto;

  @media (--viewportMobile) {
    margin: unset;
  }
}

.iconWhatsapp {
  text-align: right;

  & svg {
    width: 25px;
    height: 25px;
  }
}

.descriptionContainer {
  display: flex;
}

.PromotedListingLength {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: var(--matterColorDimmedText);
}

.whatsappSection .btnView {
  background: #00ab99;
  border-radius: 5px;
  font-weight: 700;
  height: 50px;
  font-size: 18px;
  line-height: 25px;
  font-style: italic;
  color: #ffffff;
  width: 100%;
  border: none;
}

.whatsappSection {
  display: flex;
  column-gap: 8px;
  margin: 11px 0 0 0;

  @media (--viewportMobile) {
    width: 100%;
  }

  & .iconWhatsapp {
    width: 21px;
    height: 21px;
  }
}

.listingCards {
  padding: 0 24px 96px 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  @media (--viewportMobile) {
    padding: 0px 24px;
    justify-content: space-between;
  }

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px 5px;
  }

  @media (--viewportLarge) {
    padding: 0;
  }
}

.listingCard {
  margin-bottom: 20px;
  flex-basis: 100%;
  min-height: 800px;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(50% - 12px);
    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.viewButtonClass {
  background: var(--marketplaceColor);
  border-radius: 4.57592px;
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--matterColorLight);
  width: 185px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.authorNameClass {
  font-weight: 700;
  font-size: 16.5px;
  line-height: 20px;
  color: #0d1624;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.startingAtClass {
  font-style: normal;
  font-weight: 400;
  font-size: 9.92653px;
  line-height: 13px;
  text-transform: capitalize;
  color: var(--matterColorLight);
  border: 1px solid var(--matterColorLight);
  border-radius: 34px;
  position: absolute;
  top: 7.8rem;
  right: 0;
  width: 105px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColorDarkest);
}

.startingAtClass span {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--matterColorLight);
  padding-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cardContainerClass {
  background-color: var(--matterColorLight);
  display: flex;
  /* flex-shrink: 0; */
  flex-direction: column;
  padding-bottom: 0;
  height: 423px;
  width: 218px;
  position: relative;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.047);
  font-family: 'HelveticaNeue', 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  border: 0.826475px solid #ccd7ea;

  @media (--viewportMobile) {
    width: var(--cardSizeMobile);
    margin: 10px auto;
  }

  @media (min-width: 360px) and (max-width: 400px) {
    width: 145px;
  }

  @media (min-width: 401px) and (max-width: 460px) {
    width: 170px;
  }
}

.imageWrapperClass {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.addressWrapperClass {
  display: flex;
  column-gap: 4px;
  align-items: center;
  padding: 4px 0px;
  margin-top: 7px;
}

.reviewsWrapperClass {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2px;
  margin-top: 11px;
}

.descriptionClass {
  padding-top: 4px;
  height: 34px;
  min-height: 55px;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 11.5px;
  line-height: 18px;
  color: #475e83;
  margin-top: 10px;
}

.buttonWrapperClass {
  margin-top: 0.5rem;
  padding: 8px 0px;
}