@import '../../../marketplace.css';

.hostedByProsVideoSection {
  max-width: 1200px;
  margin: 77px auto 0;
}

.hostedByProsWrap {
  display: flex;
  position: relative;
  z-index: 10;
  padding-bottom: 53px;
  column-gap: 45px;
  border-bottom: 1px solid #e5e5e5;
  @media (--viewportMobile) {
    flex-direction: column;
    padding: 33px 0 12px;
    border: 0;
  }
}

.hostedProsHeadingWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.hostedByProsTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: var(--matterColorDark);
  margin: 0;
}

.hostedByProsSlogan {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--matterColorDark);
  @media (--viewportMobile) {
    padding: 62px 0 15px;
  }
}

.videoWrapper {
  & img {
    height: 300px;
    min-width: 23rem;
    width: 100%;
    object-fit: cover;
    border-radius: 1.5rem;
  }
}

.videoWrapper video {
  border-radius: 28px;
  max-width: 100%;
  height: 100%;
  object-fit: inherit;
}

.form {
  margin-top: 10px;
  padding: 1rem;
  max-width: 28rem;
  height: auto;
  /* border: 1px solid #6e6f71; */
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 3z0px 10px rgb(0 0 0 / 10%);
  background-color: var(--matterColorLight);

  /* margin: 0 120px; */
  @media (--viewportMobile) {
    max-width: 100%;
    width: 100%;
    height: auto;
    padding: 36px 26px 32px;
  }

  & h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    max-width: 368px;
    text-transform: capitalize;
    color: #233045;
    margin: 0;

    @media (--viewportMobile) {
      font-size: 30px;
      line-height: 48px;
    }
  }

  & h2 {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: var(--matterColorDark);
    margin: 14px 0 2px 0;

    @media (--viewportMobile) {
      font-size: 20px;
    }
  }

  & .field {
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 8px;
    padding: 14px 16px;
    border-bottom: 1px solid #0d1624;
    align-items: center;

    @media (--viewportMobile) {
      width: 100%;
      height: 68px;
    }

    &:last-of-type {
      margin-bottom: 24px;
    }

    & > div {
      width: 100%;
    }

    & input {
      flex: 1 0;
      padding-left: 10px;
      border-bottom: none;
      height: auto;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: #0d1624;

      &:placeholder {
        color: #b2aeab;
      }

      @media (--viewportMobile) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    & .practiceIcon {
      width: 23px;
      height: 28px;
    }
  }

  & .selectField {
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 13px;
    /* padding: 16px; */
    background: var(--matterColorLight);
    border-bottom: 1px solid #0d1624;
    align-items: center;
    padding: 12px 16px;

    @media (--viewportMobile) {
      width: 100%;
      height: 68px;
    }

    &:last-of-type {
      margin-bottom: 16px;
    }

    & > div {
      width: 100%;
    }

    & select {
      flex: 1 0;
      padding: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: #0d1624 !important;
      text-transform: capitalize;
      padding-left: 13px;
      padding-right: 16px;
      border-bottom: none;

      /* height: 18px; */
      @media (--viewportMobile) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    & .practiceIcon {
      width: 24px;
      height: 24px;
    }
  }

  & .location {
    display: flex;
    align-items: center;
  }

  & .locationIcon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
  }

  & .submit {
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    height: 54px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    right: 5px;
    outline: none;
    padding: 14px 20px;
    transition: box-shadow 0.2s ease, transform 0.1s ease;
    background: #00ab99;
    color: #fff;
    white-space: nowrap;
    margin: 5px 3px;

    @media (--viewportMobile) {
      width: 100%;
      font-size: 22px;
      font-weight: bold;
      line-height: 30px;
      height: 68px;
    }
  }
}

.arrowIcon {
  width: 12px;
  height: 5px;
}
.searchIcon {
  margin-right: 10px;
}


/* Search Box Styling */
.searchSection {
  position: relative;
}