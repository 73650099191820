@import '../../marketplace.css';

.icon_link {
  display: flex;
  width: 100%;
  flex: 1 0 25%;
  cursor: pointer;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 12px;
  margin-bottom: 4px;
  text-decoration: none !important;
  & svg {
    width: 8px;
    height: 12px;
  }
  @media (--viewportTabletDown) {
    padding: 5px;
    margin-bottom: 0;
  }
}

.imageSize {
  width: 100%;
  max-height: 482px;
  border-radius: 0px 0px 5px 5px;
  @media (--viewportMobile) {
    width: 100%;
    height: 278px;
  }
}
.imageSize img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 9px 9px 0 0;
}

.serviceItem {
  background: var(--matterColorLight);
  margin-bottom: 34px;
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  max-width: 535px;
  height: 100%;

  border-radius: 9px 9px 40px 40px;
  @media (--viewportMedium) {
    filter: drop-shadow(0px 167.612px 134.09px rgba(0, 0, 0, 0.05));
  }
  @media (--viewportTabletDown) {
    margin: 0 auto 34px;
    min-width: 301px;
    background: var(--matterColorLight);
    border: 0.811286px solid #e2e2e2;
    box-sizing: border-box;
    box-shadow: 0px 8.11286px 10.8171px rgba(0, 0, 0, 0.1);
  }
}

.linkSection {
  padding: 12px 18px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  @media (--viewportTabletDown) {
    min-height: 145px;
    padding: 12px;
  }
  & a {
    font-weight: 600;
    font-size: 13.3542px;
    line-height: 70px;
    color: rgba(110, 111, 113, 0.46);
    mix-blend-mode: normal;
  }
}

.serviceTitleSection {
  width: 100%;
  height: 54px;
  background: rgba(35, 48, 69, 0.6);
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  & h3 {
    font-size: 13px;
    color: var(--matterColorNegative);
    margin: 0;
  }
  & h2 {
    margin: 0;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    margin-left: 30px;
    color: var(--matterColorLight);
  }
}

.imageWrapper {
  position: relative;
}

.searchAllButtom {
  width: 89px;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  color: var(--marketplaceColor);
  border: none;
  margin: 0 32px 18px 0;
  & span {
    margin-left: 8px;
    display: flex;
  }
  & svg {
    width: 9px;
    height: 13px;
  }
  &:hover {
    text-decoration: none;
  }
}

.searchAllButtomWrapper {
  display: flex;
  justify-content: flex-end;
}

.linkLabel {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #999ca0;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;

  order: 0;
  margin: 0px 9px;
  max-width: 120px;
  min-width: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: auto;
  @media (--viewportMobile) {
    margin: 0;
    min-width: 100%;
  }
}
