@import '../../marketplace.css';

.root {}

.summaryWrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    padding: 7px 0 1px;
}

.summaryName {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    min-width: 50px;
}

.summaryDetails {
    display: flex;
    flex-direction: column;
    gap: 5px 0;

    & span {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }
}

.summaryTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
}

.packageTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 20px 0 0;
    text-transform: capitalize;
}

.packageWrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    padding: 0 22px;
}

.packageAuthor {
    width: 100px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

    & h4 {
        margin: 0 0 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: var(--matterColorAnti);
    }
}

.packageDetails {
    display: flex;
    flex-direction: column;
    gap: 5px 0;

    & span {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }
}

.totalDivider {
    /* dimensions */
    width: 100%;
    height: 1px;
    margin: 12px 0 5px 0;

    border: none;
    background-color: var(--matterColorNegative);

    @media (--viewportMedium) {
        margin: 7px 0 0px 0;
    }
}