@import '../../marketplace.css';

.staticPageWrapper {
  /* width: calc(100% - 48px);
  max-width: 1056px; */
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
  @media (--viewportMobile) {
    padding:  0 18px;
  }
}

.listingTypeContainer {
  display: flex;
  max-width: 1660px;
  margin: 0 auto;
  /* flex-wrap: wrap; */
  @media (--viewportLaptopDown) {
    flex-direction: column;
  }
  @media (max-width: 1660px) {
    max-width: 100%;
  }
}

.heading {
  text-align: center;
  color: var(--marketplaceColorDarker);
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 85px;
  @media (--viewportMobile) {
    font-size: 40px;
    line-height: 55px;
  }
}

.subHeading {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  color: var(--matterColorDimmedText);
  & span {
    display: block;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.sunHeading2 {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 50px;
  text-align: center;
  color: var(--marketplaceColorDarker);
  margin-top: 51px;
  margin-bottom: 39px;
  @media (--viewportMobile) {
    font-size: 25px;
  }
}

.listingType {
  flex-basis: 46%;
  background: var(--matterColorLight);
  /* margin: 0 2% 4%; */
  margin: 0 36px 0 0;
  padding: 30px;
  flex-shrink: 1;
  box-shadow: 0px 25px 47px 3px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  color: var(--matterColorDark);
  font-size: 14px;
  & a:hover {
    text-decoration: none;
  }  
  & .icon {
    /* margin-bottom: 15px; */
    width: 70px;
    margin: 0 auto 39px;
    & svg {
      width: 60px;
      height: 60px;
      fill: var(--marketplaceColorDark);
    }
  }
  & .title {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    color: var(--marketplaceColorDarker);
  }
  @media (--viewportLaptopDown) {
    flex-basis: 100%;
    margin: 0 0 18px 0;
  }
  & .description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--matterColorDimmedText);
    min-height: 120px;
  }
}

.listingType:last-child{
  margin: 0 !important;
}
.LinkStyle{
  font-weight: 600;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: var(--matterColorLightBlue);
}

.linkContainer{
  height: 56px;
  width: 104px;
  background: #F8F8F8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 20px 0 0 0;
}

.linkSection{
  display: flex;
  justify-content: center;
}

.LayoutSingleColumnRoot{
  background:  var(--matterColorLightGrey);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}