@import '../../marketplace.css';

.formcontainer {
  max-width: 641px;

  @media (--viewportMobile) {
    max-width: 100%;
  }

  & h1 {
    font-weight: bold;
    font-size: 45px;
    line-height: 65px;
    color: #272727;
    margin: 0;
  }

  & p {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: var(--matterColorDark);
    width: 374px;
    height: auto;

    @media (--viewportMobile) {
      width: 100%;
    }
  }
}

.container {
  max-width: 1175px;
  padding: 0 24px;
  margin: 0 auto;
}

.subContainer {
  display: flex;

  @media (--viewportMobile) {
    margin-top: 20px;
    flex-direction: column-reverse;
  }
}

.hero {
  padding: 51px 0 55px;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;

  @media (--viewportMobile) {
    background-size: 100% 250px;
    padding-bottom: 0;
    padding: 22px 24px 60px;
  }

  & :global(.Button_primaryButtonRoot__OOu0n) {
    min-height: 45px;
  }

  & .heroTitle {
    max-width: 1175px;
    padding: 0 24px;
    margin: 0 auto;
    font-weight: bold;
    font-size: 45px;
    line-height: 65px;
    color: var(--marketplaceColorDarkest);
    text-transform: capitalize;

    @media (--viewportMobile) {
      font-size: 30px;
      line-height: 65px;
      max-width: 100%;
      margin: 35px 0 0 0;
    }
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 30px 0;
  height: 100%;
  position: relative;

  @media (--viewportMobile) {
    padding: 2px 0 46px 0;
  }

  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.error {
  color: var(--failColor);
}

.searchListingsPanel {
  flex-grow: 1;

  & nav {
    background: var(--matterColorLightGrey);
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.mainPanelHeader {
  display: flex;
  flex-direction: column;
  /* margin: 0 52px; */
  padding: 0 0 0 80px;

  @media (--viewportMobile) {
    padding: 20px 24px;
    margin-top: 20px;
  }
}

.mobileResultHead {
  padding: 22px 11px 8px;
  display: none;

  @media (--viewportMobile) {
    display: block;
  }
}

.modeHeadingWrap {
  display: flex;
  column-gap: 35px;
}

.modeItems {
  display: flex;
  column-gap: 11px;

  & .modeItem {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    color: #6e6f71;
    box-sizing: border-box;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    & span{
      padding-left: 7px;
    }
    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -10px;
      background-color: #B2AEAB;
    }
  }
  
  & .selectedModeItem {
    color: var(--marketplaceColor);
    & svg path{
      fill: var(--marketplaceColor);
    }
    &::after{
      background-color: var(--marketplaceColor);
    }
  }
}

.classCalendarContainer {
  max-width: 100%;

  @media (--viewportMobile) {
    margin: 30px 22px 24px;
    padding-left: 0;
  }
}

.form {
  padding: 35px 52px 35px 0;
  max-width: 448px;
  height: auto;
  background-color: var(--matterColorLight);
  /* margin: 0 120px; */
  @media (--viewportMobile) {
    max-width: 100%;
    width: 100%;
    height: auto;
    padding: 36px 26px 32px;
  }

  & h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    max-width: 280px;
    text-transform: capitalize;
    color: #233045;
    margin: 0;

    @media (--viewportMobile) {
      font-size: 30px;
      line-height: 48px;
    }
  }

  & h2 {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: var(--matterColorDark);
    margin: 14px 0 2px 0;

    @media (--viewportMobile) {
      font-size: 20px;
    }
  }

  & .field {
    display: flex;
    width: 327px;
    height: 50px;
    margin-bottom: 8px;
    padding: 14px 16px;
    background: #F3F3F3;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    align-items: center;

    @media (--viewportMobile) {
      width: 100%;
      height: 68px;
    }

    &:last-of-type {
      margin-bottom: 36px;
    }

    &>div {
      width: 100%;
    }

    & input {
      flex: 1 0;
      padding-left: 10px;
      border-bottom: none;
      height: auto;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      text-transform: capitalize;
      color: var(--matterColorDark);

      &:placeholder {
        color: #aaaaaa;
      }

      @media (--viewportMobile) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    & .practiceIcon {
      width: 24px;
    }
  }

  & .selectField {
    display: flex;
    width: 327px;
    height: 50px;
    margin-bottom: 13px;
    /* padding: 16px; */
    background: var(--matterColorLight);
    border: 1px solid #eaeaea;
    border-radius: 8px;
    align-items: center;
    padding: 12px 16px;

    @media (--viewportMobile) {
      width: 100%;
      height: 68px;
    }

    &:last-of-type {
      margin-bottom: 16px;
    }

    &>div {
      width: 100%;
    }

    & select {
      flex: 1 0;
      padding: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      text-transform: capitalize;
      color: #000000;
      padding-left: 10px;
      padding-right: 16px;
      border-bottom: none;

      /* height: 18px; */
      @media (--viewportMobile) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    & .practiceIcon {
      width: 24px;
    }
  }

  & .location {}

  & .locationIcon {
    width: 24px;
    display: flex;
    align-items: center;

    & svg {
      transform: scaleX(-1);
      display: block;
      margin: 0 auto;
      
    }
  }

  & .submit {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    color: var(--matterColorLight);
    border-radius: 8px;
    width: 327px;
    height: 50px;
    background-color: var(--marketplaceColorLight);

    @media (--viewportMobile) {
      width: 100%;
      font-size: 22px;
      font-weight: bold;
      line-height: 30px;
      height: 68px;
    }
  }
}

.formTitle {
  justify-content: center;
  display: flex;

  >h1 {
    font-size: 34px;
  }
}

.inlineForm {
  padding: 40px;
  width: 100%;
  background-color: var(--matterColorLight);
  border: 1px solid #ebebeb;
  border-radius: 4px;

  & h1 {
    font-size: 34px;
    line-height: 30px;
    text-align: center;
  }

  & h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  & .field {
    width: 378px;
    display: flex;
    margin: 0 12px 8px;
    padding: 16px;
    background: var(--matterColorLight);
    border: 1px solid #eaeaea;
    border-radius: 8px;

    @media (--viewportMobile) {
      width: 300px;
      margin: 10px 0;
    }

    &>div {
      width: 100%;
    }

    & input {
      flex: 1 0;
      padding-left: 10px;
      border-bottom: none;
      height: 18px;
      font-size: 16px;
      margin-right: 12px;
    }

    & .practiceIcon {
      width: 24px;
    }
  }

  & .selectField {
    width: 378px;
    display: flex;
    margin: 0 12px 8px;
    /* padding: 16px; */
    background: var(--matterColorLight);
    border: 1px solid #eaeaea;
    border-radius: 8px;
    align-items: center;
    padding: 12px 16px;

    @media (--viewportMobile) {
      width: 300px;
      margin: 10px 0;
    }

    &>div {
      width: 100%;
    }

    & select {
      flex: 1 0;
      padding: 0;
      padding-left: 10px;
      padding-right: 16px;
      border-bottom: none;
      /* height: 18px; */
      font-size: 16px;
    }

    & .practiceIcon {
      width: 24px;
    }
  }

  & .location {}

  & .locationIcon {
    width: 24px;

    & svg {
      display: block;
      margin: 0 auto;
    }
  }

  & .submit {
    background-color: var(--sectionHeroBg);
  }

  & button {
    display: block;
    height: 50px;
    width: 200px !important;
    min-height: 0px;
    margin-left: 12px;
    padding: 0px 0 0 0;
    border: none;
    border-radius: 8px;
    background-color: var(--sectionHeroBg);
    color: var(--marketplaceColorLightWhite);
    text-align: center;
    text-decoration: none;
    transition: all ease-in-out 0.1s;
    cursor: pointer;
  }

  @media (--viewportMobile) {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    & button {
      margin: 10px 0;
      min-height: 0px;
      min-width: 100%;
    }
  }
}

.howItWorks {
  padding: 80px 0;

  @media (--viewportMobile) {
    padding: 0;
  }

  & h1 {
    font-size: 34px;
    line-height: 43px;
    text-align: center;
    margin-top: 0;
    font-weight: bold;
    margin-bottom: 16px;
  }

  & h2 {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    text-align: center;
  }

  & .howItWorksWrap {
    display: flex;
    justify-content: center;

    @media (--viewportMobile) {
      display: block;
    }
  }

  & .howItWorksItem {
    flex: 0 1 270px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      text-align: center;
      color: #8a8d91;
    }
  }
}

.inlineFormElenment {
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (--viewportMobile) {
    flex-direction: column;
  }
}

.PromoPage_form__2eyme {
  width: 100% !important;
}

.inlineFormElenment input {
  width: 378px;
  height: 48px;
}

.reviewContainer {
  height: 524px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #bdddde;

  @media (--viewportMobile) {
    height: auto;
    padding: 30px 0;
  }
}

.reviewContent {
  height: 232px;
  background: var(--matterColorLight);
  border-radius: 5px;
  width: 75%;
  padding: 32px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (--viewportMobile) {
    height: auto;
    padding: 16px;
  }
}

.reviewContent::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 18px solid var(--matterColorLight);
  bottom: -7%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;

  @media (--viewportMobile) {
    bottom: -3%;
  }
}

.reviewProfile {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 32px;
}

.reviewAvatar {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {}
}

.quoteIcon {
  font-size: 50px;
  top: 20%;
  left: 30px;
  position: absolute;

  @media (--viewportMobile) {
    top: 5%;
  }
}

.draggableWrapper {
  width: 100%;
}

.reviewText {
  margin: 0 0 0 48px;
  font-size: 18px;
  line-height: 32px;
  font-family: 'Source Sans Pro';
  font-weight: normal;

  @media (--viewportMobile) {
    font-size: 16px;
    margin: 30px 0 0 10px;
  }
}

.reviewImage {
  width: 56px;
  height: 56px;
}

.reviewStar {
  display: grid;
}

.mapPanel {
  position: sticky;
  top: 114px;
  right: 0;
  height: 100vh;

  @media (--viewportMobile) {
    position: relative;
    display: flex;
    height: 100%;
    margin-bottom: 20px;
    top: 0;
  }

  & :global(#search-map) {
    @media (--viewportMobile) {
      height: 336px;
    }
  }
}

.reviewProfileSection {
  display: flex;
  width: 75%;
  justify-content: space-evenly;

  @media (--viewportMobile) {
    width: 100%;
    /* justify-content: space-between; */
  }
}

.reviewName {
  width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  line-height: 20px;
}

.listingTypeContainer {
  margin-bottom: 30px;
  padding: 0 24px;

  @media (--viewportMobile) {
    & :global(.ListingTypeFilter_checkboxLabel__1tPEm) {
      padding: 10px 7px;
      font-size: 14px;
    }
  }
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.noRecordFound {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ReviewSection {
  padding: 65px 0 53px 0;
  background-color: var(--marketplaceColorLightWhite);

  @media (--viewportMobile) {
    padding: 65px 20px 53px 20px;
  }

  & h2 {
    margin: 0;
  }
}

.mainReviewContainer {
  max-width: 1524px;
  margin: 0 auto;
  position: relative;

  & h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 65px;
    color: var(--marketplaceColorDarkerI);

    @media (--viewportMobile) {
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      padding: 15px 0;
    }
  }
}

.totalStays {
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-transform: capitalize;
  color: var(--matterColorDimmedText);
  margin-bottom: 12px;
}

.staysLocation {
  margin: 0 0 0;
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
  text-transform: capitalize;
  color: var(--matterColorDarkBlue);
  position: relative;

  & span {
    background: #fff;
    z-index: 1;
    width: fit-content;
  }

  @media (--viewportMobile) {
    font-size: 26px;
  }
}

.staysLocation::after {
  content: '';
  height: 1.5px;
  width: 100%;
  background: var(--matterColorNegative);
  /* top: 50%; */
  bottom: 6px;
  left: 0;
  position: absolute;
  z-index: -1;
  /* @media (--viewportMobile){
    display: none;
  } */
}

.subHeading {
  margin: -10px 0 20px 0 !important;
  position: relative;
  z-index: 1;
  line-height: 0;

  & span {
    padding: 0 12px;
    margin: 16px 0 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    color: var(--matterColorAnti);
    background: var(--matterColorLight);
  }

  &::after {
    content: '';
    height: 2px;
    width: 100%;
    background: var(--lightBackground);
    top: 60%;
    left: 0;
    position: absolute;
    z-index: -1;
  }
}

.hireBestCoachContainer {
  max-width: 1175px;
  padding: 46px 24px 0;
  margin: 0 auto 197px auto;

  @media (--viewportMobile) {
    margin: 0 auto 15px auto;
  }

  @media (--viewportMobile) {
    display: block;
  }

  & h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 75px;
    text-transform: capitalize;
    color: var(--marketplaceColor);
    margin: 14px 0 0 0;
    display: flex;
    align-items: center;

    @media (--viewportMobile) {
      margin: 0;
    }
  }

  & .bestCoachInfo {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: var(--matterColorDimmedText);
  }
}

.hireBestCoachContainerStep3 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  /* align-items: center; */
  margin: 0 0 114px;

  @media (--viewportMobile) {
    flex-direction: column;
  }

  & h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 75px;
    text-transform: capitalize;
    color: var(--marketplaceColor);
    margin: 14px 0 0 0;

    @media (--viewportMobile) {
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      margin: 0;
    }
  }

  & span {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: var(--matterColorDimmedText);

    @media (--viewportMobile) {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      display: flex;
    }
  }

  & img {
    width: 527px;

    @media (--viewportMobile) {
      width: 100%;
      max-width: 328px;
    }
  }
}

.hireBestCoachContainerReverse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 64px 0 109px 0;

  @media (--viewportMobile) {
    flex-direction: column;
    margin: 120px 0 95px 0;
  }

  & h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 75px;
    text-transform: capitalize;
    color: var(--marketplaceColorLight);
    margin: 0;

    @media (--viewportMobile) {
      font-size: 25px;
      line-height: 31px;
      text-align: center;
    }
  }

  & span {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: var(--matterColorDimmedText);

    @media (--viewportMobile) {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      display: flex;
    }
  }

  & img {
    width: 527px;

    @media (--viewportMobile) {
      width: 242px;
    }
  }
}

.hireBestCoachTitle {
  font-weight: bold;
  font-size: 40px;
  line-height: 57px;
  text-transform: capitalize;
  color: var(--matterColorLight);
  margin-top: 0;

  @media (--viewportMobile) {
    font-size: 40px;
    line-height: 60px;
  }
}

.hireBestCoachDetail {
  max-width: 468px;

  @media (--viewportMobile) {
    max-width: 100%;
    margin-bottom: 29px;
  }
}

.sectionCoaches {
  background: rgba(248, 248, 248, 0.75);
  display: flex;
  align-items: center;
  height: 422px;

  @media (--viewportMobile) {
    height: auto;
    justify-content: center;
  }

  & h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 85px;
    text-transform: capitalize;
    color: var(--marketplaceColorDarkerI);
    margin: 0;

    @media (--viewportMobile) {
      font-size: 45px;
      line-height: 75px;
      margin: 3px 0 0;
    }
  }

  & button {
    width: 344px;
    height: 67px;
    font-weight: 600;
    font-size: 23px;
    line-height: 29px;
    text-transform: capitalize;
    color: var(--matterColorLight);
    background: var(--marketplaceColor) !important;
    border-radius: 50px;
    margin-bottom: 16px;

    @media (--viewportMobile) {
      width: 100%;
      height: 58px;
    }
  }

  & p {
    font-weight: 400;
    font-size: 20px;
    line-height: 39px;
    text-transform: capitalize;
    color: var(--matterColorDark);
    margin: 13px 0 0 0;

    @media (--viewportMobile) {
      width: 339px;
      font-size: 18px;
      line-height: 30px;
      margin: 36px 0 0 0;
    }
  }
}

.sectionCoachescontainer {
  max-width: 1175px;
  margin: 0 auto;
  padding: 0 24px;

  @media (--viewportMobile) {
    max-width: 100%;
    margin: 0 24px;
    padding: 39px 4px 200px 4px;
  }
}

.sectionReviews {
  background-color: var(--marketplaceColorLightWhite);
}

.headCategory {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  color: #aaaaaa;

  @media (--viewportMobile) {
    font-size: 22px;
  }

  & span {
    color: var(--marketplaceColorLight);
  }
}

.reverseCoachHeading {
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    display: block;
  }
}

.topHireBestCoachCount {
  position: relative;
  height: 44px;
  width: 44px;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  color: var(--matterColorLight);
  margin: 4px 24px 0 0;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMobile) {
    height: 33px;
    width: 33px;
    margin: 0;
    font-size: 18px;
    line-height: 27px;
  }
}

.HireBestCoachCount {
  position: relative;
  height: 44px;
  min-width: 44px;
  width: 44px;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  color: var(--matterColorLight);
  margin: 14px 29px 0 0;
  background: var(--marketplaceColorBackground);
  border-radius: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMobile) {
    height: 33px;
    min-width: 33px;
    width: 33px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 27px;
  }
}

.sectionMapContainer {
  position: relative;
}

.hireBestCoachSectionContainer {
  max-width: 1175px;
  padding: 0 24px;
  margin: 46px auto 130px auto;

  @media (--viewportMobile) {
    margin: 46px auto 15px auto;
  }
}

.hireBestCoachSection {
  width: 100%;

  /* margin: 120px auto 0 auto; */
  @media (--viewportMobile) {
    width: auto;
    margin: 40px 24px 0 24px;
  }
}

.heroContainer {
  display: flex;
  max-width: 1175px;
  padding: 0 24px;
  margin: 0 auto;
  column-gap: 55px;
  justify-content: space-between;
  align-items: center;
}

.channelContainer {
  max-width: 1154px;
  margin: 0 auto 19px;

  @media (--viewportMobile) {
    max-width: 100%;
    padding: 0 24px;
    margin: 0;
  }

  & h1 {
    font-weight: bold;
    font-size: 35px;
    line-height: 44px;
    color: #272727;
    margin: 41px 0 5px;

    @media (--viewportMobile) {
      font-size: 30px;
      line-height: 44px;
    }
  }
}

.channelContainer :global(.yottie-widget-header-inner) {
  display: none;
}

.channelContainer :global(.swiper-wrapper) {
  & div {
    padding-left: 0 !important;
  }
}

.imageContainer {
  max-width: 537px;
  width: 100%;
  height: 356px;
  position: relative;
  & img { 
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 51px;

    @media (--viewportLarge) {
      height: 100%;
    }
  }
  @media (--viewportMobile) {
    display: none;
  }
  & svg {
    position: absolute;
    left: -50px;
    top: -12px;
    z-index: -1;
  }
}

.hireBestStep1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 813px;
  margin: 0 auto;

  & h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 75px;
    text-transform: capitalize;
    color: var(--marketplaceColorLight);
    display: flex;
    align-items: center;

    @media (--viewportMobile) {
      flex-direction: column;
      margin: 0;
      font-size: 40px;
      line-height: 75px;
      font-size: 25px;
      line-height: 31px;
    }
  }

  & .coachCountInfo {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--matterColorLight);
    margin-bottom: 42px;
  }
}

.searchEnquiryHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 28px;
  max-width: 734px;

  @media (--viewportMobile) {
    display: none;
  }

  & img {
    position: absolute;
    top: -20px;
    right: -50px;
  }

  & h2 {
    font-weight: 900;
    font-size: 29px;
    line-height: 52px;
    color: var(--matterColorDarkBlue);
    margin: 0;
  }

  & h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: var(--matterColorDimmedText);
    margin: 0;
  }
}

.searchEnquiryTitle {
  position: relative;
}

.topHireBestCoachContainer {
  height: 566px;
  background: #243144;

  @media (--viewportMobile) {
    padding: 0 24px;
  }
}

.svgFormContainer {
  width: 611px;
  height: auto;
  margin: 0 auto;
  position: relative;
  background-color: var(--matterColorLight);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 46px;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.startRequestForm {
  background: var(--matterColorLight);
  border: 0.690177px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 15.1203px 25.2005px 5.04011px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 29px 31px 20px;
  display: none;

  @media (--viewportMobile) {
    display: block;
  }

  & .startRequestFormTitle {
    font-weight: 900;
    font-size: 25px;
    line-height: 32px;
    color: #233045;
    margin: 0;
  }

  & .startRequestFormDescription {
    font-weight: 600;
    font-size: 12.4654px;
    line-height: 16px;
    text-transform: capitalize;
    color: var(--matterColorDarkBlue);
  }

  & .startRequestFormButton {
    background: var(--marketplaceColor);
    width: 100%;
    min-height: 36px;
    border-radius: 5.31956px;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: var(--matterColorLight);
    margin-top: 15px;
  }
}

.desktopFilterTypeWrapper {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mobileFilterTypeElement {
  @media (--viewportMedium) {
    display: none;
  }
}

.filterTypeButtonWrapper {
  display: flex;
  gap: 0 11px;
  padding: 28px 15px 28px 70px;
  background: var(--matterColorLight);

  @media (--viewportMobile) {
    gap: 0 7px;
    width: 100%;
    padding: 0px 19px 21px 17px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .filterTypeButton {
    background: var(--matterColorLight);
    border: 1.5px solid #f1f1f1;
    padding: 13px 22px;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: var(--marketplaceColorDarkest);

    @media (--viewportMobile) {
      font-size: 13px;
      line-height: 20px;
      white-space: nowrap;
      padding: 10px 18px 12px;
    }
  }

  & .selectedFilterTypeButton {
    background: #e7fbff;
    color: var(--marketplaceColor);
  }
}

.filterKeywordWrap {
  justify-content: left;
  align-items: center;
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }

  @media (--viewportLarge) {
    margin-left: 77px;
  }
}

.filterWrap {
  width: 375px;
  height: 46px;
  padding: 0 6px 0 16px;
  background: var(--matterColorLight);
  border-radius: 28px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;

  @media (--viewportMobile) {
    width: 100%;
    border-radius: 28px;
    margin-bottom: 18px;
  }
}

.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;

  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.filterWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
}

.filterKeywordWrap input {
  width: 214px;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);

  @media (--viewportMobile) {
    width: 330px;
  }
}

.btnapply {
  width: 85px;
  min-width: 63px;
  border-radius: 16.5601px;
  min-height: 33px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  margin-left: 14px;
  color: var(--matterColorLight);
  background: var(--marketplaceColor);

  @media (--viewportMobile) {
    display: none;
  }
}

.postRequestGifContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 316px; */
  margin: 0 auto;
  padding: 50px 110px 30px;

  @media (--viewportMobile) {
    padding: 50px 70px 10px;
  }
}

.postRequestGif {
  width: 80%;
  height: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 23px;
  z-index: 2;

  @media (--viewportMobile) {
    width: 100%;
    height: auto;
  }
}

.imagePostRequestGifBackground {
  /* width: 100%; */
  position: absolute;

  @media (--viewportMobile) {
    /* max-width: 392px;   
    max-height: 241px;     */
    width: 100%;
    padding: 24px;
    height: auto;
  }
}

.connectPros {
  background: #f1f1f1;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 35px 26px 30px;
  width: 100%;
  /* max-width: 316px; */
  margin: 0 auto;

  & .connectProsTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
    line-height: 11px;
    color: var(--matterColorDarkBlue);
    margin: 0 0 28px;
    white-space: nowrap;

    & svg {
      margin-right: 5px;
      width: 14px;
      height: 19px;
    }
  }

  & .startRequestFormDescription {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #949494;
  }

  & .startRequestFormButton {
    background: var(--matterColorLight);
    width: 100%;
    min-height: 44px;
    border-radius: 24px;
    font-weight: normal;
    font-size: 17px;
    line-height: 11px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    color: var(--marketplaceColor);
    margin-top: 30px;
  }
}

.searchEnquiryFormContainer {
  padding: 0 110px 50px;

  @media (--viewportMobile) {
    padding: 24px;
  }
}

.probuddyInfo {
  max-width: 1175px;
  padding: 0 24px;
  margin: 500px auto 0;

}

.probuddyInfoImage {
  width: 100%;
}

.experienceHead {
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  color: var(--marketplaceColor);
  margin-bottom: 13px;
  display: flex;
}

.experienceTime {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-top: 20px;

  @media (--viewportMobile) {
    column-gap: 5px;

    & svg {
      width: 20px;
      height: auto;
    }
  }
}

.experienceTimeText {
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  color: var(--marketplaceColorDarkerI);

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.experienceDesc {
  display: flex;
  align-items: flex-start;
  margin-top: 55px;
  flex-direction: column;

  @media (--viewportMobile) {
    margin-top: 0;
  }
}

.experienceDescHead {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: var(--matterColorDark);

  @media (--viewportMobile) {
    font-size: 24px;
    line-height: 30px;
  }
}

.experienceDescText {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin: 20px 0;

  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 30px;
  }
}

.probuddyServices {
  background: #f8f8f8;
  padding: 50px 0;

  @media (--viewportMobile) {
    padding: 20px 24px 0;
  }
}

.probuddyServicesWrapper {
  padding: 0 24px;
  max-width: 1175px;
  margin: 0 auto;
}

.probuddyServicesHead {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: var(--matterColorDark);

  @media (--viewportMobile) {
    font-size: 24px;
    line-height: 30px;
  }
}

.probuddyServicesText {
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: var(--matterColorDark);
  display: inline-block;
  margin: 20px 0;

  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 35px;
  }
}

.servicesPoints {
  display: flex;
  flex-wrap: wrap;
  column-gap: 44px;
  margin-top: 40px;

  @media (--viewportMobile) {
    row-gap: 40px;
  }
}

.servicesPointsWrap {
  width: 48%;
  display: flex;
  flex-direction: column;
  row-gap: 50px;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.pointsItems {
  display: flex;
  flex-direction: column;
}

.pointsItemsHead {
  font-weight: 700;
  font-size: 24px;
  line-height: 60px;
  color: var(--matterColorDark);
  margin: 0;

  @media (--viewportMobile) {
    font-size: 24px;
    line-height: 40px;
  }
}

.pointsItemsText {
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin-bottom: 0;

  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 35px;
  }
}

.servicesPointsDesc {
  display: flex;
  margin-top: 70px;
  flex-direction: column;
}

.PointsDescText {
  font-weight: 700;
  font-size: 24px;
  line-height: 60px;
  color: var(--matterColorDark);
  margin: 30px 0;

  @media (--viewportMobile) {
    font-size: 24px;
    line-height: 35px;
  }
}

.articalContainer {
  max-width: 1175px;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  column-gap: 35px;

  @media (--viewportMobile) {
    display: block;
  }
}

.articalContainer .articalWrapper:nth-of-type(2) {
  @media (--viewportMobile) {
    margin-top: 35px;
  }
}

.articalWrapper {
  width: 100%;
  text-decoration: none !important;
  font-size: 16px !important;

  @media (--viewportLaptop) {
    width: 570px;
  }
}

.articalHeading {
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  color: var(--marketplaceColor);

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 35px;
  }
}

.articalInfo {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  height: 70px;
  color: var(--marketplaceColorDarkerI);
  margin: 3px 0 4px;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.articalTime {
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  color: #bcbcbc;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.fitnessArticleTitle {
  max-width: 1175px;
  padding: 0 24px;
  margin: 30px auto 100px;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: var(--matterColorDark);

  @media (--viewportMobile) {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 50px;
  }
}

.articleImageContainer {
  width: 100%;
  height: auto;
  background: #c5c5c5;
  border-radius: 16px;
  margin-bottom: 13px;

  & img {
    width: 100%;
    height: auto;
    border-radius: 16px;
  }

  @media (--viewportLaptop) {
    width: 570px;
    height: 308px;
  }

  @media (--viewportMobile) {
    margin-bottom: 7px;
  }
}


.blueText{
  color: var(--marketplaceColor);
  text-transform: lowercase;
}

.classMode{
  padding: 0 0 20px 75px;
  display: flex;
  justify-content: center;
  @media (--viewportMobile) {
    display: none;
  }
}