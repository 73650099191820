@import '../../marketplace.css';

.root {}

.sectionWrapper {
  max-width: 1175px;
  margin: 0 auto;
  padding: 0 24px;
}

.getQuoteSectionWrapper {
  max-width: 1175px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media (--viewportMobile) {
    flex-direction: column;
    padding: 0 24px;
  }
}

.topContainer {
  background: #243144;
  height: 332px;
}

.topContainerWrapper {
  max-width: 1175px;
  margin: 0 auto;

  & h2 {
    font-weight: bold;
    font-size: 45px;
    line-height: 35px;
    color: var(--matterColorLight);
    margin: 40px 0 25px;
  }

  @media (--viewportMobile) {
    padding: 0 24px;

    & h2 {
      margin: 65px 0 14px;
    }
  }
}

.stateButton {
  padding: 8px 22px 7px;
  cursor: pointer;
  background: #2b3c56;
  border-radius: 22px;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #6b7b95;
}

.selectedStateButtonContainer {
  background: var(--marketplaceColor);
  cursor: default;
  color: var(--matterColorLight);
}

.searchSVGWrapper svg {
  fill: transparent;
}

.stateButtonWrapper {
  display: flex;
  align-items: center;
  gap: 0 16px;

  @media (--viewportMobile) {
    display: none;
  }
}

.stateDropDown {
  width: 158px;
  height: 45px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: var(--matterColorLight);
  background-color: #2b3c56;
  background-image: url('/static/images/Icons/whiteDownArrow.png');
  background-size: 9px 5px;
  background-position-x: 95%;
  padding: 12px 15px;
  border-radius: 5px;
  border: 0;
  display: none;

  @media (--viewportMobile) {
    display: block;
  }
}

.stateButtonContainer {
  display: flex;
  justify-content: space-between;
}

.articleImageContainer {
  width: 570px;
  height: 308px;
  background: #c5c5c5;
  border-radius: 16px;
  margin-bottom: 13px;

  @media (--viewportMobile) {
    width: 100%;
    margin-bottom: 7px;
  }
}

.articalContainer {
  max-width: 1175px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  column-gap: 35px;
  margin-top: -140px;

  @media (--viewportMobile) {
    padding: 0 24px;
    display: block;
  }
}

.articalContainer .articalWrapper:nth-of-type(2) {
  @media (--viewportMobile) {
    margin-top: 35px;
  }
}

.articalWrapper {
  width: 570px;
  text-decoration: none !important;
  font-size: 16px !important;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.articalHeading {
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  color: var(--marketplaceColor);

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 35px;
  }
}

.articalInfo {
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  color: var(--marketplaceColorDarkest);
  margin: 3px 0 4px;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.articalTime {
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  color: #bcbcbc;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.customerStoriesSection {
  margin: 122px 0 0;
  background: #fafafa;
  padding: 32px 0 44px;

  @media (--viewportMobile) {
    margin: 45px 0 0;
    padding: 35px 0 58px;
  }
}

.allCustomerStoriesBtn {
  width: 208px;
  min-height: 55px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: var(--marketplaceColor);
  border: 2px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 28px;
}

.customerStoriesTitle {
  font-weight: bold;
  font-size: 40px;
  line-height: 35px;
  text-align: center;
  color: var(--marketplaceColorDarkest);

  @media (--viewportMobile) {
    font-size: 35px;
  }
}

.blogSection .customerStoriesTitle {
  @media (--viewportMobile) {
    margin: 0;
  }
}

.customerStoriesContainer {
  display: flex;
  justify-content: center;
  gap: 0 25px;

  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 20px;
  }

  @media (--viewportMobile) {
    display: block;
  }
}

.customerStoriesTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 38px;

  @media (--viewportMobile) {
    margin: 0 0 18px;
  }
}

.eBookSection .customerStoriesTop {
  margin: 0 0 29px;

  @media (--viewportMobile) {
    margin: 0 0 23px;
  }
}

.eBookSection .customerStoriesTitle {
  margin: 0;
}

.customerStoriesItem {
  width: 375px;

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (--viewportMobile) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 35px;
    }

    &:last-child {
      margin-bottom: 58px;
    }
  }
}

.customerStoriesItem:hover {
  text-decoration: none !important;
}

.eBookSection .customerStoriesItem {
  @media (--viewportMobile) {
    &:not(:last-child) {
      margin-bottom: 64px;
    }

    &:last-child {
      margin-bottom: 50px;
    }
  }
}

.customerStoriesImageContainer {
  width: 100%;
  height: auto;
  background: #c5c5c5;
  border-radius: 16px;

  @media (--viewportLaptop) {
    width: 375px;
    height: 278px;
  }

  & img {
    border-radius: 16px;
    max-width: 100%;
    height: auto;
  }
}

.customerStoriesHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  color: var(--marketplaceColor);
  margin-top: 13px;
  display: block;

  @media (--viewportMobile) {
    font-size: 15px;
  }
}

.customerStoriesItem .customerStoriesHeading {
  margin-top: 23px;

  @media (--viewportMobile) {
    margin-top: 8px;
  }
}

.eBookSection .customerStoriesItem .customerStoriesHeading {
  @media (--viewportMobile) {
    margin-top: 12px;
  }
}

.customerStoriesInfo {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: var(--marketplaceColorDarkerI);
  margin-top: 6px;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.recommendedEbookSection {
  /* margin: 41px 0; */
  max-width: 1175px;
  width: 100%;
  margin: 41px auto;
  background: #243144;
  align-items: center;
  padding: 19px;
  display: flex;
  justify-content: space-between;
  border-radius: 23px;

  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 46px 24px 42px;
    border-radius: 0;
  }
}

.recommendedEbookImageWrapper {
  width: 220px;
  height: 182px;
  background: #3c4d66;
  border-radius: 12px;

  @media (--viewportMobile) {
    width: 168px;
    height: 182px;
    margin-bottom: 12px;
  }
}

.recommendedEbookDetailContainer {
  display: flex;
  align-items: center;
  gap: 0 28px;

  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.recommendedEbookTextWrapper {
  display: grid;
  max-width: 545px;

  @media (--viewportMobile) {
    margin-bottom: 32px;
  }
}

.recommendedEbookText {
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #4ad2ed;
  margin-bottom: 8px;
}

.recommendedEbookInfo {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: var(--matterColorLight);

  @media (--viewportMobile) {
    font-size: 25px;
  }
}

.GetEBookButton {
  width: 196px;
  min-height: 72px;
  background: var(--marketplaceColor) !important;
  border-radius: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: var(--matterColorLight);
}

.blogSection {
  max-width: 1175px;
  width: 100%;
  margin: 72px auto 94px;

  @media (--viewportMobile) {
    padding: 0 24px;
    margin: 5px auto 55px;
  }
}

.blogSection .customerStoriesContainer .customerStoriesItem:last-child {
  margin-bottom: 35px;
}

.blogTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 23px;
}

.podcastsSection {
  background: #fafafa;
  padding: 54px 0 72px;

  @media (--viewportMobile) {
    padding: 40px 0 55px;
  }
}

.allPodcastsSectionBtn {
  width: 208px;
  min-height: 55px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: var(--marketplaceColor);
  border: 2px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 28px;
}

.podcastsSectionTitle {
  font-weight: bold;
  font-size: 40px;
  line-height: 35px;
  text-align: center;
  color: var(--marketplaceColorDarkest);

  @media (--viewportMobile) {
    font-size: 35px;
  }
}

.podcastsSectionTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 45px;

  @media (--viewportMobile) {
    margin: 0 0 6px;
  }
}

.podcastsImageContainer {
  width: 375px;
  height: 213px;
  position: relative;
  background: #c5c5c5;
  border-radius: 10px;

  & svg {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }

  @media (--viewportMobile) {
    width: 100%;
  }
}

.rightImageContainer {
  width: 311px;
  height: 473px;
  background: var(--marketplaceColorBackground);
  border-radius: 20px;

  @media (--viewportMobile) {
    margin-top: 30px;
    width: 100%;
  }
}

.podcastTitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  color: var(--marketplaceColor);

  @media (--viewportMobile) {
    font-size: 15px;
    margin-top: 11px;
  }
}

.podcastInfo {
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  color: var(--marketplaceColorDarkest);

  @media (--viewportMobile) {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
  }
}

.podcastTime {
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  color: #bcbcbc;
  margin-top: 35px;

  @media (--viewportMobile) {
    font-size: 18px;
    margin-top: 0;
  }
}

.podcastTextSection {
  width: 371px;
  display: grid;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.podcastCards {
  display: flex;
  flex-direction: column;
  gap: 47px 0;
}

.podcastCard {
  display: flex;
  align-items: center;
  gap: 0 27px;

  @media (--viewportMobile) {
    display: block;
  }
}

.podcastDetailContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportMobile) {
    display: block;
  }
}

.podcastImageDetail {
  height: 100%;
  background: var(--marketplaceColor);
  border-radius: 10px 0px 0px 10px;
  width: 177px;
  padding: 0 18px 17px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.imageHashNumber {
  font-weight: 600;
  font-size: 40px;
  line-height: 35px;
  color: #35c0dc;
}

.imageAuthorName {
  font-weight: bold;
  font-size: 25px;
  line-height: 42px;
  color: var(--matterColorLight);
  margin-top: 9px;
}

.eBookSection {
  max-width: 1175px;
  width: 100%;
  margin: 67px auto 133px;

  @media (--viewportMobile) {
    padding: 0 24px;
    margin: 56px auto;
  }
}

.eBookImageContainer {
  width: 375px;
  height: 485px;
  background: #c5c5c5;
  border-radius: 16px;
  display: flex;
  align-items: flex-end;

  & .imageHeading {
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: var(--matterColorLight);

    @media (--viewportMobile) {
      font-size: 25px;
    }
  }

  & .imageInfo {
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    color: var(--marketplaceColor);
    margin-top: 6px;

    @media (--viewportMobile) {
      font-size: 18px;
    }
  }

  & .eBookImageDetailWrapper {
    width: 375px;
    height: 224px;
    padding: 0 21px 28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 16px;
    background: linear-gradient(180.18deg, rgba(223, 223, 223, 0) 32.87%, #222222 99.84%);
  }

  @media (--viewportMobile) {
    width: 100%;
  }
}

.getQuoteFormContainer {
  width: 499px;
  height: 100%;
  background: var(--matterColorLight);
  border-radius: 16px;

  @media (--viewportMobile) {
    width: 100%;
    margin-top: 40px;
  }
}

.getQuoteSection {
  background: var(--marketplaceColorBackground);
  padding: 92px 0 99px;

  @media (--viewportMobile) {
    display: block;
    padding: 42px 0 50px;
  }
}

.getQuoteTextContainer {
  max-width: 535px;
}

.getQuoteHeading {
  font-weight: bold;
  font-size: 40px;
  line-height: 35px;
  /* text-align: center; */
  color: var(--matterColorLight);
  margin: 0 0 10px;

  @media (--viewportMobile) {
    font-size: 25px;
  }
}

.getQuoteSubHeading {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: block;
  max-width: 476px;
  margin-bottom: 32px;
  color: #6b7b95;

  @media (--viewportMobile) {
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 26px;
  }
}

.getQuoteTextInfo {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  display: block;
  color: var(--matterColorLight);
  margin-bottom: 117px;

  @media (--viewportMobile) {
    margin-bottom: 52px;
    font-size: 20px;
    line-height: 30px;
  }
}

.personDetailContainer {
  display: flex;
  gap: 0 13px;
  margin-bottom: 38px;

  @media (--viewportMobile) {
    margin-bottom: 44px;
  }
}

.personTextSection {
  & .personName {
    font-weight: bold;
    font-size: 26px;
    line-height: 35px;
    color: var(--matterColorLight);
  }

  & .personPost {
    font-weight: normal;
    font-size: 20px;
    line-height: 35px;
    display: block;
    color: #6b7b95;
  }
}

.growthContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.growthWrapper {
  & .growthNumber {
    font-weight: bold;
    font-size: 50px;
    line-height: 35px;
    text-align: center;
    color: var(--matterColorLight);

    @media (--viewportMobile) {
      font-size: 40px;
    }
  }

  & .growthType {
    font-weight: 600;
    font-size: 16px;
    display: block;
    line-height: 35px;
    text-align: center;
    color: var(--matterColorLight);
    margin-top: 8px;

    @media (--viewportMobile) {
      font-size: 15px;
    }
  }
}

.desktopButton {
  @media (--viewportMobile) {
    display: none;
  }
}

.mobileButton {
  display: none;

  @media (--viewportMobile) {
    display: block;
    margin: 0 auto;
  }
}