@import '../../../../marketplace.css';

.hostedByProsVideoSection{
    max-width: 1175px;
    margin: 144px auto 0;
    padding: 0 24px;
}

.hostedByProsWrap{
    display: flex;
    padding: 51px 0 53px;
    column-gap: 45px;
    border-bottom: 1px solid #E5E5E5;
    @media (--viewportMobile) {
        flex-direction: column;
        padding: 33px 0 12px;
        border:0;
    }
    
}

.hostedProsHeadingWrap{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.hostedByProsTitle{
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: var(--matterColorDark);
    margin: 0;
}
  
.hostedByProsSlogan{
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--matterColorDark);
    @media (--viewportMobile) {
        padding: 62px 0 15px;

    }
}
  
.videoWrapper{
    max-width: 756px;
    height: auto;
    width: 100%;
    max-height: 300px;

    @media (--viewportMobile) {
        height: 412px;
    }
}

.videoWrapper video{
    border-radius: 28px;
    max-width: 100%;
    height: 100%;
    object-fit: inherit;
}