@import '../../marketplace.css';

.articleTopContainer {
  background: #243144;
  height: 415px;
  padding-top: 40px;
}

.containerWrapper {
  max-width: 1175px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 991px) {
    padding: 0 24px;
  }

  @media (--viewportMobile) {
    padding: 0 24px;
  }
}

.backToBlogLink {
  max-width: 174px;
  min-height: 53px;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  color: var(--matterColorLight);
  padding: 12px 18px 11px;
  background: #243144 !important;
  border: 1.4px solid #324259;
  box-sizing: border-box;
  border-radius: 26px;
  margin-top: 40px;
  & svg {
    margin-right: 6px;
  }
}
.articleTitle {
  font-weight: bold;
  font-size: 50px;
  line-height: 35px;
  margin: 42px 0 7px;
  color: var(--matterColorLight);
  @media (--viewportMobile) {
    font-size: 35px;
    margin: 32px 0 6px;
  }
}

.articleSubTitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  color: var(--marketplaceColor);
  @media (--viewportMobile) {
    font-size: 20px;
    margin: 0 0 6px;
  }
}

.articleDetailContainer {
}

.articleImageWrapper {
  width: 100%;
  margin: -200px 0 0;
  height: 375px;
  background: #c5c5c5;
  border-radius: 16px;
  @media (--viewportMobile) {
    margin: -189px 0 0;
  }

  & img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
  }
}

.timeAndLinkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 59px;
  @media (--viewportMobile) {
    flex-direction: column;
    margin: 22px 0 32px;
    gap: 12px 0;
  }
}

.articleTime {
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  color: var(--marketplaceColorDarkest);
  display: flex;
  align-items: center;
  gap: 0 16px;
  & svg {
    width: 28px;
    height: 28px;
  }
  @media (--viewportMobile) {
    font-size: 22px;
  }
}

.articleSocialLinks {
  display: flex;
  gap: 0 21px;
}

.articleSocialLinks svg {
  width: 44px;
  height: 44px;
  fill: transparent;
}

.articleDescription {
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 28px;
  color: var(--matterColorDark);
  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 32px;
  }
}

.articleQuestionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 23px 0;
  margin: 0 0 39px;
  & .question {
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    color: var(--matterColorDark);
    display: flex;
    align-items: center;
    gap: 0 51px;
    & svg {
      width: 17px;
      height: 12px;
    }
    @media (--viewportMobile) {
      font-size: 20px;
      gap: 0 28px;
    }
  }
  @media (--viewportMobile) {
    margin: 0;
  }
}

.articleFAQSection {
  padding: 75px 0 0;
  @media (--viewportMobile) {
    padding: 63px 0 0;
  }
}

.articleFAQGreySection {
  background: #f8f8f8;
  margin-top: 32px;
  padding: 74px 0 86px;
  @media (--viewportMobile) {
    padding: 41px 0 39px;
  }
}

.articleFAQ {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: var(--marketplaceColorDarkest);
  margin: 0 0 16px;
  @media (--viewportMobile) {
    font-size: 26px;
    line-height: 40px;
    margin: 0;
  }
}
.articleFAQTopicsWrapper {
  display: flex;
  column-gap: 99px;
  & h4 {
    font-weight: bold;
    font-size: 35px;
    line-height: 60px;
    color: var(--marketplaceColorDarkest);
    margin: 16px 0 0;
  }
  @media (--viewportMobile) {
    flex-direction: column;
    & h4 {
      font-size: 24px;
      line-height: 30px;
      margin: 26px 0 0;
    }
  }
}

.topicWrapper {
  width: 100%;
}

.playBookWrapper {
  max-width: 1175px;
  width: 100%;
  margin: 59px auto 30px;
  padding: 63px 57px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  color: var(--matterColorLight);
  background: var(--marketplaceColorBackground);
  border-radius: 12px;
  & span {
    max-width: 580px;
    @media (max-width: 991px) {
      max-width: 530px;
    }
  }
  & button {
    width: 196px;
    min-height: 72px;
    max-height: 72px;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    color: var(--matterColorLight);
    background: var(--marketplaceColor) !important;
    border-radius: 12px;
  }
  @media (--viewportMobile) {
    flex-direction: column;
    padding: 35px 29px 42px;
    font-size: 25px;
    line-height: 35px;
    border-radius: 0;
    gap: 45px 0;
    margin: 32px 0 0;
  }
}

.summarySection {
  margin: 32px 0 48px;
  @media (--viewportMobile) {
    margin: 59px 0 50px;
  }
}

.summaryTitle {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  margin: 0;
  color: var(--marketplaceColorDarkest);
  @media (--viewportMobile) {
    font-size: 30px;
    line-height: 40px;
  }
}

.playbookSummaryWrapper {
  background: var(--marketplaceColorBackground);
  border-radius: 12px;
  padding: 58px 57px 57px;
  display: flex;
  max-width: 1175px;
  width: 100%;
  margin: 63px auto 0;
  & .playbookSummaryInfoTitle {
    max-width: 580px;
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px;
    color: var(--matterColorLight);
    @media (--viewportMobile) {
      font-size: 25px;
      line-height: 35px;
    }
  }
  & p {
    font-weight: normal;
    max-width: 761px;
    font-size: 24px;
    line-height: 35px;
    margin-top: 9px;
    color: var(--matterColorLight);
    @media (--viewportMobile) {
      font-size: 20px;
      line-height: 30px;
      margin-top: 7px;
    }
  }
  & button {
    width: 196px;
    min-height: 72px;
    max-height: 72px;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    color: var(--matterColorLight);
    margin-top: 48px;
    background: var(--marketplaceColor) !important;
    border-radius: 12px;
    @media (--viewportMobile) {
      margin: 42px auto 42px;
    }
  }
  @media (--viewportMobile) {
    flex-direction: column;
    border-radius: 0;
  }
}

.playbookSummaryInfoSection {
  @media (--viewportMobile) {
    padding-bottom: 78px;
  }
}
.playbookSummaryImageWrapper {
  width: 279px;
  height: 323px;
  border-radius: 17px;
  @media (--viewportMobile) {
    display: none;
  }
  & img {
    border-radius: 17px;
    width: 100%;
    height: 100%;
  }
}

.blogsSection {
  background: #f8f8f8;
  @media (--viewportMobile) {
    padding-top: 42px;
  }
}

.authorImageSection {
  max-width: 270px;
  margin: -135px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .authorImage {
    width: 270px;
    height: 270px;
    background: #c4c4c4;
    border: 18px solid var(--matterColorLight);
    border-radius: 50%;
  }
  & .authorName {
    margin: 0;
    font-weight: bold;
    font-size: 35px;
    line-height: 60px;
    color: var(--marketplaceColorDarkest);
  }
  & .authorPost {
    font-weight: 600;
    font-size: 20px;
    line-height: 60px;
    color: #c4c4c4;
  }
  & .circleIn {
    max-width: 44px;
    height: 44px;
    margin: -44px auto 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--matterColorLight);
    background: var(--marketplaceColor);
    border: 2px solid var(--marketplaceColor);
    box-sizing: border-box;
    border-radius: 27.5px;
  }
}

.getQuoteSectionWrapper {
  max-width: 1154px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  @media (--viewportMobile) {
    flex-direction: column;
    padding: 0 24px;
  }
}

.getQuoteFormContainer {
  width: 499px;
  height: 100%;
  background: var(--matterColorLight);
  border-radius: 16px;
  @media (--viewportMobile) {
    width: 100%;
    margin-top: 40px;
  }
}

.getQuoteSection {
  background: var(--marketplaceColorBackground);
  padding: 92px 0 99px;
  @media (--viewportMobile) {
    display: block;
    padding: 42px 0 50px;
  }
}

.getQuoteTextContainer {
  max-width: 535px;
}

.getQuoteHeading {
  font-weight: bold;
  font-size: 40px;
  line-height: 35px;
  /* text-align: center; */
  color: var(--matterColorLight);
  margin: 0 0 10px;
  @media (--viewportMobile) {
    font-size: 25px;
  }
}

.getQuoteSubHeading {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: block;
  max-width: 476px;
  margin-bottom: 32px;
  color: #6b7b95;
  @media (--viewportMobile) {
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 26px;
  }
}

.getQuoteTextInfo {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  display: block;
  color: var(--matterColorLight);
  margin-bottom: 117px;
  @media (--viewportMobile) {
    margin-bottom: 52px;
    font-size: 20px;
    line-height: 30px;
  }
}

.personDetailContainer {
  display: flex;
  gap: 0 13px;
  margin-bottom: 38px;
  @media (--viewportMobile) {
    margin-bottom: 44px;
  }
}

.personTextSection {
  & .personName {
    font-weight: bold;
    font-size: 26px;
    line-height: 35px;
    color: var(--matterColorLight);
  }
  & .personPost {
    font-weight: normal;
    font-size: 20px;
    line-height: 35px;
    display: block;
    color: #6b7b95;
  }
}

.growthContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.growthWrapper {
  & .growthNumber {
    font-weight: bold;
    font-size: 50px;
    line-height: 35px;
    text-align: center;
    color: var(--matterColorLight);
    @media (--viewportMobile) {
      font-size: 40px;
    }
  }
  & .growthType {
    font-weight: 600;
    font-size: 16px;
    display: block;
    line-height: 35px;
    text-align: center;
    color: var(--matterColorLight);
    margin-top: 8px;
    @media (--viewportMobile) {
      font-size: 15px;
    }
  }
}

.ourTopExampleContainer {
  @media (--viewportMobile) {
    margin: 0 0 60px;
  }
}

.sectionPromotedListings {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  @media (--viewportMobile) {
    overflow-x: scroll;
    justify-content: start;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.ourTopExampleTitle {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: var(--matterColorDarkBlue);
  @media (--viewportMobile) {
    font-size: 17px;
    line-height: 24px;
  }
}
