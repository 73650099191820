@import '../../marketplace.css';

.cardContainer {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(3, minmax(10px, auto));
  grid-column-gap: 10px;
  width: 100%;
  border: 2px solid #efefef;
  border-radius: 15px;
  padding: 20px 0px;
  margin: 0 auto;
  @media (--viewportTabletDown) {
    padding: 10px 0px 0px 10px;
  }
}

.jobSelected {
  grid-row: 2/3;

  @media (--viewportTabletDown) {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

.jobTitle {
  grid-column: 2/5;
  grid-row: 1/4;

  @media (--viewportTabletDown) {
    grid-column: 2/9;
    grid-row: 1/2;
  }
}
.jobDescription {
  grid-column: 5/9;
  grid-row: 1/4;
  border-right: 1px solid var(--marketplaceColorLightWhiteI);
  @media (--viewportTabletDown) {
    grid-column: 1/16;
    grid-row: 2/3;
    padding: 0px 10px;
  }
}
.innerCont {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: start;
}
.jobText {
  text-align: start;
}
.jobTimes {
  grid-column: 9/11;
  grid-row: 1/4;
  border-right: 1px solid var(--marketplaceColorLightWhiteI);
  @media (--viewportTabletDown) {
    grid-column: 1/5;
    grid-row: 3/4;
    border-right: 0px;
  }
}
.jobLocation {
  grid-column: 11/13;
  grid-row: 1/4;
  border-right: 1px solid var(--marketplaceColorLightWhiteI);
  @media (--viewportTabletDown) {
    grid-column: 5/11;
    grid-row: 3/4;
    border-right: 0px;
  }
}
.jobStatus {
  grid-column: 13/15;
  grid-row: 1/4;
  @media (--viewportTabletDown) {
    grid-column: 11/14;
    grid-row: 3/4;
  }
}
.jobMoreIcon {
  grid-row: 2/3;
  @media (--viewportTabletDown) {
    grid-column: 14/16;
    grid-row: 1/2;
  }
}

.labelSellerLevels {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* text-transform: uppercase; */
  color: var(--marketplaceColorDarkerI);
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 12px 10px;
  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceOffWhiteColor);
  box-sizing: border-box;
  border-radius: 6px;
  max-height: 34px;
  width: 115px;
  margin-top: 16px;

  & svg {
    margin-left: 25px;
    & path {
      fill: var(--marketplaceColorLightI);
    }
  }

  @media (--viewportTabletDown) {
    width: 100px;
    padding: 8px 6px 10px;
  }
}

.bookButton {
  @apply --marketplaceButtonStylesdefault;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  border: 1px solid var(--marketplaceOffWhiteColor);
  min-height: 34px;
  width: 100px;
  margin-top: 16px;

  @media (--viewportMobile) {
    min-width: 66px;
    margin-bottom: 5px;
  }
}

.statusIcon {
  background: green;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-right: 10px;
}

.subHeading {
  color: var(--marketplaceColorLightI);
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.gameDetails {
  color: #6e6f71;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.title {
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
}

.subTitle {
  color: #233045;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.timing {
  color: #233045;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.moreIcon {
  height: 30px;
  width: 30px;
}
