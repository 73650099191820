@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.packageSessionsField {
  margin: 24px 0;
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.submitButton {
  border-radius: 8px;
  min-height: 66px;
  padding: 20px 54px;
  white-space: nowrap;
  width: 257px;
  background-color: var(--marketplaceColor);
  border: 0;
  color: var(--matterColorLight);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 257px;
    margin: auto;
    /* margin-top: 100px; */
  }
}

.submitButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.addonWrap {
  display: flex;
}

.addonText {
  & textarea {
    padding: 5px 0;
  }
}

.mutateBtns {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;

  @media (--viewportMobile) {
    margin-bottom: 24px;
  }

  &>button {
    max-width: 200px;
    min-height: 40px;
  }
}

.pricingInfo {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--marketplaceColorDarkerI);
}

.boldText {
  font-weight: 700;
}

.sectionTitle {
  margin: 0 !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--marketplaceRedDarkColor);
}

.deliveryTimeFields {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.timeFieldsWrapper {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  column-gap: 10px;
  width: 435px;
  min-height: 80px;

  @media (--viewportMobile) {
    font-size: 15px;
  }
}

.timeFields {
  width: 63px;

  & input {
    text-align: center;
  }
}

.timeCalculation {
  /* width: 560px; */

  /* & img {
    width: 100%;
    height: auto;
  } */
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dddddd;
  margin: 10px 0;

  & td:first-child {
    background-color: #dddddd;
  }

  @media (--viewportMobile) {
    font-size: 10px;
  }
}

td {
  /* border: 1px solid #dddddd; */
  text-align: center;
  padding: 1px;
}

tr {
  display: table-row !important;

  &:last-child {
    background-color: #dddddd !important;
  }
}


.formGroup {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 50px 80px 40px 80px;

  @media (--viewportMobile) {
    padding: 11px 24px 40px 24px;
  }
}

.btnWrapper {
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 20px 10px 35px;
  margin: 5px;

  @media(--viewportMobile) {
    padding-left: 25px;
  }
}

.topSaveButton {
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 8px;
  border: none;
  padding: 5px;
  cursor: pointer;
  width: 200px;

  &:hover {
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportMobile) {
    width: 100px;
    font-size: 14px;
  }
}

.topSaveButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.headingTitle {
  font-size: 35px;
  color: white;
  text-transform: capitalize;
  margin: 0;

  @media (--viewportMobile) {
    /* font-size: 20px; */
    display: none;
  }
}

.expandableBar {
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 10px 0;
}

.accordionArrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  margin-left: 0.5em;
  padding: 0 0.5em;
  transform: rotateX(0);
  transition: transform .3s ease;

  &:before {
    border-color: #1a2b49;
    border-style: solid;
    border-width: 0 0 0.15em 0.15em;
    content: "";
    height: 0.6em;
    transform: rotate(-135deg);
    transition: transform .2s ease;
    width: 0.6em;
  }
}

.accordionArrowOpen {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  margin-left: 0.5em;
  padding: 0 0.5em;
  transform: rotateX(90);
  transition: transform .3s ease;

  &:before {
    border-color: #1a2b49;
    border-style: solid;
    border-width: 0 0 0.15em 0.15em;
    content: "";
    height: 0.6em;
    transform: rotate(-45deg);
    transition: transform .2s ease;
    width: 0.6em;
  }
}