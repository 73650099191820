@import '../../marketplace.css';

.reverseSection {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 100px 0;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    margin: 0;
    padding: 0;
  }
}

.section {
  display: flex;
  justify-content: space-between;
  margin: 60px 0 124px;
  padding: 0 24px;
  @media (max-width: 991px) {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  @media (min-width: 1600px) {
    max-width: 1524px;
    margin: 60px auto;
  }
}

.bottomSection {
  max-width: 1154px;
  display: flex;
  justify-content: space-between;
  margin: 72px auto 124px;
  padding: 0 24px;
  @media (max-width: 991px) {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  @media (min-width: 1600px) {
    max-width: 1524px;
    margin: 72px auto;
  }
}
body {
  overflow-x: hidden;
}

.uniqueDesignSection {
  padding: 10px 24px;
  width: 560px;
  @media (max-width: 991px) {
    padding: 0 0 88px 0;
    max-width: 100%;
  }
  & hr {
    margin: 20px 0 30px;
  }
  & h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    text-transform: capitalize;
    margin-bottom: 16px;
    color: var(--matterColorDark);
    width: 407px;
    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 30px;
      width: 90%;
      margin-top: 31;
      margin-bottom: 11px;
    }
  }
}

.topSpan {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: var(--matterColorDark);
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 30px;
  }
}
.bottomSpan {
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: var(--matterColorDimmedText);
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 25px;
  }
}

.cards {
  background: #dafffd;
  box-shadow: 0px 10px 20px 10px rgb(0 0 0 / 3%);
  border-radius: 4px;
  width: 337px;
  height: 183px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 18px;
  padding: 24px 24px 7px;
  @media (max-width: 1440px) {
    width: 330px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    width: 100%;
    height: 196px;
    padding: 23px 23px 7px;
    margin: 0 auto 20px;
  }

  & div {
    background: transparent !important;
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    font-weight: normal;
    font-size: 20px;
    line-height: 60px;
    color: #272727;
    @media (max-width: 991px) {
      font-size: 24px;
    }
  }
}
.cardsContainer {
  column-count: 2;
  @media (max-width: 991px) {
    column-count: 1;
    margin: 41px 24px;
  }
}
.cardsContainer div:nth-child(2) {
  background: #d3ffdd;
}
.cardsContainer div:nth-child(3) {
  background: #ffe4fe;
}
.cardsContainer div:nth-child(4) {
  background: #ffffea;
}
.cardsContainer div:nth-child(5) {
  background: #e0f4ff;
}
.cardsContainer div:nth-child(6) {
  background: #ffe7d1;
}

.detailSeaction {
  max-width: 600px;
  margin-right: 20px;
  padding: 0 24px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 0;
  }
  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-transform: capitalize;
    color: var(--matterColorDark);
    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 60px;
      margin-top: 121px;
    }
  }
  & .listYourServices {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    color: var(--matterColorDimmedText);
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 30px;
    }
  }
  & div {
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    & span {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 40px;
      text-transform: capitalize;
      color: var(--matterColorLightBlue);
      & svg {
        margin: 0 22px 0 0;
      }
    }
  }
}

.mainContainer {
  background: var(--marketplaceColorBackground);
  justify-content: center;
  align-items: center;
  display: flex;
  /* overflow: hidden; */
}

.topHeadContainer {
  height: 650px;
  width: 100%;
  background: var(--marketplaceColorBackground);
  padding-top: 128px;
  color: var(--matterColorLight);
  margin-top: -5px;
  @media (max-width: 991px) {
    margin-top: -1px;
    height: 400px;
    padding-top: 60px;
  }
}

.topHeadSection {
  max-width: 1154px;
  height: 100%;
  /* justify-content: center; */
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin: 0 12px;
  }
  & h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 57px;
    line-height: 35px;
    text-align: center;
    color: var(--matterColorLight);
    margin: 8px 0 0;
    @media (max-width: 991px) {
      font-size: 30px;
    }
  }

  & .probuddyBookingTools {
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #c4c4c4;
    @media (--viewportMobile) {
      font-size: 18px;
      line-height: 17px;
    }
  }
  & p {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #c4c4c4;
    padding: 28px 0 42px 0;
    width: 609px;
    margin: 0;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 13px;
      width: 100%;
      padding: 24px 0 30px 0;
    }
  }
  & button {
    background: var(--matterColorLightBlue) !important;
    border-radius: 8px;
    width: 379px;
    height: 65px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: capitalize;
    color: var(--matterColorLight);
    @media (max-width: 991px) {
      width: 155px;
      height: 40px;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.imageContainer {
  height: 300px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -51px 0 0 0;
  & svg {
    height: auto;
  }
  & .desktopSVG {
    display: block;
  }
  & .mobileSVG {
    display: none;
    width: 100%;
  }
  @media (--viewportMobile) {
    height: auto;
    padding: 0;
    margin: -70px 0 0 0;
    & svg {
      height: auto;
      margin-bottom: 31px;
    }
    & .desktopSVG {
      display: none;
    }
    & .mobileSVG {
      display: block;
    }
  }
}

.imageitem {
  width: 1107px;
  height: 506px;
  position: relative;
  @media (--viewportMobile) {
    width: 354px;
    height: 145px;
    margin-left: -20px;
  }
  & img {
    height: 100%;
    width: 100%;
    /* object-fit: cover; */
    border-radius: 12px;
    box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.08);
    @media (--viewportMobile) {
      margin: 0;
      margin-top: -72px;
      height: 177px;
    }
  }
}

.imageitem2 {
  width: 156px;
  height: 321px;
  position: absolute;
  bottom: -42px;
  right: -37px;
  @media (--viewportMobile) {
    width: 84px;
    height: 174px;
    top: 15px;
    right: -22px;
  }
  & img {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    @media (--viewportMobile) {
      border-radius: 13px;
    }
  }
}

.sectionImages {
  width: 568px;
  height: 426px;
  @media (max-width: 991px) {
    width: 100%;
    height: 233px;
    object-fit: contain;
  }
}

.cardIcons {
  width: 35px;
  height: 35px;
  @media (max-width: 991px) {
    width: 37px;
    height: 37px;
  }
}

.iconPlus {
  width: 14px;
  height: 14px;
  background: transparent !important;
  @media (max-width: 991px) {
    width: 15px;
    height: 15px;
  }
}

.iconsRightArrow {
  width: 10.5px;
  height: 19px;
}

.uniqueSectionContainer {
  max-width: 1154px;
  margin: 50px auto 0;
  @media (max-width: 991px) {
    margin: 50px 24px 0;
  }
}

.pricingHeading {
  max-width: 1154px;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin: 0 25px;
  }
  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    text-transform: capitalize;
    color: #272727;
    margin: 0;
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 35px;
    }
  }

  & .fromFreelancers {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    padding: 24px 0 33px 0;
    color: var(--matterColorDark);
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 25px;
    }
  }
  & button {
    /* background: var(--matterColorLightBlue) !important; */
    background: var(--marketplaceColor) !important;
    border-radius: 50px;
    width: 239px;
    height: 62px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: capitalize;
    color: var(--matterColorLight);
    @media (max-width: 991px) {
      width: 228px;
      height: 64px;
      line-height: 27px;
    }
  }
}

.planContainer {
  max-width: 1154px;
  margin: 48px auto 0;
  display: flex;
  justify-content: space-evenly;
  @media (--viewportTabletDown) {
    flex-direction: column;
    margin: 42px 0 0 0;
    align-items: center;
  }
}

.planHeading {
  display: flex;
  justify-content: space-between;
  & h3 {
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    text-transform: capitalize;
    color: #272727;
    margin: 0;
    @media (--viewportMobile) {
      font-size: 30px;
    }
  }
  & h4 {
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    text-transform: capitalize;
    color: var(--marketplaceColorLight);
    margin: 0;
    @media (--viewportMobile) {
      font-size: 30px;
    }
  }
  & span {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: var(--matterColorAnti);
    @media (--viewportMobile) {
      font-size: 16px;
    }
  }
}

.planHeadingWhite {
  & h3 {
    color: var(--matterColorLight);
  }
  & h4 {
    color: var(--matterColorLight);
  }
  & span {
    color: var(--marketplaceColor);
  }
}

.planPriceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px 0;
}

.planSection {
  width: 500px;
  height: auto;
  background: var(--matterColorLight);
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.03);
  border-radius: 22px;
  padding: 37px 45px 27px;
  @media (--viewportMobile) {
    width: 100%;
    height: auto;
    padding: 14px 23px 15px;
  }
  & a {
    width: 389px;
    min-height: 62px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #0095b3;
    background: #ffffff !important;
    border: 1px solid #0095b3;
    box-sizing: border-box;
    border-radius: 49px;
    margin: 21px auto 8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (--viewportMobile) {
      width: 100%;
    }
  }
}

.planSection2 {
  width: 500px;
  height: auto;
  background: var(--marketplaceColorLight);
  box-shadow: 0px 30px 40px 10px rgba(0, 149, 179, 0.15);
  border-radius: 22px;
  padding: 37px 45px 27px;

  @media (--viewportTabletDown) {
    margin-top: 28px;
  }
  @media (--viewportMobile) {
    width: 100%;
    height: auto;
    margin-top: 28px;
    padding: 14px 23px 15px;
  }
  & a {
    width: 389px;
    min-height: 62px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #0095b3;
    background: #ffffff !important;
    border: 1px solid #0095b3;
    box-sizing: border-box;
    border-radius: 49px;
    margin: 21px auto 8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (--viewportMobile) {
      width: 100%;
    }
  }
}

.planLists {
  display: flex;
  flex-direction: column;
  & h3 {
    font-weight: bold;
    font-size: 30px;
    line-height: 55px;
    text-transform: capitalize;
    color: rgba(196, 196, 196, 0.6);
    margin: 31px 0 7px;
    @media (--viewportMobile) {
      font-size: 20px;
      line-height: 30px;
      margin: 28px 0 8px;
    }
  }
  & span {
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
    color: var(--matterColorDarkBlue);
    display: flex;
    margin-bottom: 22px;
    @media (--viewportMobile) {
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 17px;
      align-items: center;
    }
  }
  & svg {
    margin-right: 13px;
    @media (--viewportMobile) {
      width: 18px;
      height: 18px;
    }
  }
}

.planListsWhite {
  & h3 {
    color: rgba(255, 255, 255, 0.5);
  }
  & span {
    color: var(--matterColorLight);
  }
}

.billedMonthly {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  text-transform: capitalize;
  color: var(--matterColorAnti);
  @media (--viewportMobile) {
    font-size: 14px;
  }
}
.whiteText {
  color: var(--matterColorLight);
}

.planGreyWrap {
  background: #f8fbfc;
}
.selectPricesection {
  padding: 44px 0 99px;
  @media (--viewportMobile) {
    padding: 56px 19px 46px;
  }
}

.sectionImagesContainer {
  display: flex;
  align-items: center;
}

hr {
  margin: 60px 0 25px;
}

.subscriptionLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--marketplaceColorLight) !important;
  border-radius: 8px;
  width: fit-content;
  padding: 0 22px;
  height: 65px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: capitalize;
  color: var(--matterColorLight);
  text-decoration: none !important;
  @media (max-width: 991px) {
    width: auto;
    height: 44px;
    font-size: 15px;
    line-height: 19px;
  }
}

.sectionCommunity {
  max-width: 1154px;
  margin: 0 auto;

  @media (--viewportMobile) {
    max-width: 100%;
    margin: 0;
  }
  & .mobileImage {
    display: none;
    width: 100%;
    @media (--viewportMobile) {
      display: block;
    }
  }
  & .desktopImage {
    display: block;
    width: 100%;
    @media (--viewportMobile) {
      display: none;
    }
  }
  & h2 {
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: var(--matterColorDarkBlue);
    margin-top: 206px;
    @media (--viewportMobile) {
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      margin-top: 0;
    }
  }
}

.topbarContainer {
  width: 90%;
  /* margin: 0 auto; */
  @media (max-width: 991px) {
    width: 100%;
  }
}

.action {
  margin-top: auto;
}

.actionBtn {
  & > a {
    color: var(--marketplaceColor);
    border-width: 1px;
    border-style: solid;
    border-color: var(--marketplaceColor);
    text-decoration: none;
    border-radius: 49px;
    &:hover {
      text-decoration: none;
    }
  }
}

.primaryActionBtn {
  & > a {
    color: var(--marketplaceColor);
    border-width: 1px;
    border-style: solid;
    border-color: var(--marketplaceColor);
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    border-radius: 49px;
    &:hover {
      text-decoration: none;
    }
  }
}

.ourTopExampleContainer {
  @media (--viewportMobile) {
    margin: 0 0 60px;
  }
}

.sectionPromotedListings {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  @media (--viewportMobile) {
    overflow-x: scroll;
    justify-content: start;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.ourTopExampleTitle {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: var(--matterColorDarkBlue);
  @media (--viewportMobile) {
    font-size: 17px;
    line-height: 24px;
  }
}
