@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileMenu_topMargin: 96px;
}

.root {
  padding-bottom: 150px;
  background: var(--matterColorLight);
}

.topRoot {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: auto;
  background: url('/static/images/Icons/mobile_menu-bg-top.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgba(196, 196, 196, 0.56);
}

.content {
  background: #fdfdfd;
  padding: 25px 24px 25px;
}

.menucontent {
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  /* padding: 25px 26px; */
  position: relative;
  & a {
    text-decoration: none !important;
  }
}

.menuContentBorder {
  border: 1px solid var(--matterColorAnti);
}

.upArrow {
  display: block;
  z-index: 99;
  position: relative;
  margin-left: 14px;
  @media (--viewportMobile) {
    z-index: 1;
  }
}

.profileMenuWrapper {
  margin: 0px;
}
.profileUpArrow {
  display: block;
  z-index: 99;
  position: relative;
  margin-left: 120px;
  @media (--viewportMobile) {
    z-index: 1;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.bookButton {
  /* @apply --marketplaceButtonStylesdefault; */
  font-weight: 600;
  font-size: 12px;
  background-color: #ffffff;
  /* line-height: 20px; */
  /* display: flex; */
  /* align-items: center; */
  /* column-gap: 6px; */
  border: 3px solid #0095b3;
  color: #0095b3;
  border-radius: 24px;
  height: 49px;
  /* min-height: 49px; */
  width: 100%;
}

.editProfile {
  background: #ffffff;
  width: 100%;
  max-width: 300px;
  min-height: 49px;
  border-radius: 24px;
  font-weight: normal;
  line-height: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  color: #0095b3;
  border: 3px solid #0095b3;
  margin-top: 0px;
}

.avatarImageDetailSection {
  display: flex !important;
  align-items: center;
  /* margin: 20px 0 0 !important;
  padding: 0 24px 0px; */
  /* border-bottom: 1px solid rgba(196, 196, 196, 0.56); */
}

.avatarSection {
  display: flex !important;
  align-items: center;
  margin: 45px 0 0 !important;
  padding: 0 24px 14px;
  /* border-bottom: 1.5px solid var(--matterColorNegative); */
  border-bottom: 1px solid rgba(196, 196, 196, 0.56);
}

.jobsBoardSection {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.jobsBoardSectionWrap {
  border-bottom: 1.5px solid var(--matterColorNegative);
  padding-bottom: 25px;
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin-right: 16px;
  width: 77px;
  height: 77px;
  position: relative;
}
.avatarName {
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  color: var(--matterColorDark);
  margin: 10px 0 4px;
}
.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColor);
  border: none;
  /* Position component */
  width: initial;
  margin: 4px 0 16px 0;
  padding: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.calendar {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;
  margin-top: auto;
  margin-bottom: 13px;
}

.wallet {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;
  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  /* Font */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColor);
  border-bottom: 1px solid var(--matterColorNegative);
}
.emphasisedLink {
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
}

.navigationIcons {
  fill: var(--marketplaceColorDark);
  stroke: var(--marketplaceColorDark);
  stroke-width: 0.5%;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.createNewListingLink {
  @apply --marketplaceButtonStylesPrimary;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.portalHeading {
  /* margin: 21px 0 0px 0; */
  font-weight: normal;
  font-size: 16.3398px;
  line-height: 131.02%;
  display: grid;
  color: var(--marketplaceColorDarkest);
  & svg {
    width: 12.11px;
    height: 7.38px;
    & path {
      fill: var(--marketplaceColorDarkest);
    }
  }
}

.jobsBoardPortalHeading {
  display: flex;
  align-items: baseline;
  gap: 0 12px;
  & svg {
    width: 47px;
    height: 24px;
  }
}

.labelWrap {
  position: relative;
}

.inboxLink,
.topbarMenuLink {
  @apply --marketplaceH4FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.clientMenuContent,
.vendorMenuContent {
  min-width: 200px !important;
  padding-top: 20px;
  left: 0px;
  width: fit-content;
  height: auto;
  padding: 22px 27px;
  column-width: auto;
}

.mobMenuTitle span {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: var(--marketplaceColorDarkest);
  margin-bottom: 0;
  margin-top: 15px;
  display: block;
}
.menuSection {
  margin-bottom: 40px;
  padding-bottom: 45px;
  border-bottom: 2px solid #eaeaea;
}
.menucontent .menuSection:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}

.menuImage {
  width: 249px;
  height: 141px;
  & img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  margin-bottom: 16px;
}
@media only screen and (max-width: 430px) {
  .menuImage {
    width: 100%;
  }
}

.jobsBoardMenuImage {
  width: 264px;
  height: 174px;
  margin-bottom: 20px;
  & img {
    width: 100%;
    height: 100%;
    border-radius: 17px;
    object-fit: cover;
  }
  @media (max-width: 360px) {
    width: 100%;
  }
}

.becomeVendorMenuImage {
  margin-bottom: 32px;
}

.blogLink {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #8e8e8e;
  margin-top: 14px;
  display: block;
}

.becomeVenderWrapper {
  display: flex;
  align-items: center;
  & svg {
    width: 11px;
    height: 7px;
    & path {
      fill: var(--matterColorDark);
    }
  }
}

.becomeVenderName {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: var(--marketplaceColor);
}

.becomeVenderTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: var(--marketplaceColor);
}

.infoSpan {
  font-weight: 500;
  font-size: 15.2504px;
  line-height: 23px;
  letter-spacing: 0.544658px;
  color: var(--matterColorDark);
  mix-blend-mode: normal;
  opacity: 0.3;
  margin-top: 9px;
}

.activeUser {
  height: 82px;
  width: 84px;
  position: relative;
  margin-right: 12px;
  & svg {
    width: 72px;
    height: 72px;
    position: absolute;
    top: -3px;
    left: -7px;
    & path {
      fill: var(--marketplaceColor) !important;
    }
  }
}

.profileInfoContainer {
  background-color: blue;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
}

.jobsBoardButton {
  display: flex;
  align-items: center;
  justify-content: center;

  animation-delay: 0.8s;
  min-height: 55px;
  padding: 0;
  background: #37455a;
  color: var(--matterColorLight);
  width: 132px;
  height: 50px;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 35px;
  text-align: center;
}

.jobsBoardButtonContainer {
  display: flex;
  width: 140px;
  position: relative;
  & svg {
    width: 39.43px;
    height: 21.69px;
    position: absolute;
    top: -10px;
    right: 0;
  }
}

.menuItemHolder {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 0 0 24px 0;
}

.jobsBoardItemHolder {
  display: flex;
  padding: 16px 0;
  align-items: flex-start;
  border-bottom: 0.1px solid var(--matterColorAnti);
  column-gap: 18px;
  & .menuItemTitle {
    font-size: 24px;
    line-height: 30px;
  }
  & .menuItemInfo {
    font-size: 20px;
    line-height: 25px;
  }
  & svg {
    margin-top: -10px;
  }
}

.jobsBoardItemHolderWithoutBorder {
  border: 0;
  padding-top: 11px;
}

.jobsBoardItemTitle {
  height: 58px;
  width: 262px;
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--marketplaceColorDarkest);
}

.displayMenu {
  display: flex !important;
  align-items: baseline;
  column-gap: 2px;
}

.greyContainer {
  padding: 46px 34px 33px;
  background: #f8f8f8;
  border-radius: 0 0 12px 12px;
}

.whiteContainer {
  padding: 47px 34px 44px;
}

.jobsBoardContainerPadding {
  padding: 47px 22px 44px;
  & a {
    text-decoration: none !important;
  }
}

.menuItemTitle {
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: var(--marketplaceColorDarkest);
  position: relative;
}

.menuItemTitleAddListings {
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  color: var(--marketplaceColorDarkest);
}

.menuItemInfo {
  display: block;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #6e6f71;
  margin-top: 4px;
}

.menuItemInfoWithBold {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #6e6f71;
  margin: 10px 0 2px;
}
.menuItemInfoWithPadding {
  display: block;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #6e6f71;
  margin: 9px 0 23px 0;
}

.notificationCountBadgeWrap {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.menuItemClientInfo {
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #6e6f71;
  margin: 7px 0 6px 0;
}
.menuItemClientInfoWithBackground {
  height: 39.96px;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  /* top: 0px; */
  background: #f1f1f1;
  border-radius: 8.74936px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4.37468px 0px;
  padding: 13px;
}
.menuItemHeading {
  margin-top: 14px;
}

.menuOptionLabel {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #6e6f71;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 8.74936px;
}

.titleManageEdit {
  font-weight: normal;
  font-size: 19px;
  line-height: 25px;
  color: var(--marketplaceColor);
  margin: 10px 0 9px 0;
  display: block;
}
.menuItemAddListingInfo {
  width: 100%;
  height: 47px;
  left: 3.84px;
  top: 0px;
  background: var(--matterColorLight);
  border-radius: 7.68169px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 3.84084px 0px;
  display: flex;
  align-items: center;
  padding: 12px;
}

.sessionsName {
  font-weight: normal;
  font-size: 15.3634px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #6e6f71;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.profileMenuContent {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px 34px;
  & span {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: var(--marketplaceColorDarkest);
  }
}

.socialSearchMarketingInfo {
  display: block;
  font-weight: normal;
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDimmedText);
  @media (max-width: 340px) {
    white-space: break-spaces;
  }
}

.menuItemTitleOneLine {
  white-space: nowrap;
  font-size: 18px;
  line-height: 26px;
  @media (max-width: 366px) {
    white-space: break-spaces;
  }
}

.socialMarketNewIcon {
  position: absolute;
  right: -4px;
  top: -25px;
  width: 45px;
  height: 23px;
  @media (max-width: 366px) {
    right: auto;
  }
}

.newIcon {
  width: 45px;
  height: 23px;
  margin-left: 5px;
}

.embeddedInfo {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--matterColorDarkBlue);
  display: block;
  margin-top: 20px;
}

.menuIconWrapper {
  width: 30px;
  height: 30px;
}

.menuImageContainer {
  width: 86px;
  height: 68px;
  mix-blend-mode: normal;
  border-radius: 17px;
  margin-right: 20px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.cardContentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.portalHeadingWrapper {
  display: flex;
  align-items: center;
  padding: 11px 32px 10px 9px;
  background: var(--matterColorLight);
  box-shadow: 13.0718px 28.3222px 54.4658px rgba(90, 108, 234, 0.07);
  margin-top: 10px;
  border-radius: 23.965px;
}
.content > .portalHeadingWrapper:nth-of-type(1) {
  margin-top: 0;
}

.notificationCountBadgeWrapper {
  display: flex;
  align-items: flex-end;
  gap: 0 16px;
}

.slider {
  & :global(.slick-dots) li {
    width: 7px;
  }
  & :global(.slick-dots) {
    display: block;
    bottom: 6px;
  }
  & :global(.slick-dots) li:nth-child(1) button:before {
    width: 7px;
    height: 7px;
    color: var(--marketplaceColor);
  }
  & :global(.slick-dots) li:nth-child(2) button:before {
    width: 7px;
    height: 7px;
    color: var(--failColor);
  }
}

.connectPros {
  background: #f1f1f1;
  box-sizing: border-box;
  width: 100px;
  min-width: 100%;
  height: auto;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 26px 24px 48px;
  margin: 0 auto;

  & .connectProsTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 17px;
    line-height: 13px;
    color: var(--matterColorDarkBlue);
    margin: 0 0 10px;
    & svg {
      margin-right: 4px;
      width: 11px;
      height: 19px;
    }
  }

  & .startRequestFormDescription {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #949494;
  }

  & .startRequestFormButton {
    background: var(--matterColorLight);
    width: 100%;
    max-width: 300px;
    min-height: 50px;
    margin: 0 auto;
    border-radius: 24px;
    font-weight: normal;
    font-size: 17px;
    line-height: 13px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    color: var(--marketplaceColor);
    margin-top: 24px;
  }

  & .redText {
    color: var(--failColor);
  }
}

.settingBtnWrapper {
  display: flex;
  padding: 30px 20px;

  gap: 0 30px;
  & a {
    text-decoration: none !important;
  }

  & span {
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    color: #0095b3;
  }
}

.settingIconWrapper {
  width: 35px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--matterColorLight);
  border: 0.527752px solid #f5f5f5;
  box-sizing: border-box;
  box-shadow: 0px 6.86077px 26.3876px rgba(90, 108, 234, 0.09);
  border-radius: 8px;
  & svg {
    width: 18px;
    height: 18px;
  }
}
