@import '../../../marketplace.css';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.cardSectionWrapper {
  margin-bottom: 40px;
  width: 100%;
}

.spinnerMiddle>svg {
  color: var(--marketplaceColor);
  transform: scale(0.7);
}

.spinnerMiddle {
  margin: 0 auto;
}

.title {
  margin-bottom: 20px;
}

.errorMsg {
  color: red;
  text-align: center;
  width: 100%;
  padding: 10px;
}