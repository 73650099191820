@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
  -webkit-overflow-scrolling: touch;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  /*min-height: 660px;*/
  padding: 0;
  /*height: 720px;*/
  /*background: url('../../assets/Landing_Pages/Banners/HomePage_Banner_1.png') no-repeat top center /
    cover;*/
}

/* .landingPageMainWrapper {
  top: -80px;
} */

.lpworksContentWrapper {
  margin-top: 50px;
  width: 100%;
  position: relative;
}

.lpExpertContentWrapper {
  height: 100%;
  display: flex;
  gap: 5rem;
  align-items: center;
  position: relative;

  @media (--viewportLaptopDown) {
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
  }
}

.iconPopularServiceContainer {
  margin-bottom: 48px;
  background-color: var(--matterColorLight);
}

.hero {
  flex-grow: 1;
  z-index: 999;
  /*padding-top: 52px;*/

  @media (max-width: 1600px) {
    /*padding-left: 7%;*/
  }

  @media (min-width: 1601px) and (max-width: 1700px) {
    /* padding-left: 2%; */
  }

  @media (--viewportTabletDown) {
    padding-left: 0;
    padding-top: 0;
  }
}

.title {
  @apply --marketplaceH2FontStyles;
  margin: 40px 24px 24px;
  font-size: 24px;
  line-height: 28px;

  @media (--viewportMobile) {
    margin-top: 20px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.sectionsWhyUs {
  margin: 0;
  padding-top: 1px;
  margin-top: 64px;
}

.expertOrPartnerSection {
  background-color: #f7f7f7;
  width: 100%;
  display: flex;
  justify-content: center;
}

.howItWorksSection {
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (--viewportLarge) {
    padding: 30px 7%;
  }

  @media (--viewportMobile) {
    display: none;
  }
}

.categorySection {
  padding-top: 30px;

  @media (--viewportLarge) {
    padding: 30px 7%;
    overflow: auto;
  }
}

.postRequestsection {
  /*overflow: auto;*/
  padding-bottom: 63px;
  background-color: var(--matterColorLight);
  width: 100%;
  display: flex;
  justify-content: center;
}

.tripExpertDefinition {
  display: flex;
  justify-content: center;
}

.processSection {
  display: flex;
  justify-content: center;
  background: rgba(235, 235, 235, 0.5);
}

.processTitle {
  @media (max-width: 991px) {
    text-align: center;
  }
}

.processDiagramWrapper {
  width: 100%;
  margin-top: 40px;
}

.pricingSection {
  display: flex;
  justify-content: center;
}

.howItWorksection {
  /*overflow: auto;*/
  padding-bottom: 63px;
  max-width: 1501px;
  margin: 0 120px;

  @media (--viewportMobile) {
    margin: 0 30px;
  }

  @media (--viewportTabletDown) and (--viewportSmall) {
    margin: 0 50px;
  }
}

.sectionWhyUs {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 64px;

  @media (--viewportLarge) {
    padding: 30px 7%;
  }
}

.sectionBelowBanner {
  overflow: auto;
  background-color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding: 0 7%;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    /* padding: 0 36px 0 36px; */
    margin: unset;
    /* margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto; */
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

.sectionPromotedSeparator {
  width: 100%;
  height: 35px;
  background-color: var(--marketplaceColorBackground);
}

.fullScreen {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  @media (--viewportXLarge) {
    max-width: 1920px;
    margin: auto;
  }
}

.promotedSectionTabMenu {
  margin: 24px;
}

.recentBookingsWrap {
  margin: 24px;
  padding: 18px 10px;
}

.recentBookingsItem {
  margin-right: 18px;

  &:hover {
    opacity: 0.8;
  }
}

.spinner {
  display: block;
  margin: 0 auto;
}

.backgroundImage {
  gap: 3rem;
}

.backgroundWhite {
  background-color: white;
}

.promoLogos {
  margin-bottom: 24px;
  background: var(--marketplaceColorLightWhite);

  & .section {
    padding: 0;
  }

  & .logoWrap {
    padding: 30px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;

    @media (--viewportLarge) {
      padding: 30px 0;
      justify-content: center;
    }

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      background: transparent;
    }

    & img {
      margin: 0 12px;

      @media (--viewportLarge) {
        margin: 0 40px;
      }
    }
  }

  & .promoLabel {
    flex: 0 0 90px;
    margin-right: 12px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--matterColorDimmedText);

    @media (--viewportLarge) {
      flex-basis: 115px;
      font-size: 17px;
      margin-right: 40px;
    }
  }
}

.loader {
  margin: 0 auto;
}

.sectionCommunityWrapper {
  overflow: auto;
  padding-top: 30px;
  background: var(--marketplaceColorBackground);

  @media (--viewportLarge) {
    padding: 30px 7% 0;
    margin-top: 90px;
  }
}

.sectionCommunity {
  max-width: 1540px;
  margin: 36px auto 0;

  @media (--viewportMobile) {
    max-width: 100%;
    margin: 0;
  }

  & .mobileImage {
    display: none;
    width: 100%;

    @media (--viewportMobile) {
      display: block;
      padding: 0 16px;
    }
  }

  & .desktopImage {
    display: block;
    width: 100%;

    @media (--viewportMobile) {
      display: none;
    }
  }

  & h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    color: var(--matterColorLight);
    margin: 0 0 86px;

    @media (--viewportMobile) {
      font-size: 17px;
      line-height: 24px;
      padding: 0 24px;
      margin: 0 0 60px;
    }
  }
}

.searchEnquiryHeader {
  margin-bottom: 32px;
  max-width: 781px;

  @media (--viewportMobile) {
    margin-bottom: 18px;
    padding: 30px 24px 0;
  }
}

.searchEnquiryTitle {
  position: relative;

  @media (--viewportMobile) {
    padding: 0 22px;
  }

  & h2 {
    font-weight: bold;
    font-size: 34px;
    line-height: 39px;
    color: var(--matterColorDarkBlue);
    margin: 0 0 19px;

    @media (--viewportMobile) {
      font-size: 27px;
      line-height: 33px;
    }

    & .blueText {
      color: var(--marketplaceColor);
    }
  }

  & p {
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    color: var(--marketplaceColorDarkest);
    margin: 0 0 39px;

    @media (--viewportMobile) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.mobileDataHeroContainer {
  display: none;

  @media (--viewportMobile) {
    display: block;
    position: relative;
  }
}

.sectionForm {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex-direction: column; */
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0.1rem 6px rgb(193, 193, 193);
  padding: 30px;
  gap: 0 140px;

  @media (max-width: 991px) {
    padding: 15px;
    width: 100%;
  }

  @media (--viewportMobile) {
    padding: 10px;
  }
}

.desktopDiscoverProsSection {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.postRequestImage {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;

  & img {
    height: 300px;
    width: auto;

    @media (--viewportMedium) {
      height: 350px;
      width: auto;
    }

    @media (--viewportLarge) {
      height: 530px;
      width: auto;
    }
  }
}

.searchEnquiryFormContainer {
  @media (--viewportMobile) {
    padding: 0 24px;
  }
}

.Promotion {
  display: none;

  @media (--viewportMobile) {
    display: block;
    padding: 20px 12px 9px;
  }
}

.portalLinkWrap {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  margin: 26px 0 10px;
}

.clientAndVendorPortalLink {
  width: 100%;
  height: auto;
  border-radius: 7.28137px;

  & svg {
    width: 100%;
    height: auto;
  }
}

.categoryAvatarWrap {
  display: flex;
  justify-content: space-between;
  gap: 0 15px;
  padding-bottom: 10px;
  overflow-x: scroll;

  & span {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;
    display: flex;
    margin-top: 14px;
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.categoryAvatar {
  & img {
    width: auto;
    height: 74px;
  }
}

.connectPros {
  background: #f1f1f1;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 35px 26px 30px;
  width: 100%;
  /* max-width: 316px; */
  margin: 0 auto;

  & .connectProsTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
    line-height: 11px;
    color: var(--matterColorDarkBlue);
    margin: 0 0 28px;
    white-space: nowrap;

    & svg {
      margin-right: 5px;
      width: 14px;
      height: 19px;
    }
  }

  & .startRequestFormDescription {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #949494;
  }

  & .startRequestFormButton {
    background: var(--matterColorLight);
    width: 100%;
    min-height: 44px;
    border-radius: 24px;
    font-weight: normal;
    font-size: 17px;
    line-height: 11px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    color: var(--marketplaceColor);
    margin-top: 30px;
  }
}

.postRequestGifContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 316px; */
  margin: 0 auto;
}

.postRequestGifContainer {
  z-index: 2;
}

.postRequestGif {
  width: 80%;
  height: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 23px;

  @media (--viewportMobile) {
    width: 80%;
    height: auto;
  }
}

.imagePostRequestGifBackgroundContainer {
  position: absolute;
  left: -2%;
}

.imagePostRequestGifBackground {
  width: 100%;

  @media (--viewportMobile) {
    max-width: 392px;
    max-height: 241px;
  }
}

.ImageSection {
  display: flex;
  width: 876px;
  height: 259px;
  margin: 30px 0 -56px;
  z-index: -1;
  /* birthday */
  position: relative;

  @media (--viewportXLarge) {
    margin: 30px 0 -26px;
  }

  & .leftSvg {
    position: absolute;
    top: -16px;
    left: -34px;
    z-index: -1;

    @media (--viewportTabletDown) {
      display: none;
    }
  }

  & .rightSvg {
    position: absolute;
    top: -50px;
    right: -22px;
    z-index: -1;

    @media (--viewportTabletDown) {
      display: none;
    }
  }

  @media (--viewportLargeWithPaddings) {
    width: 100%;
  }

  & div {
    width: 290px;
    height: 264px;

    & img {
      width: 100%;
      height: 259px;
    }

    @media (--viewportTabletDown) {
      width: 100%;
      height: auto;

      & img {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (--viewportTabletDown) {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
}

.leftFormContainer {
  display: flex;
  flex-direction: column;
  gap: 20px 0;

  @media (--viewportLarge) {
    width: 500px;
  }
}

/* Top Section Styling */
.topHeadContainer {
  height: 650px;
  width: 100%;
  background: var(--marketplaceColorBackground);
  padding-top: 128px;
  color: var(--matterColorLight);
  margin-top: -5px;

  @media (max-width: 991px) {
    margin-top: -1px;
    height: 550px;
    padding-top: 60px;
  }

  @media (--viewportTabletDown) {
    padding-top: 0px;
  }
}

.topHeadSection {
  max-width: 1154px;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media (--viewportMobile) {
    margin: 0 12px;
  }

  & h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 57px;
    line-height: 35px;
    text-align: center;
    color: var(--matterColorLight);
    margin: 8px 0 2rem 0;

    @media (max-width: 991px) {
      font-size: 30px;
      margin-top: 30px;
    }
  }

  & .probuddyBookingTools {
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #c4c4c4;

    @media (--viewportMobile) {
      font-size: 18px;
      line-height: 17px;
    }
  }

  & p {
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #c4c4c4;
    padding: 20px 0;
    width: 609px;
    margin: 0;

    @media (max-width: 991px) {
      font-size: 22px;
    }

    @media (--viewportMobile) {
      width: 100%;
      padding: 12px 50px;
      font-size: 18px;
    }
  }

  & button {
    background: var(--matterColorLightBlue) !important;
    border-radius: 8px;
    width: 379px;
    height: 65px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: capitalize;
    color: var(--matterColorLight);

    @media (max-width: 991px) {
      width: 155px;
      height: 40px;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.lpContentWrapper {
  max-width: 1501px;
  margin: -7rem 120px 0 120px;
  position: relative;

  @media (--viewportMobile) {
    margin: -4rem 30px 0 30px;
  }

  @media (max-width: 991px) and (--viewportSmall) {
    margin: -6rem 50px 0 50px;
  }
}

/* Trip Expert Split Section */

.section {
  display: flex;
  justify-content: space-between;
  margin: 0 120px;
  max-width: 1501px;
  padding-bottom: 80px;
  overflow: auto;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  @media (--viewportSmall) and (--viewportTabletDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }
}

.priceSubSection {
  display: flex;
  justify-content: space-around;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.reverseSection {
  display: flex;
  justify-content: space-between;
  max-width: 1501px;
  margin: 0 120px;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }

  @media (--viewportTabletDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }

}

.normalDesignSection {
  padding: 10px 24px;

  @media (max-width: 991px) {
    padding: 0;
    max-width: 100%;
  }

  & hr {
    margin: 20px 0 30px;
  }

  & h1 {
    @apply (--marketplaceH1FontStyles);
  }
}

.uniqueDesignSection {
  max-width: 1501px;
  margin: 30px 120px;

  @media (--viewportMobile) {
    margin: 10px 30px;
  }

  @media (--viewportSmall) and (--viewportTabletDown) {
    margin: 10px 50px;
  }

  & hr {
    margin: 20px 0 30px;
  }

  & h1 {
    @apply (--marketplaceH1FontStyles);
  }

  & h3 {
    @apply (--marketplaceH3FontStyles)
  }
}

.titleSpan {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #272727;
  margin-bottom: 2rem;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 30px;
  }
}

.topSpan {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #272727;
  margin-bottom: 1rem;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 30px;

    & ul p {
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.sectionImages {
  width: 568px;
  height: 426px;

  @media (max-width: 991px) {
    width: 100%;
    height: 233px;
    object-fit: contain;
  }
}

.sectionImagesContainer {
  /* width: 80%; */
  width: 100%;
  align-self: center;
}

.sectionImagesContainer img {
  /* width: 100%; */
  height: 25rem;
  object-fit: contain;

  @media (--viewportTabletDown) {
    display: none;
  }
}

.checklist {
  display: flex;
  gap: 2rem;
}

.checkIcon {
  margin-right: 10px;
}

.backgroundColorSecondary {
  background-color: #f7f7f7;
}

.tripExpertForm {
  flex: 1;
}

.tripExpertTitle {
  border-bottom: 1px solid rgb(210, 210, 210);
  width: 100%;
  margin-bottom: 20px;
}

.travelBusinessTitle {
  width: 100%;
}

.form {
  width: 100%;

  &>p {
    max-width: 600px;
  }
}

.submit {
  border-radius: 5px;
  margin-top: 20px;
}

.priceImage {
  text-align: center;
}

.priceImage img {
  width: 70%;
}

.topSpace {
  margin-top: 40px;
}

.nextActionWrapper {
  border: 2px solid var(--marketplaceRedDarkColor);
  padding: 0 15px;

  & p span {
    line-height: 32px;
    font-size: 20px;
  }
}