@import '../../marketplace.css';

.topIcons {
  margin: 0;
  padding: 24px;
  flex-wrap: wrap;
  list-style: none;
  @media (--viewportMobile) {
    padding: 0 24px;
    flex-wrap: nowrap;
  }

  @media (--viewportLarge) {
  }
}

.header {
  text-align: center;
  @media (--viewportMobile) {
    font-size: 25px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  padding: 1rem 0 1rem 1rem;
  margin: auto;
  font-size: 26px;
  @media (--viewportMobile) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 0 0px;
  }
}

.title span {
  color: var(--matterColor);
  font-size: 24px;
  @media (--viewportMobile) {
    font-size: 25px;
  }
}

.serviceSection {
  display: flex;
  justify-content: space-between;
  column-gap: 25px;
  @media (--viewportTabletDown) {
    overflow-x: scroll;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.categoryButtonContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0 5px;
  margin: 24px 0;
}

.categoryButton {
  padding: 3px 0;
  margin-right: 18px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  display: none;
  @media (--viewportTabletDown) {
    display: block;
  }
}

.seletedCategoryButton {
  border-bottom: 3px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
}
