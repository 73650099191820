@import '../../marketplace.css';

.listingLink {
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
  padding: 0 12px;
  border-bottom: 1px solid #e1e1e1;
  &:hover {
    background: var(--lightBackground);
    & .message {
      visibility: visible;
    }
  }
  @media (--viewportLarge) {
    flex-direction: row;
  }
}
.main {
  flex-grow: 1;
  padding-right: 20px;
}
.message {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-basis: 100%;
  margin-bottom: 24px;
  @media (--viewportLarge) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 100px;
    margin-bottom: 0;
    visibility: hidden;
  }
}
.messageLabel {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  color: var(--marketplaceColorDark);
  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.title {
  margin: 24px 0 12px 0;
  font-size: 22px;
  color: var(--matterColorDark);
}

.categories {
  display: flex;
  margin-bottom: 12px;
}
.category {
  padding: 4px 12px;
  border-radius: 10px;
  background: #43677f;
  text-align: center;
  font-size: 14px;
  color: var(--matterColorLightGrey);
  &.sub {
    margin-right: 12px;
    background: var(--marketplaceColorSecond);
  }
}
.description {
  margin-bottom: 24px;
  font-size: 14px;
  color: var(--matterColorDimmedText);
}
.messageWrap {
  display: flex;
  align-items: center;
}
.IconWhatsapp {
  margin-left: 10px;
    margin-top: -26px;
  & svg {
    width: 25px;
    height: 25px;
  }
}
.authourName {
  color: var(--marketplaceColorDarker);
  font-weight:700;
}

.authourDetail {
  padding-top:10px;
}