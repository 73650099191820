@import '../../marketplace.css';

.card {
  padding: 12px;
  width: 300px;
  font-size: 14px;
  line-height: 16px;
  box-shadow: var(--boxShadowFilterButton);
  border-radius: 8px;
}

.row {
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-bottom: 14px;
  }
  &.alignedCenter {
    align-items: center;
  }
}

.date {
  color: var(--matterColorDimmedText);
}

.listingTitle {
  display: -webkit-box;
  width: 170px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rebook {
  display: inline;
  width: auto;
  min-height: auto;
  padding: 4px 10px;
  font-size: 16px;
}
