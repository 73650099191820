@import '../../marketplace.css';

* { -webkit-overflow-scrolling: touch;}

.topIcons {
    padding-left: 15px;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
    @media (--viewportSmall) {
        display: none;
    }
}

.icon {
    display: flex;
    flex-direction: column;
    min-width: 120px;
    margin: 1em 0.5em;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
}

.filter_btn {
    display: flex;
    flex-direction: column;
    /* min-width: 100px; */
    margin: 1em 0.5em;
    margin-top: 1px;
    margin-bottom: 1px;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
}

.icon_item {
    border-radius: 3px;
    box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
}
.top_icon_item {
    border-radius: 10px;
    box-shadow: 0 0px 13px 0px rgba(0, 0, 0, 0.25);
}

.img {
    width: 80px;
    height: 80px;
}

.img_sec {
    width: 100px;
}


.iconName {
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.filter_Name{
    font-size: 13px;
    padding: 4px 15px;
    margin: auto;
}
.title {
    @apply --marketplaceH1FontStyles;

    margin-top: 20px;
    margin-left: 24px;
    max-width: 735px;
    font-size: 25px;
  
    @media (--viewportSmall) {
      margin-bottom: 23px;
      display: none;
    }
}

.title > span {
  color: var(--matterColor);
}

.subTitle {
    margin-top: 4px;
    font-size: 14px;
    color: rgb(72,72,72);
    margin-bottom: -12px;
}

.subTitle {
  font-size: 18px;
  color: var(--matterColor);
}
