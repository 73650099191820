@import '../../../marketplace.css';

.mainFiltersWrapper {
  background: var(--matterColorLight);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  @media (--viewportMobile) {
  }
}

.mobileFilterByTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6b7b95;
  display: inline-block;
  padding: 13px 0 0 16px;
  @media (--viewportLarge) {
    display: none;
  }
}

.mainCategoryFiltersWrapper {
  display: flex;
  gap: 0 14px;
  max-width: 1089px;
  padding: 36px 12px 36px 70px;
  @media (--viewportMobile) {
    grid-template-columns: repeat(2, 1fr);
    padding: 6px 16px 18px 16px;
  }
}

.mainCategoryFilters {
  width: 246px;
  height: 72px;
  display: flex;
  border-radius: 5px;
  background: var(--matterColorLight);
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25));
  border: 1.5px solid transparent;
  @media (--viewportMobile) {
    width: 100%;
    height: 55px;
  }
  & .categoryImage {
    width: 67px;
    min-width: 67px;
    height: 100%;
    @media (--viewportMobile) {
      width: 52px;
      min-width: 52px;
    }
    & img {
      width: 100%;
      height: 100%;
      border-radius: 5px 0 0 5px;
    }
  }
  & .categoryLabel {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    white-space: nowrap;
    color: var(--marketplaceColorDarkest);
    padding: 23px 59px 23px 17px;
    border-radius: 0 5px 5px 0;
    @media (--viewportMobile) {
      font-size: 15px;
      line-height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
}

.selectedMainCategory {
  border: 1.5px solid var(--marketplaceColorLight);
}

.sportFiltersWrapper {
  padding: 20px 43px 32px 70px;
  background: var(--matterColorLight);
  @media (--viewportMobile) {
    padding: 6px 16px 30px;
  }
}

.subCategoryFiltersWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  column-gap: 0px;
  @media (--viewportMobile) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 34px;
  }
}

.subCategoryFilters {
  width: 80%;
  padding: 8px 0 10px;
  border-bottom: 1px solid #314058;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: var(--marketplaceColorDarkest);
  @media (--viewportMobile) {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    padding: 10px 0;
  }
}

.selectedSubCategoryFilters {
  color: var(--marketplaceColorLight);
  border-bottom: 1px solid var(--marketplaceColorLight);
  display: flex;
  align-items: center;
  justify-content: space-between;
  & svg {
    width: 17px;
    height: 17px;
  }
}

.filterSportsTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #4a5a73;
  margin: 0 0 5px;
  @media (--viewportMobile) {
    display: none;
  }
}

.listingTypeName {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #6b7b95;
  margin: 17px 0 9px;
  @media (--viewportMedium) {
    display: none;
  }
}

.filterResetButton {
  width: 84px;
  min-height: 40px;
  font-weight: 600;
  font-size: 12.8801px;
  line-height: 18px;
  color: var(--matterColorLight);
  background: var(--marketplaceColorBackground) !important;
  border: 0;
  border-radius: 20.5px;
}
.filterApplyButton {
  width: 84px;
  min-height: 40px;
  font-weight: 600;
  font-size: 12.8801px;
  line-height: 16px;
  color: var(--matterColorLight);
  background: var(--marketplaceColorLight) !important;
  border: 0;
  border-radius: 20.5px;
}

.filterButtonWrapper {
  display: flex;
  margin-top: 47px;
  justify-content: flex-end;
  gap: 0 10px;
  @media (--viewportMobile) {
    margin: 54px 17px 0 0;
  }
}

.toggleSwitchWrapper {
  & input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  & label {
    cursor: pointer;
    text-indent: -9999px;
    width: 39px;
    height: 22px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  & label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: var(--matterColorLight);
    border-radius: 50%;
    /* transition: 0.1s; */
  }

  & input:checked + label {
    background: var(--marketplaceColor);
  }

  & input:checked + label:after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }

  /* & label:active:after {
      width: 13px;
    } */
}

.subCategoryWrapper {
  display: flex;
  align-items: center;
  column-gap: 100px;
  padding-bottom: 12px;
  margin-bottom: 22px;
  border-bottom: 1px solid #314058;
  @media (--viewportMobile) {
    column-gap: 26px;
    justify-content: space-between;
    align-items: normal;
    border-bottom: 0;
    border-top: 1px solid #314058;
    padding: 19px 0 0;
    margin-bottom: 0;
  }
  & .subCategory {
    display: flex;
    align-items: center;
    column-gap: 20px;
    @media (--viewportMobile) {
      padding-bottom: 22px;
      border-bottom: 1px solid #314058;
      width: 50%;
    }
    & span {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: var(--marketplaceColorDarkest);
    }
  }
}

.languageTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: var(--marketplaceColorDarkest);
  margin: 23px 0 13px;
  @media (--viewportMobile) {
    margin: 17px 0 16px;
  }
}

.advancedFiltersWrapper {
  padding: 20px 44px 51px 70px;
  background: var(--matterColorLight);
  @media (--viewportMobile) {
    padding: 19px 24px 51px;
  }
}

.priceFilter {
  & span {
    color: var(--marketplaceColorDarkest) !important;
  }
  & h2 {
    color: var(--marketplaceColorDarkest) !important;
  }
  & input {
    color: var(--marketplaceColorDarkest) !important;
  }
}
.advancedFiltersWrapper .FilterByTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--marketplaceColorDarkest);
  @media (--viewportMobile) {
    display: flex;
    justify-content: center;
  }
}

.languageOptionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (--viewportMobile) {
    display: block;
    column-count: 2;
  }
}

.checkboxContainer {
  display: block;
  margin-bottom: 15px;
  @media (--viewportMobile) {
    margin-bottom: 24px;
  }
}

.checkboxContainer input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkboxContainer label {
  position: relative;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColorDarkest);
}

.checkboxContainer label:before {
  content: '';
  -webkit-appearance: none;
  border-radius: 3px;
  background-color: transparent;
  border: 2px solid #2f3d54;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.checkboxContainer input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--marketplaceColor);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  @media (--viewportMobile) {
    top: 3px;
  }
}
