@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title,
.companyName {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.customField {
  @apply --EditListingDescriptionForm_formMargins;
}

.category {
  @apply --EditListingDescriptionForm_formMargins;
}

.subCategory {
  @apply --EditListingDescriptionForm_formMargins;
}

.providerName {
  @apply --EditListingDescriptionForm_formMargins;
}

.addCompanyNameNote {
  @apply --EditListingDescriptionForm_formMargins;
  color: var(--matterColorAnti);
}

.companyListing {
  @apply --EditListingDescriptionForm_formMargins;
}

.bankDetails {
  @apply --EditListingDescriptionForm_formMargins;
}

.submitButton {
  border-radius: 8px;
  min-height: 66px;
  padding: 20px 54px;
  white-space: nowrap;
  width: 257px;
  background-color: var(--marketplaceColor);
  border: 0;
  color: var(--matterColorLight);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 257px;
    margin: auto;
  }
}

.submitButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 60px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: 54px;
  left: 0;
  box-shadow: var(--boxShadowPopup);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: var(--matterColorLight);
  border-radius: 16.8519px;
  z-index: 111;
  padding: 6px;
  max-width: 434px;

  & li div {
    color: var(--matterColorDark);
  }
}

.desktopPredictions :after {
  content: '';
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 11px solid var(--matterColorLight);
  position: absolute;
  top: -10px;
  left: 8%;
  transform: translateX(-50%);
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.checkbox {
  align-items: flex-start !important;
}

.crossIcon {
  width: 12px;
  height: 12px;
}

.formButton {
  border-radius: 30px;
  min-height: 60px;
}

.checkboxLabel {
  font-size: 18px;
  line-height: 28px;
}

.agreementInfo {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: var(--marketplaceColorDarkerI);
  margin-left: 2rem;
}

.boldText {
  font-weight: 700;
}

.sectionTitle {
  margin: 1.5rem 0;
}

.paymentsButton {
  max-width: 30rem;
  padding: 1rem 2rem;
  color: #EE3E22;
  border: 2px solid #EE3E22;
  border-radius: 10px;
}

.btnWrapper {
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 20px 10px 35px;
  margin: 5px;

  @media(--viewportMobile) {
    padding-left: 25px;
  }
}

.headingTitle {
  font-size: 35px;
  color: white;
  text-transform: capitalize;
  margin: 0;

  @media (--viewportMobile) {
    /* font-size: 20px; */
    display: none;
  }
}

.topSaveButton {
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 8px;
  border: none;
  padding: 5px;
  cursor: pointer;
  width: 200px;

  &:hover {
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportMobile) {
    width: 100px;
    font-size: 14px;
  }
}

.topSaveButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.formGroup {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 50px 80px 40px 80px;

  @media (--viewportMobile) {
    padding: 11px 24px 40px 24px;
  }
}