@import '../../marketplace.css';

.root {}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 25px;
}

.field {
  margin: 0 0 24px 0;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.breakdownWrapper {
  margin: 24px 0px;
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}

.totalPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  padding: 20px 0;
  border-top: 1px solid black;
}

.orderBreakdownTitle {
  /* Font */
  color: var(--matterColor);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 48px 0 0px;
    padding: 4px 0 4px 0;
  }
}

.infoText {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.locationAddress {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: 54px;
  left: 0;
  box-shadow: var(--boxShadowPopup);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: var(--matterColorLight);
  border-radius: 16.8519px;
  z-index: 111;
  padding: 6px;
  max-width: 434px;

  & li div {
    color: var(--matterColorDark);
  }
}

.desktopPredictions :after {
  content: '';
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 11px solid var(--matterColorLight);
  position: absolute;
  top: -10px;
  left: 8%;
  transform: translateX(-50%);
}


.validLocation {
  border-bottom-color: var(--successColor);
}

.sectionTitle{
  background: var(--marketplaceColorBackground);
  color: var(--matterColorLight);
  padding: 10px;
  & svg{
    & path{
      stroke: var(--matterColorLight)
    }
    & circle{
      fill: var(--matterColorLight)
    }
  }
}

.currencyWrapper{
  display: flex;
  column-gap: 24px;
}