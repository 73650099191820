@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

.title {
  & .EasyFastSimpleTitle{
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #5E6282;
    margin-bottom: 19px;
    @media (--viewportMobile) {
      font-size: 14px;
      line-height: 17px;
      margin-top: 19px;
    }
  }
  & h2{
    font-weight: bold;
    font-size: 50px;
    line-height: 63px;
    text-transform: capitalize;
    color: var(--matterColorDarkBlue);
    margin-bottom: 19px;
    @media (--viewportMobile) {
      font-size: 38px;
      line-height: 48px;
      margin-bottom: 27px;
    }
  }
  @media (--viewportMobile) {
    margin-top: 19px;
  }
}

.whyUs {
  display: flex;
  height: 937px;
  @media (--viewportMobile) {
    flex-direction: column;
    height: auto;
  }
}

.left {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 982px;
  & img{
    width: 100%;
    height: auto;
  }

  @media (--viewportMobile) {
    flex: auto;
    height: auto;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
  }
}

.mobileImage{
 display: none;
  & img{
  width: 100%;
  height: auto;
  }
 @media (--viewportMobile) {
   display: block;
 }
}

.right {
  width: 100%;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  @media (--viewportMobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.points {
  max-width: 408px;
  display: flex;
  column-gap: 21px;
  @media (--viewportMobile) {
    column-gap: 16px;
    & svg{
      width: 36px;
      height: 37px;
    }
  }
}

.points svg{
  fill: transparent;
}

.points h3 {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  line-height: 124%;
  color: var(--matterColorDarkBlue);
  margin: 0;
  @media (--viewportMobile) {
    font-size: 12px;
  }
}

.points p {
  font-weight: normal;
  font-size: 16px;
  line-height: 124.5%;
  color: var(--matterColorDarkBlue);
  margin: 0 0 48px 0;
  @media (--viewportMobile) {
    font-size: 12px;
    margin: 0 0 37px 0;
  }
}

.fyi {
  color: var(--matterColorPink);
  font-weight: 900;
}

.pinkText {
  color: var(--matterColorPink);
}

.findCustomers {
  margin: 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #eaeaea;
}

.findCustomersTitle {
  margin-bottom: 16px;
  margin-top: 10px;
  padding-top: 15px;
  border-top: 1px solid #eaeaea;
  font-size: 32px;
  line-height: 38px;
}
.findCustomers p {
  margin: 0 0 16px 0;
  font-size: 18px;
  line-height: 22px;
}
.addListingButton:hover {
  outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
}
.addListingButton {
    display: block;
    width: 100%;
    min-height: 59px;
    margin: 0;
    padding: 20px 0 0 0;
    border: none;
    border-radius: 2px;
    background-color: var(--marketplaceColor);
    color: var(--marketplaceColorLightWhite);
    text-align: center;
    text-decoration: none !important;
    transition: all ease-in-out 0.1s;
    cursor: pointer;
    animation-delay: 0.8s;
    min-height: 55px;
    padding: 15px 0 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;
    background-color: #34b7ba;
    width: 190px;
    border-radius: 8px;
}

.containWrapper{
  max-width: 525px;
  margin: 0 auto;
}

.imageWrapper{
  /* position: relative; */
}

.whyUsInfoImage{
  max-width: 485px;
  height: 459px;
  margin-top: -105px;
  @media (--viewportMobile) {
    margin-top: -130px;
  }
  & img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  } 
}

.icon{
  height: 49px;
  width: auto;
}