@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end
}
.submitButton {
  width: 130px;
  min-height: 45px;
}
.phoneNumberModal{
  & button {
  padding: 10px;
}

}