@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPhotosForm_imageWidthDesktop: calc(33% - 13px);
}

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
  justify-content: flex-start;
}

.thumbnail {
  margin: 12px;
  width: 300px;
  aspect-ratio: 19 / 15;

  & > div{
    position: unset;
  }
}

.mapWrapper {
  width: 300px;
}

.mapThumbnail {
  position: relative;
  margin: 12px;
  width: 300px;
  aspect-ratio: 19 / 15;

  @media (--viewportTabletDown) {
    margin: 12px 0;
  }

  & img {
    width: 100%;
  }

  & div, & button {
    max-height: 240px;
    border: 0;
  }
}

.videoThumbnail {
  aspect-ratio: 1920 / 1080;
}

.backgroundThumbnail {
  width: 100%;
  aspect-ratio: 15 / 4;
}


.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    /* width: var(--EditListingPhotosForm_imageWidthDesktop); */
    width: 380px;
    margin: 0 0 24px 0;
  }
}

.addVideoWrapper {
  float: left;
  position: relative;
  width: 350px;
  aspect-ratio: 1920 / 1080;
  margin-bottom: 24px;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.profileImageWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (--viewportTabletDown) {
    flex-direction: column;
  }

  @media (--viewportLaptopDown) {
    justify-content: center;
  }
}

.companyProfileImageWrapper {
  display: flex;
  gap: 3rem;
  align-items: center;
  margin-bottom: 2rem;

  @media (--viewportTabletDown) {
    flex-direction: column;
  }
  @media (--viewportLaptopDown) {
    justify-content: center;
  }
}

.addSingleImageWrapper {
  float: left;
  width: 300px;
  aspect-ratio: 19 / 15;
  margin: 12px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.addBackgroundImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  aspect-ratio: 15 / 4;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportMobile) {
    aspect-ratio: unset;
  }
}

.addLargeBackgroundImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  aspect-ratio: 15 / 4;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportMobile) {
    aspect-ratio: unset;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  /* padding-bottom: calc(100% * (2 / 3)); */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  background: #EBEBEB;
  border: 1px solid #6E6F71;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.loadingIndicator {
  width: 100%;
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--matterColorLight) url('../../assets/loader.gif') no-repeat center;
    font-family: 'Helvetica';
    font-weight: 300;
    line-height: 2;
    text-align: center;
    content: '';
    border: 1px solid #6E6F71;
  }
}

.puffStyle {
  width: 100px;
  height: 100px;
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  /* @apply --marketplaceH4FontStyles; */
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #6E6F71;
  /* color: var(--matterColor); */

  margin-top: 0;
  margin-bottom: 45px;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
  }
}

.imageDimension {
  /* @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti); */
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #707D83;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  /* @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti); */
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #707D83;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: red;
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  border-radius: 8px;
  min-height: 66px;
  padding: 20px 54px;
  white-space: nowrap;
  width: 257px;
  background-color: var(--marketplaceColor);
  border: 0;
  color: var(--matterColorLight);
  display: flex;
  justify-content: center;
  align-items: center;


  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  align-self: center;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 257px;
    margin: auto;
    /* margin-top: 100px; */
  }
}

.submitButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.imageArrayField {
  margin: 32px 0;
}

.videoClipWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 20px;
  margin-bottom: 32px;
}

.pictureHeading {
  font-weight: var(--fontWeightBold);
}

.pictureContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  @media (--viewportLarge) {
    width: 495px;
    height: 270px;
    margin: 0 auto;
  }
}

.uploadPictureBtn {
  position: relative;
  @apply --marketplaceButtonStylesSecondary;
  margin-top: 22px;
  padding: 0px;
}

.hiddenField {
  display: none;
}

.mutateBtns {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;

  @media (--viewportMobile) {
    margin-bottom: 24px;
  }

  &>button {
    max-width: 200px;
    min-height: 40px;
  }
}

.uploadPictureBtn .tooltiptext {
  visibility: hidden;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0 21px;

  /* Position the tooltip */
  position: absolute;
  top: 11px;
  z-index: 1;
}

.uploadPictureBtn:hover .tooltiptext {
  visibility: visible;
}

.mapImage {
  border-radius: 12px;
  overflow: hidden;
}

.mapImage img {
  width: 380px;
  height: 300px;
  object-fit: cover;
  border-radius: 12px;
  border: 5px solid var(--matterColorLight);
}


.subHeading label {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
}



.modalContentWrapper{
  display: flex;
  flex-direction: column;
  min-width: 55vw;
}

.croppingToolWrapper{
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media (max-width: 900px){
    width: 80%;
    margin: 0 auto;
  }
}

.previewWrapper{
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media (max-width: 900px){
    width: 80%;
    margin: 0 auto;
  }
}

.saveButton{
  width: 80%;
  max-width: 400px;
  height: 60px;
  margin: 40px auto 0 auto;
  background-color: var(--marketplaceColor);
  border-radius: 5px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  &:hover{
    transform: scale(1.03);
  }

  @media (max-width: 900px){
    margin-bottom: 100px;
  }
}

.saveButtonDisabled{
  width: 80%;
  max-width: 400px;
  height: 60px;
  margin: 40px auto 0 auto;
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.modalContentTopSection{
 display: flex;
 justify-content: space-between;

 @media (max-width: 900px){
   flex-direction: column;
 }
}

.previewCanvas{
  @media (max-width: 768px){
    display: none;
  }
}

.finalCanvas{
  position: fixed;
  z-index: 0;
  visibility: hidden;
}

.thumbnailLong{
    width: 95%;
    height: 384px;
    margin-bottom: 40px;
    & img {
      height: 384px;
    }

}

.imagesFieldLong{
  width: 100%;
  margin: 0;
  display: block;

  @media (--viewportMobile) {
    justify-content: center;
    display: flex;
  }
}

.addImageWrapperLong{
  position: relative;
  width: 95%;
  height: 384px;

  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

}

.formGroup {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 50px 50px 40px 50px;

  @media (--viewportMobile) {
    padding: 11px 20px 40px 20px;
  }
}

.btnWrapper {
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 20px 10px 35px;
  margin: 5px;

  @media(--viewportMobile) {
    padding-left: 25px;
  }
}

.topSaveButton {
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 8px;
  border: none;
  padding: 5px;
  cursor: pointer;
  width: 200px;
  &:hover{
    background-color: var(--marketplaceColorLight);
  }
  @media (--viewportMobile) {
    width: 100px;
    font-size: 14px;
  }
}

.topSaveButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.headingTitle {
  font-size: 35px;
  color: white;
  text-transform: capitalize;
  margin: 0;

  @media (--viewportMobile) {
    /* font-size: 20px; */
    display: none;
  }
}
